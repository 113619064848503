<template>
  <admin-checker>
    <div class="my-10 mx-20">
      <div class="flex items-center mb-5">
        <p class="text-xl font-bold text-gray-600">
          <router-link to="/"
            ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
          >Create issuer
        </p>
        <p
          class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
        >
          admin
        </p>
      </div>
      <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
      <p class="text-sm text-gray-600 mb-10">
        <i class="fas fa-info-circle text-gray-700 mr-2"></i>Create DID document
        and IPFS profile for an issuer.
      </p>
      <p class="text-gray-600 mr-3 mb-3">Issuer images :</p>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <div class="flex items-center mb-5">
            <button
              class="text-gray-900 text-sm border border-gray-900 inline-block rounded-lg cursor-pointer mr-5 py-2 px-3"
              @click="$refs.profileImageInput.click()"
            >
              Load profile image
            </button>
            <input
              type="file"
              @change="onProfileImageInput"
              ref="profileImageInput"
              class="hidden"
            />
            <p class="text-sm italic text-gray-600">
              {{ profileImageMessage }}
            </p>
          </div>
          <img
            v-if="profileImagePreview"
            class="h-32 mb-5"
            :src="profileImagePreview"
          />
        </div>
        <div>
          <div class="flex items-center mb-5">
            <button
              class="text-gray-900 text-sm border border-gray-900 inline-block rounded-lg cursor-pointer mr-5 py-2 px-3"
              @click="$refs.coverImageInput.click()"
            >
              Load cover image
            </button>
            <input
              type="file"
              @change="onCoverImageInput"
              ref="coverImageInput"
              class="hidden"
            />
            <p class="text-sm italic text-gray-600">
              {{ coverImageMessage }}
            </p>
          </div>
          <img
            v-if="coverImagePreview"
            class="h-32 mb-5"
            :src="coverImagePreview"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer name :</p>
          <textarea
            type="text"
            placeholder="Issuer name"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerName"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer nationality :</p>
          <country-select
            v-model="issuerNationality"
            :country="issuerNationality"
            topCountry="FR"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer email :</p>
          <textarea
            type="text"
            placeholder="Issuer email"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerEmail"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer website :</p>
          <textarea
            type="text"
            placeholder="Issuer website"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerWebsite"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer twitter :</p>
          <textarea
            type="text"
            placeholder="Issuer twitter"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerTwitter"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer ecosystem :</p>
          <textarea
            type="text"
            placeholder="Issuer ecosystem"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerEcosystem"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer sector :</p>
          <textarea
            type="text"
            placeholder="Issuer sector"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerSector"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer subsector :</p>
          <textarea
            type="text"
            placeholder="Issuer subsector"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerSubsector"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer registration :</p>
          <textarea
            type="text"
            placeholder="Issuer registration"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerRegistration"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Issuer RSS :</p>
          <textarea
            type="text"
            placeholder="Issuer RSS feed"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="issuerRss"
          />
        </div>
      </div>
      <p class="text-gray-600 mr-3 mb-3">Issuer address :</p>
      <textarea
        type="text"
        placeholder="Address value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="issuerAddress"
      />
      <p class="text-gray-600 mr-3 mb-3">Issuer certificate :</p>
      <textarea
        type="text"
        placeholder="Certificate IPFS"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="issuerCertificate"
      />
      <p class="text-gray-600 mr-3 mb-3">Authentication key :</p>
      <textarea
        type="text"
        placeholder="Key value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="authenticationKey"
      />
      <p class="text-gray-600 mr-3 mb-3">Issuer category :</p>
      <select
        required
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="issuerCategory"
      >
        <option disabled selected value="">Please select an option</option>
        <option value="1">1</option>
        <option value="2">2</option>
        >
      </select>
      <method-price-handler
        :method="
          issuerCategory == '1'
            ? 'createIssuerDIDPrice1'
            : 'createIssuerDIDPrice2'
        "
        class="mb-2"
        :key="issuerCategory"
      ></method-price-handler>
      <button
        class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        :class="{
          'pointer-events-none opacity-50': buttonDisable,
        }"
        @click="createNewIssuer"
      >
        Create new issuer
      </button>
      <div
        v-if="ipfsLoading"
        class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
      >
        <div class="flex items-center">
          <custom-loader color="#111827" size="30px" class="mr-3">
          </custom-loader>
          <p class="text-gray-600 text-md font-bold">Pushing on IPFS</p>
        </div>
      </div>
      <transaction-handler
        ref="transactionHandlerRef"
        @transactionCompleted="onTransactionCompleted"
        @transactionFailed="buttonDisable = false"
      ></transaction-handler></div
  ></admin-checker>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');

    const profileImageSelectedFile = ref(null);
    const profileImageMessage = ref('Please select a file');
    const profileImagePreview = ref(null);

    const coverImageSelectedFile = ref(null);
    const coverImageMessage = ref('Please select a file');
    const coverImagePreview = ref(null);

    const issuerAddress = ref('');
    const authenticationKey = ref('');
    const issuerName = ref('');
    const issuerNationality = ref('');
    const issuerEmail = ref('');
    const issuerWebsite = ref('');
    const issuerTwitter = ref('');
    const issuerEcosystem = ref('');
    const issuerSector = ref('');
    const issuerSubsector = ref('');
    const issuerRegistration = ref('');
    const issuerRss = ref('');
    const issuerCertificate = ref('');
    const issuerProfileIFPS = ref('');
    const issuerCategory = ref(1);

    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();
    const ipfsLoading = ref(false);

    async function onProfileImageInput(event) {
      if (event.target.files[0]['type'].split('/')[0] != 'image') {
        profileImageMessage.value = 'Please select an image';
        return;
      }
      profileImageSelectedFile.value = event.target.files[0];
      profileImageMessage.value = profileImageSelectedFile.value.name;
      const reader = new FileReader();
      reader.readAsDataURL(profileImageSelectedFile.value);
      reader.onload = async (e) => {
        profileImagePreview.value = e.target.result;
      };
    }

    async function onCoverImageInput(event) {
      if (event.target.files[0]['type'].split('/')[0] != 'image') {
        coverImageMessage.value = 'Please select an image';
        return;
      }
      coverImageSelectedFile.value = event.target.files[0];
      coverImageMessage.value = coverImageSelectedFile.value.name;
      const reader = new FileReader();
      reader.readAsDataURL(coverImageSelectedFile.value);
      reader.onload = async (e) => {
        coverImagePreview.value = e.target.result;
      };
    }

    async function createNewIssuer() {
      buttonDisable.value = true;
      ipfsLoading.value = true;
      issuerProfileIFPS.value = '';

      // push image on IPFS
      const profileImageIPFSHash = await utils.ipfs.uploadIpfsFile(
        profileImageSelectedFile.value
      );
      const coverImageIPFSHash = await utils.ipfs.uploadIpfsFile(
        coverImageSelectedFile.value
      );

      // push textfile on IPFS
      const issuerProfile = {
        type: 'Issuer Profile',
        id: 'IPFS',
        name: issuerName.value,
        nationality: issuerNationality.value,
        website: issuerWebsite.value,
        email: issuerEmail.value,
        twitter: issuerTwitter.value,
        ecosystem: issuerEcosystem.value,
        sector: issuerSector.value,
        subsector: issuerSubsector.value,
        registration: issuerRegistration.value,
        rss: issuerRss.value,
        certificate: issuerCertificate.value,
        coverImage: utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(coverImageIPFSHash)
        ),
        profileImage: utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(profileImageIPFSHash)
        ),
      };
      const issuerIPFSHash = await utils.ipfs.uploadIpfsJsonData(
        JSON.stringify(issuerProfile)
      );
      issuerProfileIFPS.value = utils.ipfs.getUrlFromCID(
        utils.ipfs.hashToCID(issuerIPFSHash)
      );

      ipfsLoading.value = false;

      api.addIpfsData({
        context: 'issuer-admin-create',
        hash: issuerIPFSHash,
        data: issuerProfile,
      });

      transactionHandlerRef.value.createTransaction(
        issuerCategory.value == '1' ? 'createIssuerDIDV1' : 'createIssuerDIDV2',
        issuerAddress.value,
        '0x' + issuerIPFSHash,
        authenticationKey.value
      );
    }

    function onTransactionCompleted() {
      buttonDisable.value = false;
      api.createIssuer({
        name: issuerName.value,
        did: 'DID:SDI:' + issuerAddress.value,
      });
    }

    return {
      onProfileImageInput,
      onCoverImageInput,
      profileImageSelectedFile,
      profileImageMessage,
      profileImagePreview,
      coverImageSelectedFile,
      coverImageMessage,
      coverImagePreview,
      issuerAddress,
      issuerName,
      issuerNationality,
      issuerEmail,
      issuerWebsite,
      issuerTwitter,
      issuerEcosystem,
      issuerSector,
      issuerSubsector,
      issuerRegistration,
      issuerRss,
      issuerCertificate,
      authenticationKey,
      issuerProfileIFPS,
      issuerCategory,
      createNewIssuer,
      buttonDisable,
      transactionHandlerRef,
      onTransactionCompleted,
      ipfsLoading,
    };
  },
};
</script>
