<template>
  <div class="w-3/5 mx-auto my-10">
    <div class="relative">
      <div class="absolute top-0 right-0" @click="disconnectIssuer">
        <router-link to="/"
          ><div
            class="flex justify-center items-center text-gray-600 bg-gray-200 rounded-full w-7 h-7"
          >
            <p class="text-lg leading-none">
              <i class="fas fa-times"></i>
            </p></div
        ></router-link>
      </div>
    </div>
    <p class="text-xl font-bold mb-3">Create issuer account</p>
    <div v-if="currentAddress" class="mb-10">
      <div class="flex items-center mb-5">
        <img
          :src="require('/assets/images/walletconnect.png')"
          alt=""
          class="w-4 h-4 mr-2"
        />
        <p class="mr-2">Connected with address :</p>
        <p class="font-bold mr-2">
          {{ currentAddress }}
        </p>
        <!-- <p class="text-center mb-1">
        SYL : {{ sylBalance }}&nbsp;&nbsp;&nbsp;&nbsp;BNB : {{ bnbBalance }}
      </p> -->
        <p
          class="text-xs underline cursor-pointer break-words"
          @click="disconnectIssuer"
        >
          Disconnect
        </p>
      </div>
      <p class="text-lg italic mb-1">Follow creation steps</p>
      <p class="text-xs mb-3">*Required fields</p>
    </div>
    <div v-else class="mb-10">
      <div class="flex justify-center mt-20">
        <img
          :src="require('/assets/images/walletconnect.png')"
          alt=""
          class="w-10 h-10 mr-2"
        />
      </div>
      <p
        @click="connectIssuer"
        class="text-center underline cursor-pointer mb-1 mt-3"
      >
        Click here to connect your wallet
      </p>
    </div>
    <div v-if="currentIssuerDid && issuerFromIPFS" class="mb-10">
      <img
        :src="
          issuerFromIPFS.profileImage.replace(
            'https://ipfs.infura.io/ipfs/',
            'https://myntfsid.mypinata.cloud/ipfs/'
          )
        "
        alt="issuer-image"
        class="w-24 border border-white mx-auto mb-3"
      />
      <p class="text-center text-red-600 font-bold mb-1">
        Issuer already exists :
      </p>
      <p class="font-bold text-center mb-1">
        {{ issuerFromIPFS.name }}
      </p>
      <p class="text-center mb-1">
        {{ issuerFromIPFS.email }} - {{ issuerFromIPFS.nationality }}
      </p>
      <p class="text-center mb-1">
        {{ issuerFromIPFS.sector }} - {{ issuerFromIPFS.subsector }}
      </p>
    </div>
    <multi-step-form
      v-if="!currentIssuerDid && currentAddress"
      ref="multiStepForm"
      class="w-full m-auto mb-10"
      :step-names="[
        '1. Name',
        '2. Pictures',
        '3. Contact',
        '4. Sector',
        // '5. Certificate',
        // '5. Category',
        // 'Admin',
        // '5. Allowance',
        '5. Validation',
      ]"
    >
      <div>
        <div class="grid grid-cols-2 gap-12">
          <div>
            <div class="bg-gray-100 p-4 mb-1">
              <p class="text-lg leading-none">1. Name</p>
            </div>
            <div class="bg-gray-100 p-4" style="height: 700px">
              <div class="flex justify-center items-center w-full h-full">
                <img
                  :src="require('/assets/images/small_mydid.png')"
                  alt=""
                  class="w-52 opacity-60"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <p class="font-bold mb-2">What is the name of your issuer ?*</p>
              <div>
                <textarea
                  type="text"
                  placeholder="Name"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                  v-model="issuerName"
                />
              </div>
              <p class="font-bold mb-2">Enter your email*</p>
              <div>
                <textarea
                  type="text"
                  placeholder="Email"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                  v-model="issuerEmail"
                />
              </div>
              <p class="font-bold mb-2">Confirm your email*</p>
              <div>
                <textarea
                  type="text"
                  placeholder="Email"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-7 mx-auto"
                  value="hello@awesome-issuer.com"
                />
              </div>
              <div class="flex justify-end">
                <button
                  @click="multiStepForm.nextStep()"
                  class="text-sm bg-gray-800 text-white font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-2 gap-12">
          <div>
            <div class="bg-gray-100 p-4 mb-1">
              <p class="text-lg leading-none">2. Pictures</p>
            </div>
            <div class="bg-gray-100 p-4" style="height: 700px">
              <div class="flex justify-center items-center w-full h-full">
                <img
                  :src="require('/assets/images/small_mydid.png')"
                  alt=""
                  class="w-52 opacity-60"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <p class="font-bold mb-2">Select a profile picture*</p>
              <div>
                <textarea
                  type="text"
                  placeholder="Name"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 cursor-pointer"
                  v-model="profileImageMessage"
                  @click="$refs.profileImageInput.click()"
                />
              </div>
              <input
                type="file"
                @change="onProfileImageInput"
                ref="profileImageInput"
                class="hidden"
              />
              <img
                v-if="profileImagePreview"
                :src="profileImagePreview"
                class="h-28 mb-5"
              />
              <p class="font-bold mb-2">Select a cover picture*</p>
              <div>
                <textarea
                  type="text"
                  placeholder="Name"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                  v-model="coverImageMessage"
                  @click="$refs.coverImageInput.click()"
                />
              </div>
              <img
                v-if="coverImagePreview"
                :src="coverImagePreview"
                class="h-28 mb-5"
              />
              <input
                type="file"
                @change="onCoverImageInput"
                ref="coverImageInput"
                class="hidden"
              />
              <div class="flex justify-end gap-3 mt-5">
                <button
                  @click="multiStepForm.previousStep()"
                  class="text-sm bg-gray-200 text-gray-800 font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Previous step
                </button>
                <button
                  @click="multiStepForm.nextStep()"
                  class="text-sm bg-gray-800 text-white font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-2 gap-12">
          <div>
            <div class="bg-gray-100 p-4 mb-1">
              <p class="text-lg leading-none">3. Contact</p>
            </div>
            <div class="bg-gray-100 p-4" style="height: 700px">
              <div class="flex justify-center items-center w-full h-full">
                <img
                  :src="require('/assets/images/small_mydid.png')"
                  alt=""
                  class="w-52 opacity-60"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <p class="font-bold mb-2">Nationality</p>
              <country-select
                v-model="issuerNationality"
                :country="issuerNationality"
                topCountry="FR"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-5 mx-auto"
              />
              <p class="font-bold mb-2">Registration</p>
              <textarea
                type="text"
                placeholder="123456789"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                v-model="issuerRegistration"
              />
              <p class="font-bold mb-2">Website</p>
              <textarea
                type="text"
                placeholder="www.website.com"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                v-model="issuerWebsite"
              />

              <p class="font-bold mb-2">Twitter</p>
              <textarea
                type="text"
                placeholder="Twitter identifier"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                v-model="issuerTwitter"
              />

              <p class="font-bold mb-2">RSS</p>
              <textarea
                type="text"
                placeholder="RSS feed"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                v-model="issuerRss"
              />

              <div class="flex justify-end gap-3">
                <button
                  @click="multiStepForm.previousStep()"
                  class="text-sm bg-gray-200 text-gray-800 font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Previous step
                </button>
                <button
                  @click="multiStepForm.nextStep()"
                  class="text-sm bg-gray-800 text-white font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="grid grid-cols-2 gap-12">
          <div>
            <div class="bg-gray-100 p-4 mb-1">
              <p class="text-lg leading-none">4. Sector</p>
            </div>
            <div class="bg-gray-100 p-4" style="height: 700px">
              <div class="flex justify-center items-center w-full h-full">
                <img
                  :src="require('/assets/images/small_mydid.png')"
                  alt=""
                  class="w-52 opacity-60"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <p class="font-bold mb-2">Ecosystem</p>
              <textarea
                type="text"
                placeholder="Enter ecosystem"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                v-model="issuerEcosystem"
              />
              <p class="font-bold mb-2">Sector</p>
              <textarea
                type="text"
                placeholder="Enter sector"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                v-model="issuerSector"
              />
              <p class="font-bold mb-2">Subsector</p>
              <textarea
                type="text"
                placeholder="Enter subsector"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3 mx-auto"
                v-model="issuerSubsector"
              />
              <div class="flex justify-end gap-3">
                <button
                  @click="multiStepForm.previousStep()"
                  class="text-sm bg-gray-200 text-gray-800 font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Previous step
                </button>
                <button
                  @click="multiStepForm.nextStep()"
                  class="text-sm bg-gray-800 text-white font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
        <p class="text-lg mb-5">Do you have an IPFS certificate ?</p>
        <p class="text-gray-600 mr-3 mb-3">Issuer certificate :</p>
        <div>
          <textarea
            type="text"
            placeholder="Certificate IPFS"
            rows="1"
            class="w-96 outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
            v-model="issuerCertificate"
          />
        </div>
        <div>
          <button
            @click="multiStepForm.nextStep()"
            class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer w-32 py-2 px-3 mx-auto mb-10"
          >
            Next
          </button>
        </div>
      </div> -->
      <!-- <div>
        <div class="grid grid-cols-2 gap-12">
          <div>
            <div class="bg-gray-100 p-4 mb-1">
              <p class="text-lg leading-none">5. Category</p>
            </div>
            <div class="bg-gray-100 p-4" style="height: 700px">
              <div class="flex justify-center items-center w-full h-full">
                <img
                  :src="require('/assets/images/small_mydid.png')"
                  alt=""
                  class="w-52 opacity-60"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <p class="font-bold mb-2">Category</p>
              <select
                required
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-10 mx-auto"
                v-model="issuerCategory"
              >
                <option disabled selected value="">
                  Please select an option
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                >
              </select>
              <div class="flex justify-end gap-3">
                <button
                  @click="multiStepForm.previousStep()"
                  class="text-sm bg-gray-200 text-gray-800 font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Previous step
                </button>
                <button
                  @click="multiStepForm.nextStep()"
                  class="text-sm bg-gray-800 text-white font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div>
        <div class="grid grid-cols-2 gap-12">
          <div>
            <div class="bg-gray-100 p-4 mb-1">
              <p class="text-lg leading-none">5. Allowance</p>
            </div>
            <div class="bg-gray-100 p-4" style="height: 700px">
              <div class="flex justify-center items-center w-full h-full">
                <img
                  :src="require('/assets/images/small_mydid.png')"
                  alt=""
                  class="w-52 opacity-60"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <p class="font-bold mb-2">Allowance</p>
              <div v-if="allowanceGiven == null" class="flex items-center mb-5">
                <custom-loader color="#111827" size="30px" class="mr-3">
                </custom-loader>
                <p class="text-gray-600 text-md font-bold">
                  Checking allowance
                </p>
              </div>
              <div
                v-else-if="allowanceGiven == false"
                class="flex items-center mb-5"
              >
                <button
                  class="text-sm text-red-700 border-2 border-red-700 font-bold rounded-full cursor-pointer py-3 px-5 mb-3"
                  :class="{
                    'pointer-events-none opacity-50': buttonDisable,
                  }"
                  @click="triggerAllowance"
                >
                  Trigger allowance
                </button>
              </div>
              <p v-else class="text-gray-500 mb-8">
                <i class="fas fa-check-circle mr-2"></i>Allowance already given,
                you can go to next step.
              </p>
              <transaction-handler
                class="text-left mb-8"
                ref="transactionHandlerAllowanceRef"
                @transactionCompleted="onTransactionAllowanceCompleted"
                @transactionFailed="buttonDisable = false"
              ></transaction-handler>
              <div class="flex justify-end gap-3 mt-5">
                <button
                  @click="multiStepForm.previousStep()"
                  class="text-sm bg-gray-200 text-gray-800 font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Previous step
                </button>
                <button
                  @click="multiStepForm.nextStep()"
                  class="text-sm bg-gray-800 text-white font-bold rounded-full cursor-pointer w-40 py-3"
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div>
        <div class="grid grid-cols-2 gap-12"></div>
        <p class="text-xl font-semibold leading-none border-b-2 pb-3 mb-3">
          5. Validation
        </p>
        <p class="text-xl italic leading-none mb-10">
          Verify your information and create your issuer.
        </p>
        <div class="grid grid-cols-2 gap-12 mb-20">
          <div>
            <p class="text-lg font-semibold leading-none border-b pb-3 mb-3">
              1. Name
            </p>
            <p class="mb-2">
              <span class="font-bold mr-2">Issuer name :</span>{{ issuerName }}
            </p>
            <p class="mb-7">
              <span class="font-bold mr-2">Issuer email :</span
              >{{ issuerEmail }}
            </p>
            <p class="text-lg font-semibold leading-none border-b pb-3 mb-3">
              2. Pictures
            </p>
            <p class="mb-2">
              <span class="font-bold mr-2">Profile picture :</span
              >{{ profileImageMessage }}
            </p>
            <img
              v-if="profileImagePreview"
              :src="profileImagePreview"
              class="h-10 mb-3"
            />
            <p class="mb-2">
              <span class="font-bold mr-2">Profile cover :</span
              >{{ coverImageMessage }}
            </p>
            <img
              v-if="coverImagePreview"
              :src="coverImagePreview"
              class="h-10"
            />
          </div>
          <div>
            <p class="text-lg font-semibold leading-none border-b pb-3 mb-3">
              3. Contact
            </p>
            <p class="mb-2">
              <span class="font-bold mr-2">Nationality :</span
              >{{ issuerNationality }}
            </p>
            <p class="mb-2">
              <span class="font-bold mr-2">Registration :</span
              >{{ issuerRegistration }}
            </p>
            <p class="mb-2">
              <span class="font-bold mr-2">Website :</span>{{ issuerWebsite }}
            </p>
            <p class="mb-2">
              <span class="font-bold mr-2">Twitter :</span>{{ issuerTwitter }}
            </p>
            <p class="mb-7">
              <span class="font-bold mr-2">RSS :</span>{{ issuerRss }}
            </p>
            <p class="text-lg font-semibold leading-none border-b pb-3 mb-3">
              4. Sector
            </p>
            <p class="mb-2">
              <span class="font-bold mr-2">Ecosystem :</span
              >{{ issuerEcosystem }}
            </p>
            <p class="mb-2">
              <span class="font-bold mr-2">Sector :</span>{{ issuerSector }}
            </p>
            <p class="">
              <span class="font-bold mr-2">Subsector :</span
              >{{ issuerSubsector }}
            </p>
          </div>
        </div>

        <method-price-handler
          :method="
            issuerCategory == '1'
              ? 'createIssuerDIDPrice1'
              : 'createIssuerDIDPrice2'
          "
          :noAutoAllowanceTrigger="true"
          class="text-center mb-5"
          :key="[issuerCategory, updateMethodPriceHandler].toString()"
        ></method-price-handler>
        <div
          class="flex items-center justify-center"
          :class="{ 'mb-5': allowanceGiven }"
        >
          <button
            class="text-sm bg-green-700 text-white font-bold rounded-full cursor-pointer py-3 px-5 mb-3"
            :class="{
              'pointer-events-none opacity-50':
                buttonDisable || !allowanceGiven,
            }"
            @click="createNewIssuer"
          >
            Create new issuer
          </button>
        </div>
        <p v-if="!allowanceGiven" class="text-xxs text-center italic mb-5">
          Return to "Allowance" step to authorize myDid to use your SYL
        </p>
        <div
          v-if="errorMessage"
          class="bg-white rounded-lg text-left shadow-md flex-col space-y-4 p-5 mb-5"
        >
          <p class="text-gray-600 text-md font-bold mb-1">
            <i class="fas fa-exclamation-circle mr-2"></i>An error occured :
          </p>
          <p class="bg-red-200 h-16 overflow-y-scroll px-2 py-1">
            <span class="text-xxs leading-none my-4">{{ errorMessage }}</span>
          </p>
        </div>
        <div
          v-if="ipfsLoading"
          class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
        >
          <div class="flex items-center">
            <custom-loader color="#111827" size="30px" class="mr-3">
            </custom-loader>
            <p class="text-gray-600 text-md font-bold">Pushing on IPFS</p>
          </div>
        </div>
        <transaction-handler
          class="text-left mb-5"
          ref="transactionHandlerRef"
          @transactionCompleted="onTransactionCompleted"
          @transactionFailed="buttonDisable = false"
        ></transaction-handler>
        <div class="flex justify-end gap-3">
          <button
            @click="multiStepForm.previousStep()"
            class="text-sm bg-gray-200 text-gray-800 font-bold rounded-full cursor-pointer w-40 py-3"
          >
            Previous step
          </button>
        </div>
      </div></multi-step-form
    >
  </div>
</template>

<script>
import { ref, inject, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { toChecksumAddress } from 'ethereum-checksum-address';
import MultiStepForm from '../components/MultiStepForm.vue';
import EventsBus from '../plugins/eventBus';

export default {
  components: { MultiStepForm },
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const cookies = inject('cookies');
    const walletConnect = inject('walletConnect');
    const store = useStore();
    const { eventBus } = EventsBus();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const issuerConnectionMethod = computed(
      () => store.getters.getCurrentIssuerConnectionMethod
    );

    const allowanceGiven = ref(null);
    const bnbBalance = ref('-');
    const sylBalance = ref('-');
    const issuerFromRegistration = ref(null);
    const issuerFromIPFS = ref(null);

    const profileImageSelectedFile = ref(null);
    const profileImageMessage = ref('Please select a file');
    const profileImagePreview = ref(null);

    const coverImageSelectedFile = ref(null);
    const coverImageMessage = ref('Please select a file');
    const coverImagePreview = ref(null);

    const issuerAddress = ref('');
    const issuerAuthenticationKey = ref(
      '0x020000000000000000000000000000000000000000000000000000000000000000'
    );
    const issuerName = ref('My awesome issuer');
    const issuerNationality = ref('FR');
    const issuerEmail = ref('hello@awesome-issuer.com');
    const issuerWebsite = ref('https://awesome-issuer.com');
    const issuerTwitter = ref('https://twitter.com/my-awesome-issuer');
    const issuerEcosystem = ref('Education');
    const issuerSector = ref('Blockchain');
    const issuerSubsector = ref('Identity');
    const issuerRegistration = ref('123456789');
    const issuerRss = ref('https://rss.awesome-issuer.com');
    const issuerCertificate = ref('');
    const issuerProfileIFPS = ref('');
    const issuerCategory = ref(1);

    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();
    const transactionHandlerAllowanceRef = ref();
    const multiStepForm = ref();
    const errorMessage = ref(null);
    const ipfsLoading = ref(false);
    const updateMethodPriceHandler = ref(0);

    watch(
      () => eventBus.value.get('walletConnect'),
      (val) => {
        const [eventData] = val ?? [];
        console.log('Event received from WalletConnect :', eventData);
        if (
          eventData.params &&
          eventData.params.event &&
          eventData.params.event.data.indexOf('issuerAuthenticationKey') != -1
        ) {
          console.log(
            'Retrieved authentication key',
            eventData.params.event.data.split('#')[1]
          );
          issuerAuthenticationKey.value =
            eventData.params.event.data.split('#')[1];
        }
      }
    );

    if (currentAddress.value) {
      issuerAddress.value = currentAddress.value;
      askAuthenticationKey();
      updateBalances();
      checkAllowance();
      retrieveRegisteredIssuerInfo();
      if (currentIssuerDid.value) {
        utils.mydid.isIssuer(currentAddress.value).then((isIssuer) => {
          if (isIssuer) {
            utils.mydid.getDID(currentAddress.value).then((didDocument) => {
              utils.ipfs
                .getJsonDataFromCID(utils.ipfs.hashToCID(didDocument[1]))
                .then((issuerIPFS) => {
                  issuerFromIPFS.value = issuerIPFS;
                });
            });
          }
        });
      }
    }

    function retrieveRegisteredIssuerInfo() {
      if (currentIssuerDid.value) {
        api.getIssuer(currentIssuerDid.value).then((result) => {
          if (result.data.found)
            issuerFromRegistration.value = result.data.issuer;
        });
      }
    }

    async function onProfileImageInput(event) {
      if (event.target.files[0]['type'].split('/')[0] != 'image') {
        profileImageMessage.value = 'Please select an image';
        return;
      }
      profileImageSelectedFile.value = event.target.files[0];
      profileImageMessage.value = profileImageSelectedFile.value.name;
      const reader = new FileReader();
      reader.readAsDataURL(profileImageSelectedFile.value);
      reader.onload = async (e) => {
        profileImagePreview.value = e.target.result;
      };
    }

    async function onCoverImageInput(event) {
      if (event.target.files[0]['type'].split('/')[0] != 'image') {
        coverImageMessage.value = 'Please select an image';
        return;
      }
      coverImageSelectedFile.value = event.target.files[0];
      coverImageMessage.value = coverImageSelectedFile.value.name;
      const reader = new FileReader();
      reader.readAsDataURL(coverImageSelectedFile.value);
      reader.onload = async (e) => {
        coverImagePreview.value = e.target.result;
      };
    }

    async function createNewIssuer() {
      // // retrieve authentication key
      // if (issuerConnectionMethod.value == 'walletconnect') {
      //   await retrieveAuthenticationKey();
      // }

      // check fields
      errorMessage.value = null;
      if (!issuerName.value || issuerName.value.length < 3) {
        errorMessage.value = 'Issuer name not valid';
        return;
      }
      if (!profileImageSelectedFile.value || !coverImageSelectedFile.value) {
        errorMessage.value = 'Missing profile or cover image';
        return;
      }
      if (
        !issuerNationality.value ||
        !issuerRegistration.value ||
        !issuerEmail.value ||
        !issuerWebsite.value ||
        !issuerTwitter.value ||
        !issuerRss.value
      ) {
        errorMessage.value = 'Missing contact and company information';
        return;
      }
      if (
        !issuerEcosystem.value ||
        !issuerSector.value ||
        !issuerSubsector.value
      ) {
        errorMessage.value = 'Missing sector information';
        return;
      }

      buttonDisable.value = true;
      ipfsLoading.value = true;
      issuerProfileIFPS.value = '';

      // push image on IPFS
      const profileImageIPFSHash = await utils.ipfs.uploadIpfsFile(
        profileImageSelectedFile.value
      );
      const coverImageIPFSHash = await utils.ipfs.uploadIpfsFile(
        coverImageSelectedFile.value
      );

      // push textfile on IPFS
      const issuerProfile = {
        type: 'Issuer Profile',
        id: 'IPFS',
        name: issuerName.value,
        nationality: issuerNationality.value,
        website: issuerWebsite.value,
        email: issuerEmail.value,
        twitter: issuerTwitter.value,
        ecosystem: issuerEcosystem.value,
        sector: issuerSector.value,
        subsector: issuerSubsector.value,
        registration: issuerRegistration.value,
        rss: issuerRss.value,
        certificate: issuerCertificate.value,
        coverImage: utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(coverImageIPFSHash)
        ),
        profileImage: utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(profileImageIPFSHash)
        ),
      };
      const issuerIPFSHash = await utils.ipfs.uploadIpfsJsonData(
        JSON.stringify(issuerProfile)
      );
      issuerProfileIFPS.value = utils.ipfs.getUrlFromCID(
        utils.ipfs.hashToCID(issuerIPFSHash)
      );

      ipfsLoading.value = false;

      api.addIpfsData({
        context: 'issuer-create',
        hash: issuerIPFSHash,
        data: issuerProfile,
      });

      transactionHandlerRef.value.createTransaction(
        issuerCategory.value == '1' ? 'createIssuerDIDV1' : 'createIssuerDIDV2',
        issuerAddress.value,
        '0x' + issuerIPFSHash,
        issuerAuthenticationKey.value
      );
    }

    async function triggerAllowance() {
      transactionHandlerAllowanceRef.value.createSylTransaction(
        'approve',
        process.env.VUE_APP_MYDID_CONTRACT_ADDR,
        '0xffffffffffffffffffffffffffffffff'
      );
    }

    function onTransactionCompleted() {
      buttonDisable.value = false;
      api
        .createIssuer({
          name: issuerName.value,
          did: 'DID:SDI:' + issuerAddress.value,
        })
        .then(() => {
          window.location.href = '/';
        });
    }

    function onTransactionAllowanceCompleted() {
      buttonDisable.value = false;
      setTimeout(checkAllowance(), 1000);
    }

    function updateBalances() {
      console.log('updateBalances', currentAddress.value);
      utils
        .getCurrentProviderBalance(currentAddress.value)
        .then((amount) => (bnbBalance.value = parseFloat(amount).toFixed(6)));
      utils.syl
        .getSylBalance(currentAddress.value)
        .then((amount) => (sylBalance.value = parseFloat(amount)).toFixed(6));
      setInterval(() => {
        if (currentAddress.value) {
          utils
            .getCurrentProviderBalance(currentAddress.value)
            .then(
              (amount) => (bnbBalance.value = parseFloat(amount).toFixed(6))
            );
          utils.syl
            .getSylBalance(currentAddress.value)
            .then((amount) =>
              (sylBalance.value = parseFloat(amount)).toFixed(6)
            );
        }
      }, 10000);
    }

    async function checkAllowance() {
      const allowanceVerification = await utils.syl.verifySylAllowanceForMydid(
        currentAddress.value,
        'createIssuerDIDPrice1'
      );
      if (allowanceVerification.code != 1) allowanceGiven.value = true;
      else allowanceGiven.value = false;
      updateMethodPriceHandler.value = updateMethodPriceHandler.value + 1;
    }

    async function retrieveAuthenticationKey() {
      const response = await walletConnect.connector.signPersonalMessage([
        utils.asciiToHex('authenticationKey'),
        currentAddress.value,
      ]);
      issuerAuthenticationKey.value = response;
    }

    function askAuthenticationKey() {
      if (walletConnect.value) {
        const session = walletConnect.value.getSession();
        if (session) {
          walletConnect.value.connector.emit({
            topic: session.topic,
            event: { name: 'dappEvent', data: 'issuerAuthenticationKey' },
            chainId: `eip155:${Number(process.env.VUE_APP_CHAIN_ID)}`,
          });
        }
      }
    }

    async function disconnectIssuer() {
      walletConnect.value.disconnectSessions();
      cookies.remove('expirationTime');
      cookies.remove('expirationSignature');
      store.commit('resetIssuerState');
      store.commit('setCurrentAddress', null);
    }

    function connectIssuer() {
      cookies.remove('expirationTime');
      cookies.remove('expirationSignature');
      store.commit('resetIssuerState');
      store.commit('setIssuerConnectionDisplay', true);
    }

    return {
      issuerConnectionMethod,
      currentIssuerDid,
      currentAddress,
      allowanceGiven,
      bnbBalance,
      sylBalance,
      issuerFromIPFS,
      issuerFromRegistration,
      onProfileImageInput,
      onCoverImageInput,
      profileImageSelectedFile,
      profileImageMessage,
      profileImagePreview,
      coverImageSelectedFile,
      coverImageMessage,
      coverImagePreview,
      issuerAddress,
      issuerName,
      issuerNationality,
      issuerEmail,
      issuerWebsite,
      issuerTwitter,
      issuerEcosystem,
      issuerSector,
      issuerSubsector,
      issuerRegistration,
      issuerRss,
      issuerCertificate,
      issuerAuthenticationKey,
      issuerProfileIFPS,
      issuerCategory,
      createNewIssuer,
      buttonDisable,
      transactionHandlerRef,
      transactionHandlerAllowanceRef,
      onTransactionCompleted,
      onTransactionAllowanceCompleted,
      multiStepForm,
      errorMessage,
      triggerAllowance,
      ipfsLoading,
      disconnectIssuer,
      updateMethodPriceHandler,
      connectIssuer,
    };
  },
};
</script>
