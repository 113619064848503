<template>
  <admin-checker>
    <div class="my-10 mx-20">
      <div class="flex items-center mb-5">
        <p class="text-xl font-bold text-gray-600">
          <router-link to="/"
            ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
          >Withdraw fees
        </p>
        <p
          class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
        >
          admin
        </p>
      </div>
      <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
      <p class="text-sm text-gray-600 mb-10">
        <i class="fas fa-info-circle text-gray-700 mr-2"></i>Withdraw fees from
        myDid smart contract wallet.
      </p>
      <p class="text-gray-600 mr-3 mb-3">Withdrawal address :</p>
      <textarea
        type="text"
        placeholder="Address value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
        v-model="withdrawAddress"
      />
      <p class="text-gray-600 mr-3 mb-3">Amount in SYL :</p>
      <textarea
        type="text"
        placeholder="Value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-7"
        v-model="withdrawAmount"
      />
      <button
        class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        :class="{
          'pointer-events-none opacity-50': buttonDisable,
        }"
        @click="
          () => {
            buttonDisable = true;
            transactionHandlerRef.createTransaction(
              'withdrawFees',
              withdrawAddress,
              withdrawAmount * Math.pow(10, 6)
            );
          }
        "
      >
        Withdraw fees
      </button>
      <transaction-handler
        ref="transactionHandlerRef"
        @transactionCompleted="buttonDisable = false"
        @transactionFailed="buttonDisable = false"
      ></transaction-handler></div
  ></admin-checker>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const withdrawAddress = ref('');
    const withdrawAmount = ref(0);
    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();

    return {
      withdrawAddress,
      withdrawAmount,
      buttonDisable,
      transactionHandlerRef,
    };
  },
};
</script>
