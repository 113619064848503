import Axios from 'axios';
import VueCookies from 'vue-cookies';
import { createToast } from 'mosha-vue-toastify';
import router from '../router';

const APIBaseURL = process.env.VUE_APP_BACKEND_URL;

const adapter = Axios.create({
  baseURL: APIBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const monitoringAdapter = Axios.create({
  baseURL: process.env.VUE_APP_MONITORING_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.VUE_APP_MONITORING_KEY,
  },
});

const firebaseDLinksAdapter = Axios.create({
  baseURL: process.env.VUE_APP_FIREBASEDLINK_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

adapter.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = VueCookies.get('token')
      ? 'Bearer ' + VueCookies.get('token')
      : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function eachRecursive(obj) {
  for (var k in obj) {
    if (typeof obj[k] == 'object' && obj[k] !== null) eachRecursive(obj[k]);
    else if (obj[k] == null) obj[k] = '';
  }
}

export default {
  install(app) {
    adapter.interceptors.response.use(
      (response) => {
        eachRecursive(response.data);
        return response;
      },
      (error) => {
        if (error.reponse) {
          if (
            error.response.status === 404 ||
            error.response.status === 401 ||
            error.response.status === 500 ||
            error.response.status === 503
          ) {
            VueCookies.remove('token');
            router.push('/');
          }
        }

        if (error.message === 'Network Error') {
          console.log("Can't reach server");
          createToast("Can't reach server, please try again later", {
            position: 'bottom-center',
            hideProgressBar: true,
            toastBackgroundColor: '#111827',
          });
          router.push('/');
        }

        return Promise.reject(error);
      }
    );

    var evtSource = null;

    var api = {
      isTimeExpired(time) {
        return adapter.get(`/auth/time-info?time=${time}`);
      },
      addTransaction(hash, action, did) {
        return adapter.post('/issuers/transactions', {
          hash,
          action,
          did,
        });
      },
      addIpfsData(body) {
        return adapter.post('/ipfs-data/', body);
      },
      createIssuer(body) {
        return adapter.post('/issuers/', body);
      },
      isAdminForIssuer(issuerDid) {
        return adapter.get('/issuers/is-admin/' + issuerDid);
      },
      getAuthorizedUsersForIssuer(issuerDid) {
        return adapter.get('/issuers/authorized-users/' + issuerDid);
      },
      addAuthorizedUserToIssuer(body) {
        return adapter.put('/issuers/authorized-users/add', body);
      },
      removeAuthorizedUserFromIssuer(body) {
        return adapter.put('/issuers/authorized-users/remove', body);
      },
      getIssuers() {
        return adapter.get('/issuers/');
      },
      getIssuer(did) {
        return adapter.get('/issuers/issuer/' + did);
      },
      getIssuersForUser() {
        return adapter.get('/issuers/user/');
      },
      getIssuerTransactions(issuerDid, fromHash, number) {
        return adapter.get('/issuers/transactions/' + issuerDid, {
          params: { fromHash, number },
        });
      },
      createChallengeSignup() {
        return adapter.post('/auth/challenge/');
      },
      waitChallengeValidation(challenge, next) {
        evtSource = new EventSource(APIBaseURL + '/auth/sse/' + challenge, {
          xhrHeaders: {
            'Content-Type': 'text/event-stream',
            Connection: 'keep-alive',
          },
        });

        evtSource.addEventListener(
          'message',
          (event) => {
            const response = JSON.parse(event.data).data;
            console.log('Receive elements from stream : ', response);
            if (
              response.status == 'validated' &&
              response.challenge == challenge
            ) {
              console.log('Great, you did sign in');
              evtSource.close();
              return next(response);
            } else if (response.status == 'expired') {
              console.log('Challenge expired, you did not sign in');
              evtSource.close();
              return next(response);
            } else {
              next(response);
            }
          },
          false
        );

        evtSource.addEventListener(
          'error',
          (event) => {
            if (event.readyState === EventSource.CLOSED) {
              console.log('Event was closed');
              console.log(EventSource);
            }
          },
          false
        );
        return evtSource;
      },
      closeChallengeValidation() {
        if (evtSource) evtSource.close();
      },
      getStatistics(fromDate, toDate) {
        return monitoringAdapter.get(
          `/events?fromDate=${fromDate}&toDate=${toDate}`
        );
      },
      getUniverseDLink(address, cid) {
        return firebaseDLinksAdapter.post(
          `/v1/shortLinks?key=${process.env.VUE_APP_FIREBASEDLINK_KEY}`,
          {
            dynamicLinkInfo: {
              domainUriPrefix: 'mydid.page.link',
              link: `https://mydid.app.dev/custom-universe?issuer=${address.replace(
                '0x',
                ''
              )}&cid=${cid}`,
              androidInfo: {
                androidPackageName: 'com.mydid.wallet',
              },
              iosInfo: {
                iosBundleId: 'com.mydidsa.wallet',
                iosAppStoreId: '1630348267',
              },
            },
            suffix: {
              option: 'SHORT',
            },
          }
        );
      },
    };
    app.provide('api', api);
  },
};
