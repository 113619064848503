<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Manage app universe list
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        universe
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-10">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Get app universe list
      for current issuer.
    </p>
    <p class="font-bold text-gray-600 mb-5">
      <i class="fas fa-certificate mr-2"></i>App universe list
    </p>
    <div class="flex flex-col mb-10">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="w-full table-auto divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    Issuer DID
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    Ranking
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    IPFS
                  </th>
                  <th
                    v-if="!isIssuerReadOnly"
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="universe in universeList"
                :key="universe"
                class="bg-white divide-y divide-gray-200"
                :class="{ 'opacity-50': universe.rank == 0 }"
              >
                <tr>
                  <td class="px-6 py-4">
                    <p class="text-sm font-bold text-gray-700">
                      {{ universe.info ? universe.info.localeNames.en : '-' }}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p class="text-sm font-medium text-gray-700">
                      {{ 'DID:SDI:' + universe.issuer }}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p>{{ universe.rank }}</p>
                  </td>
                  <td class="px-6 py-4">
                    <a
                      v-if="universe.info"
                      :href="universe.info.ipfs"
                      target="_blank"
                      ><p class="underline text-xs">Full profile</p></a
                    >
                    <p v-else>-</p>
                  </td>
                  <td v-if="!isIssuerReadOnly" class="px-6 py-4">
                    <p
                      @click="
                        () => {
                          universeListAddShow = false;
                          universeListUpdateShow = true;
                          universeListUpdateIssuerDid =
                            'DID:SDI:' + universe.issuer;
                          universeListUpdateRanking = universe.rank;
                          universeListUpdateIndex = universe.index;
                        }
                      "
                      class="text-gray-700 cursor-pointer"
                    >
                      <i class="fas fa-edit"></i>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="!isIssuerReadOnly"
              @click="
                () => {
                  universeListUpdateShow = false;
                  universeListAddShow = true;
                }
              "
              class="bg-gray-50 text-center border-t cursor-pointer py-3"
            >
              <p class="text-gray-700 font-bold">ADD UNIVERSE</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="universeListLoading" class="flex justify-center mt-2">
        <custom-loader class="pb-3" color="#111827" size="30px">
        </custom-loader>
      </div>
    </div>
    <div v-if="universeListUpdateShow">
      <p class="text-gray-600 text-lg font-bold mb-5">
        <i class="fas fa-cog mr-3"></i>Update universe
      </p>
      <p class="text-gray-600 mr-3 mb-3">
        Issuer DID corresponding to universe :
      </p>
      <textarea
        type="text"
        placeholder="DID value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
        v-model="universeListUpdateIssuerDid"
      />
      <p class="text-gray-600 mr-3 mb-3">Universe ranking :</p>
      <textarea
        type="text"
        placeholder="Ranking value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="universeListUpdateRanking"
      />
      <button
        class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        :class="{
          'pointer-events-none opacity-50': buttonDisable,
        }"
        @click="
          () => {
            buttonDisable = true;
            transactionHandlerRef.createTransaction(
              'updateUniverseInAppUniversesList',
              universeListUpdateIssuerDid.replace('DID:SDI:', ''),
              universeListUpdateRanking,
              universeListUpdateIndex
            );
          }
        "
      >
        Update universe
      </button>
    </div>
    <div v-if="universeListAddShow">
      <p class="text-gray-600 text-lg font-bold mb-5">
        <i class="fas fa-cog mr-3"></i>Add universe to list
      </p>
      <p class="text-gray-600 mr-3 mb-3">
        Issuer DID corresponding to universe :
      </p>
      <textarea
        type="text"
        placeholder="DID value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
        v-model="universeListAddIssuerDid"
      />
      <p class="text-gray-600 mr-3 mb-3">Universe ranking :</p>
      <textarea
        type="text"
        placeholder="Ranking value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="universeListAddRanking"
      />
      <method-price-handler
        v-if="universeList.length == 0"
        method="createAppUniversesListPrice"
        class="mb-2"
      ></method-price-handler>
      <p
        v-if="
          universeList
            .map((e) => e.issuer)
            .indexOf(universeListAddIssuerDid.replace('DID:SDI:', '')) != -1
        "
        class="text-red-700 mb-2"
      >
        Universe already in app list
      </p>
      <button
        class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        :class="{
          'pointer-events-none opacity-50':
            buttonDisable ||
            universeList
              .map((e) => e.issuer)
              .indexOf(universeListAddIssuerDid.replace('DID:SDI:', '')) != -1,
        }"
        @click="
          () => {
            buttonDisable = true;
            transactionHandlerRef.createTransaction(
              universeList.length > 0
                ? 'AddToAppUniversesList'
                : 'CreateAppUniversesList',
              [universeListAddIssuerDid.replace('DID:SDI:', '')],
              [universeListAddRanking]
            );
          }
        "
      >
        Add universe
      </button>
    </div>
    <div v-if="universeListUpdateShow || universeListAddShow">
      <transaction-handler
        @transactionCompleted="onTransactionCompleted"
        @transactionFailed="buttonDisable = false"
        ref="transactionHandlerRef"
      ></transaction-handler>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const isIssuerReadOnly = computed(() => store.getters.isIssuerReadOnly);

    const universeList = ref(null);
    const universeListLoading = ref(false);

    const universeListUpdateShow = ref(false);
    const universeListUpdateIssuerDid = ref('');
    const universeListUpdateRanking = ref('');
    const universeListUpdateIndex = ref(null);

    const universeListAddShow = ref(false);
    const universeListAddIssuerDid = ref('');
    const universeListAddRanking = ref('');

    const transactionHandlerRef = ref();
    const buttonDisable = ref(false);

    async function getAppUniverseList() {
      if (!currentIssuerDid.value) return;

      universeListLoading.value = true;

      utils.mydid
        .getAppUniverseList(currentIssuerDid.value.replace('DID:SDI:', ''))
        .then(async (result) => {
          result.sort((a, b) => (a.rank != 0 ? a.rank - b.rank : false));
          universeList.value = result;
          universeListLoading.value = false;
          getAppUniversesProfiles();
        });
    }

    async function getAppUniversesProfiles() {
      for (var i = 0; i < universeList.value.length; i++) {
        const universeIpfsHash = await utils.mydid.getUniverse(
          universeList.value[i].issuer
        );
        universeList.value[i]['info'] = Object.assign(
          await utils.ipfs.getJsonDataFromCID(
            utils.ipfs.hashToCID(universeIpfsHash)
          ),
          {
            ipfs: utils.ipfs.getUrlFromCID(
              utils.ipfs.hashToCID(universeIpfsHash)
            ),
          }
        );
      }
    }

    function onTransactionCompleted() {
      buttonDisable.value = false;
      getAppUniverseList();
    }

    getAppUniverseList();

    return {
      universeList,
      universeListLoading,
      isIssuerReadOnly,
      universeListUpdateShow,
      universeListUpdateIssuerDid,
      universeListUpdateRanking,
      universeListUpdateIndex,
      universeListAddShow,
      universeListAddIssuerDid,
      universeListAddRanking,
      transactionHandlerRef,
      onTransactionCompleted,
      buttonDisable,
    };
  },
};
</script>
