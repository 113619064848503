<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Get public reference list
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        verifiable credentials
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-10">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Get information about
      public references.
    </p>
    <p class="text-gray-600 mr-3 mb-3">User DID :</p>
    <textarea
      type="text"
      placeholder="DID value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
      v-model="userDid"
    />
    <p class="text-gray-600 mr-3 mb-3">Reference type :</p>
    <textarea
      type="text"
      placeholder="Value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-7"
      v-model="refType"
    />
    <button
      class="
        text-sm
        bg-primary-light
        text-white
        font-bold
        inline-block
        rounded-lg
        cursor-pointer
        py-3
        px-5
        mb-10   
      "
      @click="getPublicRefList"
    >
      Get public references list
    </button>
    <div
      v-if="publicRefList && publicRefList.length > 0"
      class="flex flex-col mb-10"
    >
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="w-full table-auto divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    IPFS link
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="ref in publicRefList"
                :key="ref"
                class="bg-white divide-y divide-gray-200"
              >
                <tr>
                  <td class="px-6 py-4">
                    <a
                      :href="
                        utils.ipfs.getUrlFromCID(
                          utils.ipfs.hashToCID(ref.replace('0x', ''))
                        )
                      "
                      target="_blank"
                    >
                      <p class="text-xs text-blue-600 underline break-all">
                        {{
                          utils.ipfs.getUrlFromCID(
                            utils.ipfs.hashToCID(ref.replace('0x', ''))
                          )
                        }}
                      </p></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <p v-if="publicRefList && publicRefList.length == 0" class="text-gray-700">
      <i class="fas fa-times mr-2"></i>No public reference
    </p>
    <div v-if="errorMessage" class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5">
      <p class="text-gray-600 text-md font-bold mb-1">
        <i class="fas fa-exclamation-circle mr-2"></i>An error occured :
      </p>
      <p class="bg-red-200 h-16 overflow-y-scroll px-2 py-1">
        <span class="text-xxs leading-none my-4">{{ errorMessage }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const utils = inject('utils');

    const publicRefList = ref(null);
    const userDid = ref('');
    const refType = ref(1);

    const errorMessage = ref(null);

    async function getPublicRefList() {
      errorMessage.value = null;
      try {
        publicRefList.value = await utils.mydid.getPublicVCsByType(
          userDid.value.replace('DID:SDI:', ''),
          refType.value
        );
      } catch (e) {
        console.log(e);
        errorMessage.value = e;
        return;
      }
    }

    return {
      userDid,
      refType,
      publicRefList,
      getPublicRefList,
      errorMessage,
      utils,
    };
  }
};
</script>
