<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Set attribute
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        users
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-10">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Set or revoke
      attribute of a DID document.
    </p>
    <p class="text-gray-600 mr-3 mb-3">Identity address :</p>
    <textarea
      type="text"
      placeholder="Address value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="identityAddress"
    />
    <div class="grid grid-cols-2 gap-5">
      <div>
        <p class="text-gray-600 mr-3 mb-3">Attribute name :</p>
        <select
          required
          rows="1"
          class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
          v-model="attributeName"
        >
          <option disabled selected value="">Please select an option</option>
          <option value="AUTH">Authentication</option>
          <option value="ASSR">AssertionMethod</option>
          <option value="KEYA">KeyAgreement</option>
          <option value="CAPI">Capability Invocation</option>
          <option value="CAPD">Capability Delegation</option>
          <option value="SERV">Services</option>
        </select>
      </div>
      <div v-if="attributeName != 'SERV'">
        <p class="text-gray-600 mr-3 mb-3">Method Type :</p>
        <select
          required
          rows="1"
          class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
          v-model="methodType"
        >
          <option disabled selected value="">Please select an option</option>
          <option value="ED19_VR18">Ed25519VerificationKey2018</option>
          <option value="ECK1_VR19">EcdsaSecp256k1VerificationKey2019</option>
          <option value="ECK1_RM20">EcdsaSecp256k1RecoveryMethod2020</option>
          <option value="PGPK_VR21">PgpVerificationKey2021</option>
        </select>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-5">
      <div v-if="attributeName != 'SERV'">
        <p class="text-gray-600 mr-3 mb-3">Encoding :</p>
        <select
          required
          rows="1"
          class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
          v-model="encoding"
        >
          <option disabled selected value="">Please select an option</option>
          <option
            v-if="
              ['ED19_VR18', 'ECK1_VR19', 'PGPK_VR21'].indexOf(methodType) != -1
            "
            value="PUBM"
          >
            publicKeyMultibase
          </option>
          <option
            v-if="['ECK1_VR19', 'ECK1_RM20'].indexOf(methodType) != -1"
            value="BCAC"
          >
            blockchainAccountId
          </option>
        </select>
      </div>
      <div v-else>
        <p class="text-gray-600 mr-3 mb-3">Type (max 10 chars) :</p>
        <textarea
          type="text"
          placeholder="Value"
          rows="1"
          maxlength="10"
          class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
          v-model="serviceType"
        />
      </div>
      <div>
        <p class="text-gray-600 mr-3 mb-3">Validity :</p>
        <select
          required
          rows="1"
          class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
          v-model="validity"
        >
          <option disabled selected value="">Please select an option</option>
          <option value="99">99 years (max)</option>
          <option value="1">01 years</option>
          <option value="2">02 years</option>
          <option value="3">03 years</option>
          <option value="5">05 years</option>
          <option value="0">00 revoke</option>
        </select>
      </div>
    </div>

    <p v-if="attributeName != 'SERV'" class="text-gray-600 mr-3 mb-3">
      Attribute value :
    </p>
    <p v-else class="text-gray-600 mr-3 mb-3">Endpoint value :</p>
    <textarea
      v-if="attributeName != 'SERV'"
      type="text"
      placeholder="Value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="attributeValue"
    />
    <textarea
      v-else
      type="text"
      placeholder="Value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="serviceEndpoint"
    />
    <button
      class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
      :class="{
        'pointer-events-none opacity-50': buttonDisable,
      }"
      @click="
        () => {
          buttonDisable = true;
          if (validity == 0) {
            transactionHandlerRef.createTransaction(
              'revokeAttribute',
              identityAddress,
              attributeName != 'SERV'
                ? utils.asciiToHex(`${attributeName},${methodType},${encoding}`)
                : utils.asciiToHex(`${attributeName},${serviceType}`),
              attributeName != 'SERV' ? attributeValue : serviceEndpoint
            );
          } else {
            transactionHandlerRef.createTransaction(
              'setAttribute',
              identityAddress,
              attributeName != 'SERV'
                ? utils.asciiToHex(`${attributeName},${methodType},${encoding}`)
                : utils.asciiToHex(`${attributeName},${serviceType}`),
              validity * 365 * 24 * 60 * 60,
              attributeName != 'SERV' ? attributeValue : serviceEndpoint
            );
          }
        }
      "
    >
      Set attribute
    </button>
    <transaction-handler
      ref="transactionHandlerRef"
      @transactionCompleted="buttonDisable = false"
      @transactionFailed="buttonDisable = false"
    ></transaction-handler>
  </div>
</template>

<script>
import { ref, inject, watch, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    const identityAddress = ref(currentIssuerDid.value ?? null);
    const attributeName = ref('AUTH');
    const methodType = ref('ED19_VR18');
    const encoding = ref('PUBM');
    const validity = ref(99);
    const serviceType = ref('Website');
    const serviceEndpoint = ref('https://mydid.com/');
    const attributeValue = ref('');

    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();

    watch(methodType, (type) => {
      if (type == 'ECK1_RM20') encoding.value = 'BCAC';
      if (type == 'ED19_VR18' || type == 'PGPK_VR21') encoding.value = 'PUBM';
    });

    return {
      identityAddress,
      attributeName,
      methodType,
      encoding,
      validity,
      serviceType,
      serviceEndpoint,
      attributeValue,
      utils,
      buttonDisable,
      transactionHandlerRef,
    };
  },
};
</script>
