import { createApp } from 'vue';

import './styles.css';
import 'mosha-vue-toastify/dist/style.css';
import '@fortawesome/fontawesome-free/js/all.js';
import VueCookies from 'vue-cookies';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';
import QrCodeVue from 'qrcode.vue';
import VueCountryRegionSelect from 'vue3-country-region-select';
import Datepicker from 'vue3-datepicker';
import Vue3ColorPicker from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import CountryFlag from 'vue-country-flag-next';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import autocolors from 'chartjs-plugin-autocolors';
import 'chartjs-adapter-moment';
Chart.register(autocolors);
Chart.register(ChartDataLabels);

import router from './router.js';
import store from './store';
import api from './plugins/api.js';
import utils from './plugins/utils.js';
import walletConnect from './plugins/walletConnect';

import App from './App.vue';
import NavBar from './components/NavBar.vue';
import TransactionBar from './components/TransactionBar.vue';
import Wallets from './components/Wallets.vue';
import Banner from './components/Banner.vue';
import IssuerConnect from './components/IssuerConnect.vue';
import UserConnect from './components/UserConnect.vue';
import UserSignin from './components/UserSignin.vue';
import AdminChecker from './components/AdminChecker.vue';
import TransactionHandler from './components/TransactionHandler.vue';
import MethodPriceHandler from './components/MethodPriceHandler.vue';
import MultiStepForm from './components/MultiStepForm.vue';
import MultiStepFormOld from './components/MultiStepFormOld.vue';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(api);
app.use(utils);
app.use(walletConnect);
app.use(VueCountryRegionSelect);
app.use(Vue3ColorPicker);

app.provide('cookies', VueCookies);
app.component('nav-bar', NavBar);
app.component('transaction-bar', TransactionBar);
app.component('wallets', Wallets);
app.component('banner', Banner);
app.component('issuer-connect', IssuerConnect);
app.component('user-connect', UserConnect);
app.component('user-signin', UserSignin);
app.component('admin-checker', AdminChecker);
app.component('transaction-handler', TransactionHandler);
app.component('method-price-handler', MethodPriceHandler);
app.component('multi-step-form', MultiStepForm);
app.component('multi-step-form-old', MultiStepFormOld);
app.component('qrcode-vue', QrCodeVue);
app.component('custom-loader', BounceLoader);
app.component('datepicker', Datepicker);
app.component('country-flag', CountryFlag);

app.mount('#app');
