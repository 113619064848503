<template>
  <div v-if="totalSteps && currentStep != null">
    <div
      v-if="totalSteps > 0"
      class="grid items-center pb-3"
      :style="`grid-template-columns: repeat(${totalSteps}, minmax(0, 1fr));`"
    >
      <div
        v-for="(n, step) in totalSteps"
        class="cursor-pointer py-2"
        :class="{
          'border-b': step != currentStep,
          'border-b-2 border-black': step == currentStep,
          'bg-success': step < currentStep,
        }"
        @click="
          () => {
            changeStep(step);
          }
        "
      >
        <div class="flex items-center justify-center">
          <div
            class="px-2 py-1"
            :class="{
              'opacity-50': step > currentStep,
              'font-bold': step <= currentStep,
              'text-white': step < currentStep,
            }"
          >
            <p class="text-xs">
              <span v-if="step < currentStep"
                ><i class="fas fa-check mr-1"></i></span
              >{{ stepNames[step] ? stepNames[step] : `Step ${step + 1}` }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-center h-full"
      :style="'min-height:' + maxHeight + 'px'"
    >
      <div class="grow my-5" ref="formElements">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
export default {
  props: {
    stepNames: Array,
  },
  setup(props) {
    const formElements = ref();
    const currentStep = ref(0);
    const totalSteps = ref(props.stepNames.length);
    const maxHeight = ref(0);

    onMounted(() => {
      formElements.value.children.forEach((step) => {
        maxHeight.value =
          step.clientHeight > maxHeight.value
            ? step.clientHeight
            : maxHeight.value;
      });
      refreshSteps();
      changeStep(0);
    });

    function refreshSteps() {
      var index = 0;
      for (var step of formElements.value.children) {
        if (index != currentStep.value) {
          step.style.display = 'none';
        } else {
          step.style.display = 'inline';
        }
        index++;
      }
    }

    function changeStep(newStep) {
      currentStep.value = newStep;
      refreshSteps();
    }

    function nextStep() {
      if (currentStep.value >= totalSteps.value - 1) return;
      currentStep.value = currentStep.value + 1;
      refreshSteps();
    }

    function previousStep() {
      if (currentStep.value == 0) return;
      currentStep.value = currentStep.value - 1;
      refreshSteps();
    }

    return {
      formElements,
      currentStep,
      totalSteps,
      maxHeight,
      changeStep,
      nextStep,
      previousStep,
    };
  },
};
</script>
