<template>
  <div
    v-if="displayIssuerConnection && !currentIssuerDid"
    class="bg-black bg-opacity-50 fixed top-0 left-0 z-10 w-full h-full overflow-auto"
  >
    <div
      class="bg-white w-1/2 absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md rounded-lg p-10"
    >
      <div v-if="!currentIssuerDid && waitingSignature">
        <div class="flex justify-center items-center mb-5">
          <custom-loader class="mr-3" color="#028ef6" size="20px">
          </custom-loader>
          <p class="text-center text-lg font-bold">
            Waiting signature from WalletConnect
          </p>
        </div>
        <p
          @click="cancelSignature"
          class="text-center underline cursor-pointer"
        >
          Cancel
        </p>
      </div>
      <div v-else>
        <div class="flex justify-center items-center">
          <div
            class="flex items-center gap-4 border-black border-opacity-10 border w-96 mx-auto rounded-lg cursor-pointer p-3 mb-5"
            @click="connectWalletConnect"
            :class="{ 'pointer-events-none opacity-50': !walletConnect }"
          >
            <img
              :src="require('/assets/images/walletconnect.png')"
              alt=""
              class="w-8 my-auto"
            />
            <p class="font-bold">Connect with WalletConnect</p>
            <div class="flex-grow"></div>
            <custom-loader
              v-if="!walletConnect"
              class="mr-3"
              color="#028ef6"
              size="20px"
            >
            </custom-loader>
          </div>
        </div>
        <div class="flex justify-center gap-5">
          <p
            class="text-center text-xs underline text-gray-500 cursor-pointer mb-3"
            @click="setIssuerConnectionDisplay(false)"
          >
            Continue without issuer
          </p>
          <router-link
            v-if="route.path != '/create-issuer'"
            to="/create-issuer"
          >
            <p
              class="text-center text-xs underline text-gray-500 cursor-pointer"
              @click="setIssuerConnectionDisplay(false)"
            >
              Create a new issuer
            </p></router-link
          >
        </div>
      </div>
    </div>
  </div>
  <div class="flex">
    <div
      v-if="areVerticalBarsDisplayed"
      :key="currentIssuerDid"
      class="flex-none w-60 min-h-screen filter drop-shadow-2xl relative overflow-auto bg-primary"
    >
      <div class="absolute w-full h-full">
        <nav-bar class="min-h-full"></nav-bar>
      </div>
    </div>
    <div
      class="flex-1 min-h-screen relative overflow-auto"
      :key="[currentIssuerDid, currentAddress].toString()"
    >
      <div class="absolute w-full">
        <router-view v-slot="slotProps">
          <banner></banner>
          <component
            :is="slotProps.Component"
            :class="{
              'pointer-events-none opacity-50':
                currentIssuerDid && !isCurrentChaindIdValid,
            }"
          ></component>
        </router-view>
      </div>
    </div>
    <div
      v-if="areVerticalBarsDisplayed"
      class="flex-none w-60 min-h-screen filter drop-shadow-2xl relative overflow-auto bg-primary"
    >
      <div class="absolute w-full h-full">
        <transaction-bar
          class="min-h-full"
          :key="currentIssuerDid"
        ></transaction-bar>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, provide, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { recoverTypedSignature } from '@metamask/eth-sig-util';
import { createToast } from 'mosha-vue-toastify';
import EventsBus from './plugins/eventBus';
import initWalletConnect from './plugins/walletConnect';

export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const cookies = inject('cookies');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { eventBus } = EventsBus();

    const walletConnect = ref(null);
    provide('walletConnect', walletConnect);

    const waitingSignature = ref(false);

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const displayIssuerConnection = computed(
      () => store.getters.isIssuerConnectionDisplayed
    );
    const isCurrentChaindIdValid = computed(
      () => store.getters.isCurrentChaindIdValid
    );
    const areVerticalBarsDisplayed = computed(
      () => store.getters.areVerticalBarsDisplayed
    );

    initWalletConnect().then((wc) => {
      walletConnect.value = wc;
      const session = walletConnect.value.getSession();
      if (session) {
        verifyPreviousWalletConnectSession(session);
      }
    });

    watch(
      () => eventBus.value.get('walletConnect'),
      (val) => {
        const [eventData] = val ?? [];
        console.log('Event received from WalletConnect :', eventData);
        if (eventData == 'disconnect') {
          cookies.remove('expirationTime');
          cookies.remove('expirationSignature');
          store.commit('resetIssuerState');
          disconnectWalletConnect();
        } else if (eventData == 'connect') {
          const session = walletConnect.value.getSession();
          if (session) {
            const currentAddress = toChecksumAddress(
              session.namespaces.eip155.accounts[0].split(':')[2]
            );
            verifyWalletConnectAddress(currentAddress);
          }
        }
      }
    );

    async function verifyPreviousWalletConnectSession(session) {
      const currentAddress = toChecksumAddress(
        session.namespaces.eip155.accounts[0].split(':')[2]
      );
      const expirationTime = cookies.get('expirationTime');
      const expirationSignature = cookies.get('expirationSignature');

      if (expirationTime && expirationSignature) {
        // retrieve address
        const typedData = utils.sign.getParamsTypedDataV4(
          { value: expirationTime },
          'AuthHash',
          {
            EIP712Domain: [
              {
                name: 'name',
                type: 'string',
              },
              {
                name: 'version',
                type: 'string',
              },
              {
                name: 'chainId',
                type: 'uint256',
              },
              {
                name: 'verifyingContract',
                type: 'address',
              },
            ],
            AuthHash: [
              {
                name: 'value',
                type: 'string',
              },
            ],
          },
          process.env.VUE_APP_CHAIN_ID,
          'myDid',
          '0x7e52a123ed6db6ac872a875552935fbbd2544c86',
          '1'
        );
        const recoveredAddress = toChecksumAddress(
          recoverTypedSignature({
            data: typedData,
            signature: expirationSignature,
            version: 'V4',
          })
        );
        const { expired } = (await api.isTimeExpired(expirationTime / 1000))
          .data;

        if (recoveredAddress == currentAddress && !expired) {
          store.commit(
            'setCurrentIssuerDid',
            'DID:SDI:' + toChecksumAddress(currentAddress)
          );
          console.log(
            'Recover previous walletConnect session with address :',
            currentAddress
          );
          return;
        }
      }

      cookies.remove('expirationTime');
      cookies.remove('expirationSignature');
      store.commit('resetIssuerState');
      disconnectWalletConnect();
    }

    function verifyWalletConnectAddress(currentAddress) {
      waitingSignature.value = true;
      // const message = '0x' + crypto.randomBytes(32).toString('hex');
      const message = (Date.now() + 24 * 60 * 60 * 1000).toString();

      const typedData = {
        domain: {
          chainId: process.env.VUE_APP_CHAIN_ID,
          name: 'myDid',
          verifyingContract: '0x7e52a123ed6db6ac872a875552935fbbd2544c86',
          version: '1',
        },
        message: { value: message },
        primaryType: 'AuthHash',
        types: {
          EIP712Domain: [
            {
              name: 'name',
              type: 'string',
            },
            {
              name: 'version',
              type: 'string',
            },
            {
              name: 'chainId',
              type: 'uint256',
            },
            {
              name: 'verifyingContract',
              type: 'address',
            },
          ],
          AuthHash: [
            {
              name: 'value',
              type: 'string',
            },
          ],
        },
      };

      const session = walletConnect.value.getSession();

      walletConnect.value.connector
        .request({
          topic: session.topic,
          chainId: `eip155:${Number(process.env.VUE_APP_CHAIN_ID)}`,
          request: {
            id: 1,
            jsonrpc: '2.0',
            method: 'eth_signTypedData',
            params: [currentAddress, JSON.stringify(typedData)],
          },
        })
        .then((signedHash) => {
          waitingSignature.value = false;

          // verify signature
          const recoveredAddress = toChecksumAddress(
            recoverTypedSignature({
              data: typedData,
              signature: signedHash,
              version: 'V4',
            })
          );

          if (recoveredAddress == currentAddress) {
            api.getIssuer('DID:SDI:' + currentAddress).then((result) => {
              if (result.data.found) {
                store.commit(
                  'setCurrentIssuerDid',
                  'DID:SDI:' + toChecksumAddress(currentAddress)
                );
                cookies.set('expirationTime', message);
                cookies.set('expirationSignature', signedHash);
              } else {
                if (route.path != '/create-issuer') {
                  createToast(`Issuer is not valid`, {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    toastBackgroundColor: '#111827',
                  });
                  disconnectWalletConnect();
                }

                cookies.remove('expirationTime');
                cookies.remove('expirationSignature');
                store.commit(
                  'setCurrentAddress',
                  toChecksumAddress(currentAddress)
                );
                store.commit('resetIssuerState');
                store.commit('setIssuerConnectionDisplay', false);
                waitingSignature.value = false;
              }
            });
          } else {
            createToast('Bad signature with WalletConnect', {
              position: 'bottom-center',
              hideProgressBar: true,
              toastBackgroundColor: '#111827',
            });
            cookies.remove('expirationTime');
            cookies.remove('expirationSignature');
            store.commit('resetIssuerState');
            store.commit('setIssuerConnectionDisplay', false);
            disconnectWalletConnect();
            waitingSignature.value = false;
          }
        })
        .catch((err) => {
          console.log(err);
          createToast('Error while signing with WalletConnect', {
            position: 'bottom-center',
            hideProgressBar: true,
            toastBackgroundColor: '#111827',
          });
          cookies.remove('expirationTime');
          cookies.remove('expirationSignature');
          store.commit('resetIssuerState');
          store.commit('setIssuerConnectionDisplay', false);
          disconnectWalletConnect();
          waitingSignature.value = false;
        });
    }

    function cancelSignature() {
      store.commit('resetIssuerState');
      store.commit('setIssuerConnectionDisplay', false);
      disconnectWalletConnect();
      waitingSignature.value = false;
    }

    function setIssuerConnectionDisplay(state) {
      store.commit('setIssuerConnectionDisplay', state);
    }

    async function disconnectWalletConnect() {
      await walletConnect.value.disconnectSessions();
      console.log('SESSION DELETED');
    }

    async function connectWalletConnect() {
      await disconnectWalletConnect();
      walletConnect.value.createSession();
    }

    // redirect when issuer changes
    watch(currentIssuerDid, () => {
      if (!currentIssuerDid.value) router.push('/');
    });

    return {
      currentIssuerDid,
      currentAddress,
      isCurrentChaindIdValid,
      waitingSignature,
      cancelSignature,
      connectWalletConnect,
      setIssuerConnectionDisplay,
      displayIssuerConnection,
      areVerticalBarsDisplayed,
      route,
      walletConnect,
    };
  },
};
</script>
