<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Manage verifiable credential
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        verifiable credentials
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-10">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Create and sign
      verifiable credential. Add public hash and public reference.
    </p>
    <p class="text-gray-600 mr-3 mb-3">User DID :</p>
    <textarea
      type="text"
      placeholder="DID value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
      v-model="userDid"
    />
    <p class="text-gray-600 mr-3 mb-3">Credential Key :</p>
    <select
      required
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
      v-model="credentialKey"
    >
      <option disabled selected value="">Please select an option</option>
      <option value="email">Email</option>
      <option value="givenName">Given Name</option>
      <option value="familyName">Family Name</option>
      <option value="birthDate">Birth Date</option>
      <option value="nationality">Nationality</option>
      <option value="gender">Gender</option>
      <option value="award_+18">+18</option>
      <option value="award_documentNumber">Document Number</option>
    </select>
    <p class="text-gray-600 mr-3 mb-3">Credential Value :</p>
    <textarea
      type="text"
      placeholder="Value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
      v-model="credentialValue"
    />
    <p class="text-gray-600 mr-3 mb-3">Credential image :</p>
    <div class="flex items-center">
      <button
        class="bg-gray-200 text-gray-700 text-xs border border-gray-300 inline-block rounded-lg cursor-pointer mr-5 py-2 px-3"
        @click="$refs.fileInput.click()"
      >
        <i class="fas fa-image mr-2"></i>Add image
      </button>
      <input type="file" @change="onFileInput" ref="fileInput" class="hidden" />
      <p class="text-xs italic text-gray-500 break-words line-clamp-1 w-32">
        {{ fileMessage ? fileMessage : 'Please select a file' }}
      </p>
    </div>
    <div v-if="previewImage" class="flex items-center w-full mt-2">
      <img class="w-32 border border-gray-400" :src="previewImage" />
    </div>

    <button
      class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mt-7 mb-10"
      @click="createVerifiableCredential"
    >
      Create and sign verifiable credential
    </button>
    <div
      v-if="waitingSignature"
      class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5 mb-10"
    >
      <div class="flex items-center">
        <custom-loader color="#111827" size="30px" class="mr-3">
        </custom-loader>
        <p class="text-gray-600 text-md font-bold">
          Waiting for WalletConnect signature
        </p>
      </div>
    </div>
    <div v-if="gifSrc" class="mb-10">
      <!-- <qrcode-vue
        :value="JSON.stringify(newCredential)"
        :size="300"
        level="L"
        :margin="5"
        class="mr-5 mb-5"
      /> -->
      <img :src="gifSrc" />
    </div>
    <div class="mb-10">
      <p class="text-lg font-bold text-gray-600 mb-3">Add public hash</p>
      <p class="text-gray-600 mr-3 mb-3">Verifiable Credential Hash :</p>
      <textarea
        type="text"
        placeholder="Value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-7"
        v-model="vcHash"
      />
      <method-price-handler
        method="addVCPrice"
        class="mb-2"
      ></method-price-handler>
      <button
        class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        :class="{
          'pointer-events-none opacity-50': buttonDisable,
        }"
        @click="
          () => {
            buttonDisable = true;
            transactionHandlerRef.createTransaction('addPublicHash', [vcHash]);
          }
        "
      >
        Add public hash
      </button>
      <transaction-handler
        ref="transactionHandlerRef"
        @transactionCompleted="buttonDisable = false"
        @transactionFailed="buttonDisable = false"
      ></transaction-handler>
    </div>
    <div>
      <p class="text-lg font-bold text-gray-600 mb-3">Add public reference</p>
      <p class="text-gray-600 mr-3 mb-3">Verifiable Credential Hash :</p>
      <textarea
        type="text"
        placeholder="Value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
        v-model="vcHash"
      />
      <p class="text-gray-600 mr-3 mb-3">Reference type :</p>
      <textarea
        type="text"
        placeholder="Value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
        v-model="refType"
      />
      <p class="text-gray-600 mr-3 mb-3">User DID :</p>
      <textarea
        type="text"
        placeholder="DID value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-7"
        v-model="userDid"
      />
      <method-price-handler
        method="addPublicRefPrice"
        class="mb-2"
      ></method-price-handler>
      <button
        class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        :class="{
          'pointer-events-none opacity-50': buttonDisable,
        }"
        @click="addPublicRef"
      >
        Add VC as public reference
      </button>
      <div
        v-if="ipfsLoading"
        class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
      >
        <div class="flex items-center">
          <custom-loader color="#111827" size="30px" class="mr-3">
          </custom-loader>
          <p class="text-gray-600 text-md font-bold">Pushing on IPFS</p>
        </div>
      </div>
      <transaction-handler
        ref="transactionHandlerRef2"
        @transactionCompleted="buttonDisable = false"
        @transactionFailed="buttonDisable = false"
      ></transaction-handler>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
import { createGif } from '../../plugins/qrGif';
export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const walletConnect = inject('walletConnect');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    const userDid = ref('');
    const credentialKey = ref('email');
    const credentialValue = ref('');

    const selectedFile = ref(null);
    const fileMessage = ref(null);
    const previewImage = ref(null);

    const newCredential = ref('');
    const gifSrc = ref(null);
    const vcHash = ref('');
    const waitingSignature = ref(false);
    const ipfsLoading = ref(false);
    const refType = ref(1);

    const errorMessage = ref(null);
    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();
    const transactionHandlerRef2 = ref();

    async function onFileInput(event) {
      if (event.target.files[0]['type'].split('/')[0] != 'image') {
        fileMessage.value = null;
        return;
      }
      selectedFile.value = event.target.files[0];
      fileMessage.value = selectedFile.value.name;
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile.value);
      reader.onload = async (e) => {
        previewImage.value = e.target.result;
      };
    }

    async function createVerifiableCredential() {
      if (!selectedFile.value) {
        errorMessage.value = 'Missing template image';
        return;
      }

      newCredential.value = null;
      vcHash.value = '';

      buttonDisable.value = true;
      ipfsLoading.value = true;

      const imageIPFSHash = await utils.ipfs.uploadIpfsFile(selectedFile.value);

      buttonDisable.value = false;
      ipfsLoading.value = false;

      const vcWithoutProof = utils.vc.createVerifiableCredential(
        currentIssuerDid.value,
        userDid.value,
        credentialKey.value,
        credentialValue.value,
        utils.ipfs.getUrlFromCID(utils.ipfs.hashToCID(imageIPFSHash))
      );

      console.log('vcWithoutProof', vcWithoutProof);

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(vcWithoutProof);

      var from = currentIssuerDid.value.replace('DID:SDI:', '');

      if (!walletConnect.value)
        throw new Error('No wallet connected in WalletConnect');

      waitingSignature.value = true;
      const session = walletConnect.value.getSession();
      const signedHash = await walletConnect.value.connector.request({
        topic: session.topic,
        chainId: `eip155:${Number(process.env.VUE_APP_CHAIN_ID)}`,
        request: {
          id: 1,
          jsonrpc: '2.0',
          method: 'eth_signTypedData',
          params: [from, JSON.stringify(typedData)],
        },
      });
      waitingSignature.value = false;

      const verifiableCredential =
        await utils.vc.addProofToVerifiableCredential(
          vcWithoutProof,
          currentIssuerDid.value,
          signedHash,
          credentialKey.value
        );

      // Verifying signature
      const address = await utils.sign.recoverVCTypedSignatureV4(
        verifiableCredential
      );

      if (address == currentIssuerDid.value.replace('DID:SDI:', '')) {
        newCredential.value = verifiableCredential;
        vcHash.value = utils.createSHA256Hash(
          JSON.stringify(verifiableCredential)
        );
        createGif(JSON.stringify(verifiableCredential)).then((res) => {
          gifSrc.value = res;
        });
      } else {
        throw new Error('Signature invalid for verifiable credential');
      }
    }

    async function addPublicRef() {
      buttonDisable.value = true;
      ipfsLoading.value = true;
      const vcIPFSHash = await utils.ipfs.uploadIpfsJsonData(
        JSON.stringify(newCredential.value)
      );

      ipfsLoading.value = false;

      api.addIpfsData({
        context: 'public-ref-add',
        hash: vcIPFSHash,
        data: newCredential.value,
      });

      transactionHandlerRef2.value.createTransaction(
        'addPublicRefs',
        [userDid.value.replace('DID:SDI:', '')],
        ['0x' + vcIPFSHash],
        [refType.value]
      );
    }

    return {
      userDid,
      credentialKey,
      credentialValue,
      vcHash,
      newCredential,
      refType,
      ipfsLoading,
      createVerifiableCredential,
      addPublicRef,
      waitingSignature,
      buttonDisable,
      transactionHandlerRef,
      transactionHandlerRef2,
      gifSrc,
      selectedFile,
      fileMessage,
      previewImage,
      errorMessage,
      onFileInput,
    };
  },
};
</script>
