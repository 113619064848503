<template>
  <div class="my-10 mx-20">
    <p class="text-xl font-bold text-gray-600 mb-5">Transactions</p>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-10" />
    <p class="font-bold text-gray-600 mb-5">
      Transaction list<span @click="refreshTransactions"
        ><i class="fas fa-sync-alt cursor-pointer ml-3"></i
      ></span>
    </p>
    <div class="flex flex-col mb-5">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="w-full table-auto divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Hash
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    DID issuer
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    DID user
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="transaction in transactions"
                :key="transaction._id"
                class="bg-white divide-y divide-gray-200"
              >
                <tr>
                  <td class="px-6 py-4">
                    <div class="text-sm font-medium text-gray-900">
                      {{ transaction.action }}
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <div class="text-xs text-gray-900 break-all">
                      {{ transaction.hash }}
                      <a
                        :href="scanUrlPrefix + transaction.hash"
                        target="_blank"
                        ><i
                          class="fas fa-external-link-alt text-gray-700 text-xxs ml-1"
                        ></i
                      ></a>
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <div class="text-xs text-gray-900 break-all">
                      {{ transaction.issuerDid }}
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <div class="text-xs text-gray-900 break-all">
                      {{ transaction.didController }}
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                      :class="{
                        'bg-green-100 text-green-800':
                          transaction.state == 'success',
                        'bg-blue-100 text-blue-800':
                          transaction.state == 'pending',
                        'bg-red-100 text-red-800': transaction.state == 'error',
                      }"
                    >
                      {{
                        transaction.state.charAt(0).toUpperCase() +
                        transaction.state.slice(1)
                      }}
                    </span>
                  </td>
                  <td class="px-6 py-4 text-xs text-gray-500">
                    {{
                      transaction.created.split('T')[0] +
                      ' ' +
                      transaction.created.split('T')[1].split('.')[0]
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentIssuerDid && !endResult" class="flex justify-center mb-3">
      <button
        class="bg-transparent text-gray-900 inline-block text-xs rounded-lg cursor-pointer border py-2 px-3 mb-3"
        @click="getTransactions"
      >
        Afficher plus
      </button>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const api = inject('api');
    const store = useStore();

    const currentUserDid = computed(() => store.getters.getCurrentUserDid);
    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    const transactions = ref([]);
    const currentTransactionHash = ref(null);
    const endResult = ref(false);
    const scanUrlPrefix = ref(process.env.VUE_APP_SCAN_URL_PREFIX);

    if (currentUserDid.value) {
      getTransactions();
    }

    async function getTransactions() {
      if (!currentIssuerDid.value) return;

      api
        .getIssuerTransactions(
          currentIssuerDid.value,
          currentTransactionHash.value,
          10
        )
        .then((result) => {
          const issuerTransactions = result.data;
          if (issuerTransactions.length < 10) endResult.value = true;
          if (issuerTransactions.length == 0) return;
          transactions.value = [...transactions.value, ...issuerTransactions];
          currentTransactionHash.value =
            transactions.value[transactions.value.length - 1].hash;
        });
    }

    async function refreshTransactions() {
      transactions.value = [];
      currentTransactionHash.value = null;
      getTransactions();
    }

    return {
      currentUserDid,
      currentIssuerDid,
      transactions,
      endResult,
      getTransactions,
      refreshTransactions,
      scanUrlPrefix,
    };
  },
};
</script>
