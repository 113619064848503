<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Update verifiable credential status
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        verifiable credentials
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-10">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Update verifiable
      credential status (and get info).
    </p>
    <p class="text-gray-600 mr-3 mb-3">Verifiable Credential Hash :</p>
    <textarea
      type="text"
      placeholder="Hash value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
      v-model="credentialHash"
    />
    <p class="text-gray-600 mr-3 mb-3">Verifiable Credential Status :</p>
    <textarea
      type="text"
      placeholder="Status number"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="credentialStatus"
    />
    <div class="flex items-center mb-5">
      <button
        class="text-sm bg-transparent text-primary border-primary border-2 font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        @click="getVCInfo"
      >
        Get vc info
      </button>
      <button
        class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        :class="{
          'pointer-events-none opacity-50': buttonDisable,
        }"
        @click="
          () => {
            buttonDisable = true;
            transactionHandlerRef.createTransaction(
              'updateVCStatus',
              credentialHash,
              credentialStatus
            );
          }
        "
      >
        Update VC status
      </button>
    </div>
    <div v-if="vcInfo" class="bg-white rounded-lg shadow-md p-5 mb-3">
      <p class="text-gray-600 text-sm mr-3 mb-3">
        <i class="fas fa-info-circle mr-2"></i>Current verifiable credential
        info :
      </p>
      <p class="bg-green-200 h-32 overflow-y-scroll px-2 py-1">
        <span class="text-xxs whitespace-pre leading-none my-4">{{
          JSON.stringify(vcInfo, null, 4)
        }}</span>
      </p>
    </div>
    <transaction-handler
      ref="transactionHandlerRef"
      @transactionCompleted="buttonDisable = false"
      @transactionFailed="buttonDisable = false"
    ></transaction-handler>

    <div v-if="errorMessage" class="bg-white rounded-lg shadow-md p-5">
      <p class="text-gray-600 text-md font-bold mb-1">
        <i class="fas fa-exclamation-circle mr-2"></i>An error occured :
      </p>
      <p class="bg-red-200 h-16 overflow-y-scroll px-2 py-1">
        <span class="text-xxs leading-none my-4">{{ errorMessage }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const utils = inject('utils');

    const credentialHash = ref('');
    const credentialStatus = ref(1);
    const vcInfo = ref(null);
    const transactionHash = ref('');
    const errorMessage = ref(null);

    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();

    async function getVCInfo() {
      errorMessage.value = null;
      vcInfo.value = null;

      try {
        vcInfo.value = await utils.mydid.getVCStatus(credentialHash.value);
      } catch (e) {
        console.log(e);
        errorMessage.value = e;
        return;
      }
    }

    return {
      credentialHash,
      credentialStatus,
      vcInfo,
      transactionHash,
      errorMessage,
      getVCInfo,
      buttonDisable,
      transactionHandlerRef,
    };
  },
};
</script>
