<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Manage issuer badge templates
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        open badge
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-10">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Get information about
      issuer templates.
    </p>
    <div class="flex gap-5 mb-5">
      <p
        class="font-bold text-gray-800 border-gray-200 border bg-white p-3 rounded-lg cursor-pointer"
        :class="{ 'bg-gray-100': !issuerSelected }"
        @click="issuerSelected = true"
      >
        Issuer badges
      </p>
      <p
        class="font-bold text-gray-800 border-gray-200 border bg-white p-3 rounded-lg cursor-pointer"
        :class="{ 'bg-gray-100': issuerSelected }"
        @click="issuerSelected = false"
      >
        P2P badges
      </p>
    </div>
    <div class="flex flex-col mb-10">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 max-h-128 overflow-y-scroll sm:rounded-lg"
          >
            <table class="w-full table-auto divide-y divide-gray-200">
              <thead class="bg-gray-50 sticky top-0">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    Hash
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    IPFS link
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    Edit
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                  >
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="template in issuerBadgeTemplates"
                :key="template.index"
                class="bg-white divide-y divide-gray-200"
              >
                <tr>
                  <td class="px-6 py-4">
                    <p class="text-sm font-medium text-gray-700">
                      {{ utils.hexToAscii(template.name) }}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p class="text-xs text-gray-900 break-all">
                      {{ template.badgeTemplateHash }}
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <a
                      :href="
                        utils.ipfs.getUrlFromCID(
                          utils.ipfs.hashToCID(
                            template.badgeTemplateHash.replace('0x', '')
                          )
                        )
                      "
                      target="_blank"
                    >
                      <p class="text-xs text-blue-600 underline break-all">
                        {{
                          utils.ipfs.getUrlFromCID(
                            utils.ipfs.hashToCID(
                              template.badgeTemplateHash.replace('0x', '')
                            )
                          )
                        }}
                      </p></a
                    >
                  </td>
                  <td class="px-6 py-4">
                    <p
                      @click="
                        () => {
                          issuerBadgeAddShow = false;
                          issuerBadgeUpdateShow = true;
                          badgeTemplateName = null;
                          badgeTemplateDescription = null;
                          badgeTemplateCriteriaId = null;
                          badgeTemplateCriteriaNarrative = null;
                          fileMessage = null;
                          previewImage = null;
                          selectedFile = null;
                          recoverBadgeTemplate(
                            template.badgeTemplateHash,
                            template.index
                          );
                        }
                      "
                      class="text-gray-700 cursor-pointer"
                    >
                      <i class="fas fa-edit"></i>
                    </p>
                  </td>
                  <td class="px-6 py-4">
                    <p
                      @click="
                        () => {
                          issuerBadgeAddShow = false;
                          issuerBadgeUpdateShow = false;
                          issuerBadgeRemoveShow = true;
                          badgeTemplateName = null;
                          badgeTemplateDescription = null;
                          badgeTemplateCriteriaId = null;
                          badgeTemplateCriteriaNarrative = null;
                          fileMessage = null;
                          previewImage = null;
                          selectedFile = null;
                          recoverBadgeTemplate(
                            template.badgeTemplateHash,
                            template.index
                          );
                        }
                      "
                      class="text-gray-700 cursor-pointer"
                    >
                      <i class="fas fa-trash"></i>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              @click="
                () => {
                  issuerBadgeUpdateShow = false;
                  issuerBadgeAddShow = true;
                  badgeTemplateName = null;
                  badgeTemplateDescription = null;
                  badgeTemplateCriteriaId = null;
                  badgeTemplateCriteriaNarrative = null;
                  badgeTemplateIndex = null;
                  fileMessage = null;
                  previewImage = null;
                  selectedFile = null;
                }
              "
              class="bg-gray-50 text-center border-t cursor-pointer sticky bottom-0 py-3"
            >
              <p class="text-gray-700 font-bold">ADD TEMPLATE</p>
            </div>
          </div>
          <div v-if="issuerTemplatesLoading" class="flex justify-center mt-2">
            <custom-loader class="pb-3" color="#111827" size="30px">
            </custom-loader>
          </div>
        </div>
      </div>
      <p
        v-if="issuerBadgeTemplates && issuerBadgeTemplates.length == 0"
        class="text-sm text-gray-700 p-2"
      >
        No badge templates for this issuer
      </p>
    </div>
    <div v-if="issuerBadgeAddShow || issuerBadgeUpdateShow">
      <div
        @click="
          () => {
            issuerBadgeAddShow = false;
            issuerBadgeUpdateShow = false;
          }
        "
        class="w-full h-full fixed top-0 left-0 z-40 bg-black bg-opacity-50"
      ></div>
      <div
        class="w-2/4 overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 rounded-lg shadow-lg"
      >
        <div class="relative w-full h-full">
          <div class="relative bg-white p-5">
            <multi-step-form-old
              ref="multiStepForm"
              class=""
              :step-names="[
                'Name',
                'Image',
                'Description',
                'Narrative',
                'Language',
                issuerBadgeAddShow ? 'Create' : 'Update',
              ]"
            >
              <div class="text-center">
                <p class="text-lg mb-5">What is the name of your badge ?</p>
                <div class="mb-5">
                  <div class="flex justify-center items-center mb-2">
                    <textarea
                      type="text"
                      placeholder="Template name"
                      rows="1"
                      class="w-96 outline-none bg-gray-200 rounded-r-lg border-none p-3"
                      v-model="badgeTemplateName"
                    />
                  </div>
                </div>

                <div>
                  <button
                    @click="multiStepForm.nextStep()"
                    class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer w-32 py-2 px-3 mx-auto mb-10"
                  >
                    Next
                  </button>
                </div>
                <div
                  class="text-left bg-yellow-50 border border-gray-200 rounded-lg p-3"
                >
                  <p class="text-xxs text-gray-500">
                    <i class="fas fa-info-circle text-gray-700 mr-2"></i>Some
                    help and tips for this step.
                  </p>
                </div>
              </div>
              <div class="text-center">
                <p class="text-lg mb-5">Select template image :</p>
                <div class="mb-5">
                  <div class="flex justify-center items-center mb-2">
                    <button
                      class="bg-gray-200 text-gray-700 text-xs border border-gray-300 inline-block rounded-lg cursor-pointer mr-5 py-2 px-3"
                      @click="document.getElementById('fileInput').click()"
                    >
                      <i class="fas fa-image mr-2"></i>Badge template image
                    </button>
                    <p>{{ $refs['fileInput'] }}</p>
                    <input
                      type="file"
                      @change="(event) => onFileInput(event)"
                      :id="'fileInput'"
                      class="hidden"
                    />
                    <p
                      class="text-xs italic text-gray-500 break-words line-clamp-1 w-32"
                    >
                      {{ fileMessage ? fileMessage : 'Please select a file' }}
                    </p>
                  </div>
                </div>
                <div class="flex justify-center items-center">
                  <img
                    v-if="previewImage"
                    class="w-32 mx-auto border border-gray-400 mb-5"
                    :src="previewImage"
                  />
                </div>
                <div>
                  <button
                    @click="multiStepForm.nextStep()"
                    class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer w-32 py-2 px-3 mx-auto mb-10"
                  >
                    Next
                  </button>
                </div>
                <div
                  class="text-left bg-yellow-50 border border-gray-200 rounded-lg p-3"
                >
                  <p class="text-xxs text-gray-500">
                    <i class="fas fa-info-circle text-gray-700 mr-2"></i>Some
                    help and tips for this step.
                  </p>
                </div>
              </div>
              <div class="text-center">
                <div class="mb-5">
                  <p class="text-xs text-gray-600 mr-3 mb-1">
                    Badge template description :
                  </p>
                  <textarea
                    type="text"
                    placeholder="Description"
                    rows="1"
                    class="w-96 outline-none bg-gray-200 rounded-r-lg border-none p-3"
                    v-model="badgeTemplateDescription"
                  />
                </div>
                <div>
                  <button
                    @click="multiStepForm.nextStep()"
                    class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer w-32 py-2 px-3 mx-auto mb-10"
                  >
                    Next
                  </button>
                </div>
                <div
                  class="text-left bg-yellow-50 border border-gray-200 rounded-lg p-3"
                >
                  <p class="text-xxs text-gray-500">
                    <i class="fas fa-info-circle text-gray-700 mr-2"></i>Some
                    help and tips for this step.
                  </p>
                </div>
              </div>
              <div class="text-center">
                <div class="mb-5">
                  <p class="text-xs text-gray-600 mr-3 mb-1">
                    Badge template criteria id (URL) :
                  </p>
                  <textarea
                    type="text"
                    placeholder="Criteria"
                    rows="1"
                    class="w-96 outline-none bg-gray-200 rounded-r-lg border-none p-3"
                    v-model="badgeTemplateCriteriaId"
                  />
                </div>
                <div class="mb-5">
                  <p class="text-xs text-gray-600 mr-3 mb-1">
                    Badge template criteria narrative :
                  </p>
                  <textarea
                    type="text"
                    placeholder="Criteria"
                    rows="1"
                    class="w-96 outline-none bg-gray-200 rounded-r-lg border-none p-3"
                    v-model="badgeTemplateCriteriaNarrative"
                  />
                </div>
                <div>
                  <button
                    @click="multiStepForm.nextStep()"
                    class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer w-32 py-2 px-3 mx-auto mb-10"
                  >
                    Next
                  </button>
                </div>
                <div
                  class="text-left bg-yellow-50 border border-gray-200 rounded-lg p-3"
                >
                  <p class="text-xxs text-gray-500">
                    <i class="fas fa-info-circle text-gray-700 mr-2"></i>Some
                    help and tips for this step.
                  </p>
                </div>
              </div>
              <div class="text-center">
                <div class="mb-5">
                  <p class="text-xs text-gray-600 mr-3 mb-1">
                    Badge language :
                  </p>
                  <select
                    required
                    class="w-96 outline-none bg-gray-200 rounded-lg border-none p-3 mb-3"
                    v-model="badgeTemplateLang"
                  >
                    <option disabled selected value="">
                      Please select an option
                    </option>
                    <option value="en">English</option>
                    <option value="fr">French</option>
                  </select>
                </div>
                <div>
                  <button
                    @click="multiStepForm.nextStep()"
                    class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer w-32 py-2 px-3 mx-auto mb-10"
                  >
                    Next
                  </button>
                </div>
                <div
                  class="text-left bg-yellow-50 border border-gray-200 rounded-lg p-3"
                >
                  <p class="text-xxs text-gray-500">
                    <i class="fas fa-info-circle text-gray-700 mr-2"></i>Some
                    help and tips for this step.
                  </p>
                </div>
              </div>
              <div class="text-center">
                <img
                  v-if="previewImage"
                  class="w-32 mx-auto mb-1"
                  :src="previewImage"
                />
                <p v-if="badgeTemplateName" class="text-lg mb-4">
                  {{ badgeTemplateName }}
                </p>
                <method-price-handler
                  v-if="issuerBadgeAddShow"
                  method="addIssuerBTPrice"
                  class="mb-2"
                ></method-price-handler>
                <div class="flex items-center justify-center mb-5">
                  <button
                    class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5"
                    :class="{
                      'pointer-events-none opacity-50': buttonDisable,
                    }"
                    @click="
                      () => {
                        return issuerBadgeAddShow
                          ? addIssuerBadgeTemplate()
                          : updateIssuerBadgeTemplate();
                      }
                    "
                  >
                    {{
                      issuerBadgeAddShow
                        ? 'Add badge template'
                        : 'Update badge template'
                    }}
                  </button>
                </div>
                <div
                  v-if="errorMessage"
                  class="bg-white rounded-lg text-left shadow-md flex-col space-y-4 p-5 mb-5"
                >
                  <p class="text-gray-600 text-md font-bold mb-1">
                    <i class="fas fa-exclamation-circle mr-2"></i>An error
                    occured :
                  </p>
                  <p class="bg-red-200 h-16 overflow-y-scroll px-2 py-1">
                    <span class="text-xxs leading-none my-4">{{
                      errorMessage
                    }}</span>
                  </p>
                </div>
                <div
                  v-if="ipfsLoading"
                  class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
                >
                  <div class="flex items-center">
                    <custom-loader color="#111827" size="30px" class="mr-3">
                    </custom-loader>
                    <p class="text-gray-600 text-md font-bold">
                      Pushing on IPFS
                    </p>
                  </div>
                </div>
                <transaction-handler
                  class="text-left"
                  @transactionCompleted="onTransactionCompleted"
                  @transactionFailed="buttonDisable = false"
                  ref="issuerListTransactionHandlerRef"
                ></transaction-handler></div
            ></multi-step-form-old>
          </div>
        </div>
      </div>
    </div>
    <div v-if="issuerBadgeRemoveShow">
      <div
        @click="
          () => {
            issuerBadgeRemoveShow = false;
          }
        "
        class="w-full h-full fixed top-0 left-0 z-40 bg-black bg-opacity-50"
      ></div>
      <div
        class="w-2/4 overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 rounded-lg shadow-lg"
      >
        <div class="relative w-full h-full">
          <div class="relative bg-white p-5">
            <div class="text-center">
              <img
                v-if="previewImage"
                class="w-32 mx-auto mb-1"
                :src="previewImage"
              />
              <p v-if="badgeTemplateName" class="text-lg mb-4">
                {{ badgeTemplateName }}
              </p>
              <div class="flex items-center justify-center mb-5">
                <button
                  class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5"
                  :class="{
                    'pointer-events-none opacity-50': buttonDisable,
                  }"
                  @click="
                    () => {
                      return removeIssuerBadgeTemplate();
                    }
                  "
                >
                  Remove issuer badge template
                </button>
              </div>
              <div
                v-if="errorMessage"
                class="bg-white rounded-lg text-left shadow-md flex-col space-y-4 p-5 mb-5"
              >
                <p class="text-gray-600 text-md font-bold mb-1">
                  <i class="fas fa-exclamation-circle mr-2"></i>An error occured
                  :
                </p>
                <p class="bg-red-200 h-16 overflow-y-scroll px-2 py-1">
                  <span class="text-xxs leading-none my-4">{{
                    errorMessage
                  }}</span>
                </p>
              </div>
              <transaction-handler
                class="text-left"
                @transactionCompleted="onTransactionCompleted"
                @transactionFailed="buttonDisable = false"
                ref="issuerListTransactionHandlerRef"
              ></transaction-handler>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    const issuerBadgeTemplates = ref(null);
    const issuerTemplatesLoading = ref(false);
    const ipfsLoading = ref(false);

    const issuerBadgeUpdateShow = ref(false);
    const issuerBadgeAddShow = ref(false);
    const issuerBadgeRemoveShow = ref(false);

    const selectedFile = ref(null);
    const fileMessage = ref(null);
    const previewImage = ref(null);

    // const badgeTemplateName = ref('');
    const badgeTemplateName = ref(null);
    const badgeTemplateDescription = ref(null);
    const badgeTemplateCriteriaId = ref(null);
    const badgeTemplateCriteriaNarrative = ref(null);
    const badgeTemplateIPFS = ref('');
    const badgeTemplateIndex = ref(null);
    const badgeTemplateLang = ref('en');

    const issuerListTransactionHandlerRef = ref();
    const buttonDisable = ref(false);

    const multiStepForm = ref();
    const errorMessage = ref(null);

    const issuerSelected = ref(true);

    async function onFileInput(event) {
      if (event.target.files[0]['type'].split('/')[0] != 'image') {
        fileMessage.value = null;
        return;
      }
      selectedFile.value = event.target.files[0];
      fileMessage.value = selectedFile.value.name;
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile.value);
      reader.onload = async (e) => {
        previewImage.value = e.target.result;
      };
    }

    async function imageUrlToFileInput(imageUrl) {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          selectedFile.value = blob;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = async (e) => {
            previewImage.value = e.target.result;
          };
        });
    }

    async function addIssuerBadgeTemplate() {
      // check fields
      errorMessage.value = null;

      if (!badgeTemplateName.value || badgeTemplateName.value.length < 3) {
        errorMessage.value = 'Template name not valid';
        return;
      }
      if (!selectedFile.value) {
        errorMessage.value = 'Missing template image';
        return;
      }
      if (
        !badgeTemplateDescription.value ||
        !badgeTemplateCriteriaId.value ||
        !badgeTemplateCriteriaNarrative.value
      ) {
        errorMessage.value = 'Missing template information';
        return;
      }

      buttonDisable.value = true;
      ipfsLoading.value = true;
      badgeTemplateIPFS.value = '';

      const imageIPFSHash = await utils.ipfs.uploadIpfsFile(selectedFile.value);

      const badgeTemplate = utils.badge.computeBadgeTemplate(
        badgeTemplateLang.value,
        badgeTemplateName.value,
        uuidv4(),
        utils.ipfs.getUrlFromCID(utils.ipfs.hashToCID(imageIPFSHash)),
        badgeTemplateDescription.value,
        badgeTemplateCriteriaId.value,
        badgeTemplateCriteriaNarrative.value,
        currentIssuerDid.value.replace('DID:SDI:', '')
      );

      const badgeTemplateHash = await utils.ipfs.uploadIpfsJsonData(
        JSON.stringify(badgeTemplate)
      );

      ipfsLoading.value = false;

      api.addIpfsData({
        context: issuerSelected.value
          ? 'issuer-template-add'
          : 'p2p-template-add',
        hash: badgeTemplateHash,
        data: badgeTemplate,
      });

      issuerListTransactionHandlerRef.value.createTransaction(
        issuerSelected.value ? 'addIssuerBadgeTemplate' : 'addP2PBadgeTemplate',
        ['0x' + badgeTemplateHash],
        [
          '0x' +
            (
              '0000000000000000000000000000000000000000000000000000000000000000' +
              utils.asciiToHex(badgeTemplateName.value).replace('0x', '')
            ).substr(-64),
        ]
      );
    }

    async function recoverBadgeTemplate(hash, index) {
      utils.ipfs.getJsonDataFromCID(utils.ipfs.hashToCID(hash)).then((res) => {
        badgeTemplateIndex.value = index;
        try {
          fileMessage.value = null;
          if (
            res.mVersion &&
            (res.mVersion == '2.0' || res.mVersion == '3.0')
          ) {
            badgeTemplateLang.value = res['@language'];
            badgeTemplateName.value = res.name;
            badgeTemplateDescription.value = res.description;
            badgeTemplateCriteriaId.value = res.criteria.id;
            badgeTemplateCriteriaNarrative.value = res.criteria.narrative;
            imageUrlToFileInput(res.image, 'en');
          } else {
            badgeTemplateLang.value = 'en';
            badgeTemplateName.value = res.name;
            badgeTemplateDescription.value = res.description;
            badgeTemplateCriteriaNarrative.value = res.criteria;
            imageUrlToFileInput(
              utils.ipfs.getUrlFromCID(utils.ipfs.hashToCID(res.image.id)),
              'en'
            );
          }
        } catch (e) {
          console.log(`Can't recover former template properly`, e);
        }
      });
    }

    async function updateIssuerBadgeTemplate() {
      // check fields
      errorMessage.value = null;

      if (!badgeTemplateName.value || badgeTemplateName.value.length < 3) {
        errorMessage.value = 'Template name not valid';
        return;
      }
      if (!selectedFile.value) {
        errorMessage.value = 'Missing template image';
        return;
      }
      if (
        !badgeTemplateDescription.value ||
        !badgeTemplateCriteriaId.value ||
        !badgeTemplateCriteriaNarrative.value
      ) {
        errorMessage.value = 'Missing template information';
        return;
      }

      buttonDisable.value = true;
      ipfsLoading.value = true;
      badgeTemplateIPFS.value = '';

      const imageIPFSHash = await utils.ipfs.uploadIpfsFile(selectedFile.value);

      const badgeTemplate = utils.badge.computeBadgeTemplate(
        badgeTemplateLang.value,
        badgeTemplateName.value,
        uuidv4(),
        utils.ipfs.getUrlFromCID(utils.ipfs.hashToCID(imageIPFSHash)),
        badgeTemplateDescription.value,
        badgeTemplateCriteriaId.value,
        badgeTemplateCriteriaNarrative.value,
        currentIssuerDid.value.replace('DID:SDI:', '')
      );

      const badgeTemplateHash = await utils.ipfs.uploadIpfsJsonData(
        JSON.stringify(badgeTemplate)
      );

      ipfsLoading.value = false;

      api.addIpfsData({
        context: issuerSelected.value
          ? 'issuer-template-update'
          : 'p2p-template-update',
        hash: badgeTemplateHash,
        data: badgeTemplate,
      });

      issuerListTransactionHandlerRef.value.createTransaction(
        issuerSelected.value
          ? 'updateIssuerBadgeTemplate'
          : 'updateP2PBadgeTemplate',
        '0x' + badgeTemplateHash,
        '0x' +
          (
            '0000000000000000000000000000000000000000000000000000000000000000' +
            utils.asciiToHex(badgeTemplateName.value).replace('0x', '')
          ).substr(-64),
        badgeTemplateIndex.value
      );
    }

    async function removeIssuerBadgeTemplate() {
      // check fields
      errorMessage.value = null;

      buttonDisable.value = true;
      badgeTemplateIPFS.value = '';

      const badgeTemplate = {};
      const badgeTemplateHash =
        'FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';

      api.addIpfsData({
        context: issuerSelected.value
          ? 'issuer-template-remove'
          : 'p2p-template-remove',
        hash: badgeTemplateHash,
        data: badgeTemplate,
      });

      issuerListTransactionHandlerRef.value.createTransaction(
        issuerSelected.value
          ? 'updateIssuerBadgeTemplate'
          : 'updateP2PBadgeTemplate',
        '0x' + badgeTemplateHash,
        '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
        badgeTemplateIndex.value
      );
    }

    async function getBadgeTemplates() {
      if (!currentIssuerDid.value) return;
      issuerTemplatesLoading.value = true;
      issuerBadgeTemplates.value = null;

      if (issuerSelected.value) {
        utils.mydid
          .getIssuerBadgeTemplateList(
            currentIssuerDid.value.replace('DID:SDI:', '')
          )
          .then((result) => {
            issuerBadgeTemplates.value = result;
            issuerTemplatesLoading.value = false;
          });
      } else {
        utils.mydid
          .getP2PBadgeTemplateList(
            currentIssuerDid.value.replace('DID:SDI:', '')
          )
          .then((result) => {
            issuerBadgeTemplates.value = result.filter(
              (el) =>
                el.issuer.toLowerCase() ==
                currentIssuerDid.value.replace('DID:SDI:', '').toLowerCase()
            );
            issuerTemplatesLoading.value = false;
          });
      }
    }

    function onTransactionCompleted() {
      buttonDisable.value = false;
      getBadgeTemplates();
    }

    async function getIssuerProfileUrl(address) {
      const didDocument = await utils.mydid.getDID(address);
      return utils.ipfs.getUrlFromCID(utils.ipfs.hashToCID(didDocument[1]));
    }

    getBadgeTemplates();

    watch(issuerSelected, () => {
      getBadgeTemplates();
    });

    return {
      issuerBadgeTemplates,
      issuerTemplatesLoading,
      utils,
      issuerBadgeUpdateShow,
      issuerBadgeAddShow,
      issuerBadgeRemoveShow,
      recoverBadgeTemplate,
      onFileInput,
      selectedFile,
      fileMessage,
      previewImage,
      badgeTemplateName,
      badgeTemplateDescription,
      badgeTemplateCriteriaId,
      badgeTemplateCriteriaNarrative,
      badgeTemplateIPFS,
      badgeTemplateIndex,
      badgeTemplateLang,
      issuerListTransactionHandlerRef,
      buttonDisable,
      onTransactionCompleted,
      addIssuerBadgeTemplate,
      updateIssuerBadgeTemplate,
      removeIssuerBadgeTemplate,
      multiStepForm,
      errorMessage,
      document,
      ipfsLoading,
      issuerSelected,
    };
  },
};
</script>
