import { createStore } from 'vuex';

const store = createStore({
  state: {
    currentIssuerDid: null,
    currentAddress: null,
    currentChainId: null,
    isCurrentChainIdValid: false,

    displayIssuerConnection: false,
    displayVerticalBars: true,
  },
  mutations: {
    setCurrentIssuerDid(state, payload) {
      state.currentIssuerDid = payload;
      state.currentAddress = payload.replace('DID:SDI:', '');
      state.displayIssuerConnection = false;
    },
    setCurrentAddress(state, payload) {
      state.currentAddress = payload;
    },
    setCurrentChainId(state, payload) {
      state.currentChainId = payload;
      state.isCurrentChainIdValid =
        payload == Number(process.env.VUE_APP_CHAIN_ID);
    },
    resetIssuerState(state) {
      state.currentIssuerDid = null;
      state.currentChainId = null;
      state.isCurrentChainIdValid = false;
    },
    setIssuerConnectionDisplay(state, payload) {
      state.displayIssuerConnection = payload;
    },
    setVerticalBarsDisplay(state, payload) {
      state.displayVerticalBars = payload;
    },
  },
  actions: {},
  modules: {},
  getters: {
    getCurrentIssuerDid(state) {
      return state.currentIssuerDid;
    },
    getCurrentAddress(state) {
      return state.currentAddress;
    },
    getCurrentChainId(state) {
      return state.currentChainId;
    },
    isCurrentChaindIdValid(state) {
      return state.isCurrentChainIdValid;
    },
    isIssuerConnectionDisplayed(state) {
      return state.displayIssuerConnection;
    },
    areVerticalBarsDisplayed(state) {
      return state.displayVerticalBars;
    },
  },
});

export default store;
