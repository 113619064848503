<template>
  <div>
    <div v-if="!currentUserDid" class="flex items-center" @click="connectUser">
      <p
        class="bg-white text-black text-xs font-bold rounded-md cursor-pointer p-2"
      >
        Connect user
      </p>
    </div>
    <div v-else>
      <p class="text-gray-300 text-xs mb-1 break-words">
        <i class="fas fa-user text-white mr-2"></i>User connected with :
      </p>
      <p class="text-white text-xs mb-1 break-all">
        {{ currentUserDid }}
      </p>
      <p class="text-gray-300 text-xs cursor-pointer" @click="logout">
        Log out user<i class="fas fa-sign-out-alt ml-2"></i>
      </p>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const cookies = inject('cookies');
    const store = useStore();

    const currentUserDid = computed(() => store.getters.getCurrentUserDid);

    function logout() {
      cookies.remove('token');
      store.commit('resetUserState');
    }

    function connectUser() {
      cookies.remove('token');
      store.commit('setUserConnectionDisplay', true);
    }

    return {
      currentUserDid,
      logout,
      connectUser,
    };
  },
};
</script>
