<template>
  <div>
    <p class="text-center font-bold mb-5">
      Scan QR code with the mobile application to sign in :
    </p>
    <div class="flex items-center justify-center mb-3">
      <custom-loader v-if="loading" class="mr-2" color="#151838" size="20px">
      </custom-loader>
      <p class="text-md">
        {{ status }}
      </p>
    </div>
    <div class="flex items-center justify-center mb-8">
      <qrcode-vue v-if="qrCode" :value="qrCode" :size="200" level="H" />
    </div>
    <p v-if="verifiableCredentials.length > 0" class="text-xs text-gray-600">
      <i class="fas fa-shield-alt mr-1"></i>Verifiable credentials needed :
    </p>
    <p v-else class="text-center text-xs text-gray-600">
      No verifiable credentials needed
    </p>
    <p
      class="text-center text-xs text-gray-600"
      v-for="vc in verifiableCredentials"
      :key="vc"
    >
      • {{ vc }}
    </p>
  </div>
</template>

<script>
import { ref, inject, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import jwtDecode from 'jwt-decode';
export default {
  setup() {
    const api = inject('api');
    const cookies = inject('cookies');
    const store = useStore();
    const qrCode = ref('');
    const status = ref('');
    const verifiableCredentials = ref({});
    const loading = ref(null);

    onBeforeUnmount(() => {
      api.closeChallengeValidation();
    });

    api.createChallengeSignup().then((res) => {
      const newChallenge = res.data;
      qrCode.value = JSON.stringify(newChallenge);
      verifiableCredentials.value = newChallenge.verifiableCredentials;

      // CREATE SSE CONNECTION
      api.waitChallengeValidation(newChallenge.challenge, (response) => {
        switch (response.status) {
          case 'waiting':
            status.value = 'Waiting scan from mobile application';
            loading.value = true;
            break;
          case 'treating':
            status.value = 'Checking sign in...';
            loading.value = true;
            break;
          case 'expired':
            status.value = 'Error while registering : ' + response.message;
            loading.value = false;
            break;
          case 'validated':
            status.value = 'Sign in accepted. Redirecting...';
            cookies.set('token', response.token);
            loading.value = false;
            store.commit('setCurrentUserDid', jwtDecode(response.token).did);
            api.getIssuersForUser().then((result) => {
              store.commit('setAuthorizedIssuers', result.data);
            });
            break;
        }
      });
    });

    return {
      qrCode,
      status,
      verifiableCredentials,
      loading,
    };
  },
};
</script>
