<template>
  <admin-checker>
    <div class="my-10 mx-20">
      <div class="flex items-center mb-5">
        <p class="text-xl font-bold text-gray-600">
          <router-link to="/"
            ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
          >Get P2P badge templates
        </p>
        <p
          class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
        >
          open badge
        </p>
      </div>
      <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
      <p class="text-sm text-gray-600 mb-10">
        <i class="fas fa-info-circle text-gray-700 mr-2"></i>Get information
        about P2P templates.
      </p>
      <p class="font-bold text-gray-600 mb-5">
        <i class="fas fa-certificate mr-2"></i>P2P template list
      </p>
      <div class="flex flex-col mb-10">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="w-full table-auto divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      Issuer
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      Hash
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      IPFS link
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="template in p2pBadgeTemplates"
                  :key="template.index"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr>
                    <td class="px-6 py-4">
                      <p class="text-sm font-medium text-gray-700">
                        {{ utils.hexToAscii(template.name) }}
                      </p>
                    </td>
                    <td class="px-6 py-4">
                      <p>{{ template.issuer }}</p>
                    </td>
                    <td class="px-6 py-4">
                      <p class="text-xs text-gray-900 break-all">
                        {{ template.badgeTemplateHash }}
                      </p>
                    </td>
                    <td class="px-6 py-4">
                      <a
                        :href="
                          utils.ipfs.getUrlFromCID(
                            utils.ipfs.hashToCID(
                              template.badgeTemplateHash.replace('0x', '')
                            )
                          )
                        "
                        target="_blank"
                      >
                        <p class="text-xs text-blue-600 underline break-all">
                          {{
                            utils.ipfs.getUrlFromCID(
                              utils.ipfs.hashToCID(
                                template.badgeTemplateHash.replace('0x', '')
                              )
                            )
                          }}
                        </p></a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="p2pTemplatesLoading" class="flex justify-center mt-2">
              <custom-loader class="pb-3" color="#111827" size="30px">
              </custom-loader>
            </div>
          </div>
        </div>
      </div></div
  ></admin-checker>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const utils = inject('utils');

    const p2pBadgeTemplates = ref(null);
    const p2pTemplatesLoading = ref(false);

    async function getBadgeTemplates() {
      p2pTemplatesLoading.value = true;

      utils.mydid
        .getP2PBadgeTemplateList()
        .then((result) => {
          p2pBadgeTemplates.value = result;
          p2pTemplatesLoading.value = false;
        });
    }

    getBadgeTemplates();

    return {
      p2pBadgeTemplates,
      p2pTemplatesLoading,
      utils,
    };
  },
};
</script>
