import SignClient from '@walletconnect/sign-client';
import { Web3Modal } from '@web3modal/standalone';
import EventsBus from './eventBus';

const { emit } = EventsBus();

export default async function () {
  const web3Modal = new Web3Modal({
    projectId: '5e457b02b76de9e8d6d950cb20740258',
    standaloneChains: [`eip155:${Number(process.env.VUE_APP_CHAIN_ID)}`],
  });

  const signClient = await SignClient.init({
    projectId: '5e457b02b76de9e8d6d950cb20740258',
    metadata: {
      description: 'myDid Panel Admin',
      url: process.env.VUE_APP_WALLETCONNECT_URL,
      icons: [process.env.VUE_APP_EXTERNAL_LOGO_URL],
      name: 'WalletConnect',
    },
  });

  const walletConnect = {
    connector: signClient,
    sessionTopic: null,
    createSession: async () => {
      console.log('CREATE SESSION');
      signClient
        .connect({
          requiredNamespaces: {
            eip155: {
              methods: [
                'eth_sendTransaction',
                'eth_signTransaction',
                'eth_sign',
                'personal_sign',
                'eth_signTypedData',
              ],
              chains: [`eip155:${Number(process.env.VUE_APP_CHAIN_ID)}`],
              events: ['walletEvent', 'dappEvent'],
            },
          },
        })
        .then((response) => {
          if (response.uri) {
            web3Modal.openModal({ uri: response.uri });
            response
              .approval()
              .then((session) => {
                emit('walletConnect', 'connect');
                web3Modal.closeModal();
              })
              .catch((e) => {
                web3Modal.closeModal();
              });
          }
        });
    },
    getSession: () => {
      if (signClient && signClient.session.length) {
        const lastKeyIndex = signClient.session.keys.length - 1;
        const session = signClient.session.get(
          signClient.session.keys[lastKeyIndex]
        );
        return session;
      } else return null;
    },
    disconnectSessions: async () => {
      if (signClient && signClient.session.length) {
        for (let key of signClient.session.keys) {
          const session = signClient.session.get(key);
          try {
            await signClient.disconnect({
              topic: session.topic,
            });
          } catch (e) {
            console.log(
              `Error while trying to disconnect WalletConnect session ${session.topic} :`,
              e
            );
          }
        }
      }
    },
  };

  signClient.on('session_event', (event) => {
    emit('walletConnect', event);
  });

  signClient.on('connect', () => {
    emit('walletConnect', 'connect');
    web3Modal.closeModal();
  });

  signClient.on('session_delete', () => {
    emit('walletConnect', 'disconnect');
    web3Modal.closeModal();
  });

  return walletConnect;
}
