<template>
  <div class="flex items-center">
    <p class="text-xs mr-2">BNB :</p>
    <custom-loader v-if="bnbBalance == null" class="mr-3" color="#ffffff" size="10px">
    </custom-loader>
    <p v-else class="text-xs text-white">
      {{ bnbBalance }}
    </p>
  </div>
  <div class="flex items-center">
    <p class="text-xs mr-3">SYL :</p>
    <custom-loader v-if="sylBalance == null" class="mr-3" color="#ffffff" size="10px">
    </custom-loader>
    <p v-else class="text-xs text-white">
      {{ sylBalance }}
    </p>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const bnbBalance = ref(null);
    const sylBalance = ref(null);

    function updateBalances() {
      if (currentIssuerDid.value) {
        utils
          .getCurrentProviderBalance(
            currentIssuerDid.value.replace('DID:SDI:', '')
          )
          .then((amount) => (bnbBalance.value = parseFloat(amount).toFixed(6)));
        utils.syl
          .getSylBalance(currentIssuerDid.value.replace('DID:SDI:', ''))
          .then((amount) => (sylBalance.value = parseFloat(amount)).toFixed(6));
      }
    }

    updateBalances();
    setInterval(updateBalances, 5000);

    return {
      bnbBalance,
      sylBalance,
    };
  },
};
</script>
