<template>
  <div>
    <slot v-if="!loading"></slot>
    <div v-else class="flex justify-center items-center mt-10">
      <custom-loader color="#111827" size="30px" class="mr-3"> </custom-loader>
    </div>
  </div>
</template>
<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const loading = ref(true);

    if (currentIssuerDid.value) {
      utils.mydid
        .isAdmin(currentIssuerDid.value.replace('DID:SDI:', ''))
        .then((status) => {
          if (!status) window.location.replace('/');
          else loading.value = false;
        });
    } else {
      window.location.replace('/');
    }

    return {
      loading,
    };
  },
};
</script>
