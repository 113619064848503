<template>
  <div class="my-10 mx-20">
    <div class="mt-1 py-2 px-4 mb-1">
      <router-link to="/"
        ><div class="flex justify-center mb-10">
          <img
            :src="require('/assets/images/small_mydid.png')"
            alt=""
            class="w-8 self-center mr-2"
          /><img
            :src="require('/assets/images/mydid.png')"
            alt=""
            class="w-28 self-center"
          /></div
      ></router-link>
    </div>
    <div
      class="w-2/4 m-auto text-center shadow border border-gray-200 bg-white rounded-lg h-full p-5 mb-10"
    >
      <p class="text-left text-xl font-bold text-gray-600 mb-5">
        <i class="fas fa-sign-in-alt mr-3"></i>Access issuer dashboard
      </p>
      <p class="text-l text-gray-600 mb-5">
        Scan QR code with the mobile application to sign in :
      </p>
      <div class="flex items-center justify-center mb-3">
        <custom-loader v-if="loading" class="mr-2" color="#151838" size="20px">
        </custom-loader>
        <p class="text-md text-primary-light">
          {{ status }}
        </p>
      </div>
      <div class="flex items-center justify-center mb-8">
        <qrcode-vue v-if="qrCode" :value="qrCode" :size="200" level="H" />
      </div>
      <p v-if="verifiableCredentials.length > 0" class="text-xs text-gray-600">
        <i class="fas fa-shield-alt mr-1"></i>Verifiable credentials needed :
      </p>
      <p v-else class="text-xs text-gray-600">
        No verifiable credentials needed
      </p>
      <p
        class="text-xs text-gray-600"
        v-for="vc in verifiableCredentials"
        :key="vc"
      >
        • {{ vc }}
      </p>
    </div>
    <div
      class="w-2/4 m-auto text-center shadow border border-gray-200 bg-white rounded-lg h-full p-5"
    >
      <p class="text-left text-xl font-bold text-gray-600 mb-5">
        <i class="fas fa-building mr-3"></i>Create issuer and manage access
      </p>
      <p class="text-l text-gray-600 mb-5">
        Click below to create a new issuer or to manage user access for an
        existant issuer :
      </p>
      <div class="mb-3">
        <router-link to="/create-issuer">
          <button
            class="
              text-sm
              bg-primary-light
              text-white
              font-bold
              inline-block
              rounded-lg
              cursor-pointer
              py-3
              px-5
              mr-4
            "
          >
            Create or manage
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onBeforeUnmount } from 'vue';
export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const cookies = inject('cookies');
    const qrCode = ref('');
    const status = ref('');
    const verifiableCredentials = ref({});
    const loading = ref(null);

    onBeforeUnmount(() => {
      api.closeChallengeValidation();
    });

    api.createChallengeSignup().then(res => {
      const newChallenge = res.data;
      qrCode.value = JSON.stringify(newChallenge);
      verifiableCredentials.value = newChallenge.verifiableCredentials;

      // CREATE SSE CONNECTION
      api.waitChallengeValidation(newChallenge.challenge, response => {
        switch (response.status) {
          case 'waiting':
            status.value = 'Waiting scan from mobile application';
            loading.value = true;
            break;
          case 'treating':
            status.value = 'Checking sign in...';
            loading.value = true;
            break;
          case 'expired':
            status.value = 'Error while registering : ' + response.message;
            loading.value = false;
            break;
          case 'validated':
            status.value = 'Sign in accepted. Redirecting...';
            cookies.set('token', response.token);
            loading.value = false;
            setTimeout(() => window.location.replace('/'), 1000);
            break;
        }
      });
    });

    return {
      qrCode,
      status,
      verifiableCredentials,
      loading
    };
  }
};
</script>
