<template>
  <admin-checker>
    <div class="my-10 mx-20">
      <div class="flex items-center mb-5">
        <p class="text-xl font-bold text-gray-600">
          <router-link to="/"
            ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
          >Create verifier
        </p>
        <p
          class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
        >
          admin
        </p>
      </div>
      <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
      <p class="text-sm text-gray-600 mb-10">
        <i class="fas fa-info-circle text-gray-700 mr-2"></i>Create DID document
        and IPFS profile for an verifier.
      </p>
      <p class="text-gray-600 mr-3 mb-3">Verifer images :</p>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <div class="flex items-center mb-5">
            <button
              class="text-gray-900 text-sm border border-gray-900 inline-block rounded-lg cursor-pointer mr-5 py-2 px-3"
              @click="$refs.profileImageInput.click()"
            >
              Load profile image
            </button>
            <input
              type="file"
              @change="onProfileImageInput"
              ref="profileImageInput"
              class="hidden"
            />
            <p class="text-sm italic text-gray-600">
              {{ profileImageMessage }}
            </p>
          </div>
          <img
            v-if="profileImagePreview"
            class="h-32 mb-5"
            :src="profileImagePreview"
          />
        </div>
        <div>
          <div class="flex items-center mb-5">
            <button
              class="text-gray-900 text-sm border border-gray-900 inline-block rounded-lg cursor-pointer mr-5 py-2 px-3"
              @click="$refs.coverImageInput.click()"
            >
              Load cover image
            </button>
            <input
              type="file"
              @change="onCoverImageInput"
              ref="coverImageInput"
              class="hidden"
            />
            <p class="text-sm italic text-gray-600">
              {{ coverImageMessage }}
            </p>
          </div>
          <img
            v-if="coverImagePreview"
            class="h-32 mb-5"
            :src="coverImagePreview"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer name :</p>
          <textarea
            type="text"
            placeholder="Verifer name"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierName"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer nationality :</p>
          <country-select
            v-model="verifierNationality"
            :country="verifierNationality"
            topCountry="FR"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer email :</p>
          <textarea
            type="text"
            placeholder="Verifer email"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierEmail"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer website :</p>
          <textarea
            type="text"
            placeholder="Verifer website"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierWebsite"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer twitter :</p>
          <textarea
            type="text"
            placeholder="Verifer twitter"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierTwitter"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer ecosystem :</p>
          <textarea
            type="text"
            placeholder="Verifer ecosystem"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierEcosystem"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer sector :</p>
          <textarea
            type="text"
            placeholder="Verifer sector"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierSector"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer subsector :</p>
          <textarea
            type="text"
            placeholder="Verifer subsector"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierSubsector"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5 mb-5">
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer registration :</p>
          <textarea
            type="text"
            placeholder="Verifer registration"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierRegistration"
          />
        </div>
        <div>
          <p class="text-gray-600 mr-3 mb-3">Verifer RSS :</p>
          <textarea
            type="text"
            placeholder="Verifer RSS feed"
            rows="1"
            class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
            v-model="verifierRss"
          />
        </div>
      </div>
      <p class="text-gray-600 mr-3 mb-3">Verifer address :</p>
      <textarea
        type="text"
        placeholder="Address value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="verifierAddress"
      />
      <p class="text-gray-600 mr-3 mb-3">Verifer certificate :</p>
      <textarea
        type="text"
        placeholder="Certificate IPFS"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="verifierCertificate"
      />
      <p class="text-gray-600 mr-3 mb-3">Authentication key :</p>
      <textarea
        type="text"
        placeholder="Key value"
        rows="1"
        class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
        v-model="authenticationKey"
      />
      <method-price-handler
        method="createVerifierDIDPrice"
        class="mb-2"
      ></method-price-handler>
      <button
        class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
        :class="{
          'pointer-events-none opacity-50': buttonDisable,
        }"
        @click="createNewVerifer"
      >
        Create new verifier
      </button>
      <div
        v-if="ipfsLoading"
        class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
      >
        <div class="flex items-center">
          <custom-loader color="#111827" size="30px" class="mr-3">
          </custom-loader>
          <p class="text-gray-600 text-md font-bold">Pushing on IPFS</p>
        </div>
      </div>
      <transaction-handler
        ref="transactionHandlerRef"
        @transactionCompleted="onTransactionCompleted"
        @transactionFailed="buttonDisable = false"
      ></transaction-handler></div
  ></admin-checker>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');

    const profileImageSelectedFile = ref(null);
    const profileImageMessage = ref('Please select a file');
    const profileImagePreview = ref(null);

    const coverImageSelectedFile = ref(null);
    const coverImageMessage = ref('Please select a file');
    const coverImagePreview = ref(null);

    const verifierAddress = ref('0x5c5F7aD4BE8A16Ce6108E6AF98DfEcA3Ef16784F');
    const authenticationKey = ref(
      '0x0231ca6d5944ef328d568cba6522194fe6382c803cbf979c26ef7ce3cb08cf915d'
    );
    const verifierName = ref('');
    const verifierNationality = ref('');
    const verifierEmail = ref('');
    const verifierWebsite = ref('');
    const verifierTwitter = ref('');
    const verifierEcosystem = ref('');
    const verifierSector = ref('');
    const verifierSubsector = ref('');
    const verifierRegistration = ref('');
    const verifierRss = ref('');
    const verifierCertificate = ref('');
    const verifierProfileIFPS = ref('');

    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();
    const ipfsLoading = ref(false);

    async function onProfileImageInput(event) {
      if (event.target.files[0]['type'].split('/')[0] != 'image') {
        profileImageMessage.value = 'Please select an image';
        return;
      }
      profileImageSelectedFile.value = event.target.files[0];
      profileImageMessage.value = profileImageSelectedFile.value.name;
      const reader = new FileReader();
      reader.readAsDataURL(profileImageSelectedFile.value);
      reader.onload = async (e) => {
        profileImagePreview.value = e.target.result;
      };
    }

    async function onCoverImageInput(event) {
      if (event.target.files[0]['type'].split('/')[0] != 'image') {
        coverImageMessage.value = 'Please select an image';
        return;
      }
      coverImageSelectedFile.value = event.target.files[0];
      coverImageMessage.value = coverImageSelectedFile.value.name;
      const reader = new FileReader();
      reader.readAsDataURL(coverImageSelectedFile.value);
      reader.onload = async (e) => {
        coverImagePreview.value = e.target.result;
      };
    }

    async function createNewVerifer() {
      buttonDisable.value = true;
      ipfsLoading.value = true;
      verifierProfileIFPS.value = '';

      // push image on IPFS
      const profileImageIPFSHash = await utils.ipfs.uploadIpfsFile(
        profileImageSelectedFile.value
      );
      const coverImageIPFSHash = await utils.ipfs.uploadIpfsFile(
        coverImageSelectedFile.value
      );

      // push textfile on IPFS
      const verifierProfile = {
        type: 'Verifier Profile',
        id: 'IPFS',
        name: verifierName.value,
        nationality: verifierNationality.value,
        website: verifierWebsite.value,
        email: verifierEmail.value,
        twitter: verifierTwitter.value,
        ecosystem: verifierEcosystem.value,
        sector: verifierSector.value,
        subsector: verifierSubsector.value,
        registration: verifierRegistration.value,
        rss: verifierRss.value,
        certificate: verifierCertificate.value,
        coverImage: utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(coverImageIPFSHash)
        ),
        profileImage: utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(profileImageIPFSHash)
        ),
      };
      const verifierIPFSHash = await utils.ipfs.uploadIpfsJsonData(
        JSON.stringify(verifierProfile)
      );
      verifierProfileIFPS.value = utils.ipfs.getUrlFromCID(
        utils.ipfs.hashToCID(verifierIPFSHash)
      );

      ipfsLoading.value = false;

      api.addIpfsData({
        context: 'verifier-admin-create',
        hash: verifierIPFSHash,
        data: verifierProfile,
      });

      transactionHandlerRef.value.createTransaction(
        'createVerifierDID',
        verifierAddress.value,
        '0x' + verifierIPFSHash,
        authenticationKey.value
      );
    }

    function onTransactionCompleted() {
      buttonDisable.value = false;
      // api.createVerifer({
      //   name: verifierName.value,
      //   did: 'DID:SDI:' + verifierAddress.value
      // });
    }

    return {
      onProfileImageInput,
      onCoverImageInput,
      profileImageSelectedFile,
      profileImageMessage,
      profileImagePreview,
      coverImageSelectedFile,
      coverImageMessage,
      coverImagePreview,
      verifierAddress,
      verifierName,
      verifierNationality,
      verifierEmail,
      verifierWebsite,
      verifierTwitter,
      verifierEcosystem,
      verifierSector,
      verifierSubsector,
      verifierRegistration,
      verifierRss,
      verifierCertificate,
      authenticationKey,
      verifierProfileIFPS,
      createNewVerifer,
      buttonDisable,
      transactionHandlerRef,
      onTransactionCompleted,
      ipfsLoading,
    };
  },
};
</script>
