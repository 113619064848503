<template>
  <div>
    <div
      v-if="!currentIssuerDid"
      class="flex items-center"
      @click="connectIssuer"
    >
      <p
        class="bg-white text-black text-xs font-bold rounded-md cursor-pointer p-2"
      >
        Connect issuer
      </p>
    </div>
    <div v-else>
      <div class="flex items-center mb-1">
        <img
          :src="require('/assets/images/walletconnect.png')"
          alt=""
          class="flex-none w-4 my-auto mr-2"
        />
        <p class="text-gray-300 text-xs break-words">Issuer connected with :</p>
      </div>
      <p class="text-white text-xs mb-1 break-words">
        {{ currentIssuerDid }}
      </p>
      <p v-if="!issuerFromRegistration" class="text-white text-xxs mb-1">
        <i class="fas fa-info-circle mr-1"></i
        ><router-link to="/create-issuer" class="underline"
          >Register issuer</router-link
        >
        to enable issuer mode
      </p>
      <div v-else>
        <div
          class="text-white text-xs mb-2 break-words"
          v-if="issuerFromRegistration"
        >
          <i class="fas fa-building mr-1"></i>{{ issuerFromRegistration.name }}
        </div>
        <div class="text-gray-300 text-xs mt-2 mb-1 break-words">
          <wallets></wallets>
        </div>
      </div>
      <div>
        <p
          class="text-gray-300 text-xs cursor-pointer"
          @click="disconnectIssuer"
        >
          Log out issuer<i class="fas fa-sign-out-alt ml-2"></i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const api = inject('api');
    const cookies = inject('cookies');
    const store = useStore();

    const walletConnect = inject('walletConnect');

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const issuerFromRegistration = ref(null);

    if (currentIssuerDid.value) {
      api.getIssuer(currentIssuerDid.value).then((result) => {
        if (result.data.found)
          issuerFromRegistration.value = result.data.issuer;
      });
    }

    async function disconnectIssuer() {
      walletConnect.value.disconnectSessions();
      cookies.remove('expirationTime');
      cookies.remove('expirationSignature');
      store.commit('resetIssuerState');
    }

    function connectIssuer() {
      cookies.remove('expirationTime');
      cookies.remove('expirationSignature');
      store.commit('resetIssuerState');
      store.commit('setIssuerConnectionDisplay', true);
    }

    return {
      currentIssuerDid,
      issuerFromRegistration,
      disconnectIssuer,
      connectIssuer,
    };
  },
};
</script>
