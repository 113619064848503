<template>
  <div class="flex flex-col">
    <div class="px-3 py-3">
      <issuer-connect class="px-2 mt-2"></issuer-connect>
    </div>
    <hr class="border-t border-white border-opacity-20 w-full" />
    <div v-if="currentIssuerDid" class="p-3">
      <p class="text-gray-300 text-xs mx-2 mb-4 mt-2 break-words">
        Transactions<span @click="getLastTransactions()"
          ><i class="fas fa-sync-alt cursor-pointer ml-3"></i
        ></span>
      </p>
      <p class="text-gray-500 text-xxs break-words mx-2 mb-4 mt-2">
        For issuer : {{ currentIssuerDid }}
      </p>
      <div
        v-for="transaction in transactions"
        :key="transaction"
        class="mx-2 mb-3 mt-2 break-words cursor-pointer"
      >
        <a :href="scanUrlPrefix + transaction.hash" target="_blank">
          <p class="text-gray-50 text-xs font-bold">
            {{ transaction.action }}
          </p>
          <p class="text-gray-300 text-xxs line-clamp-1">
            {{ transaction.hash }}
          </p>
          <p class="text-gray-300 text-xxs">
            {{
              transaction.created.split('T')[0] +
              ' ' +
              transaction.created.split('T')[1].split('.')[0]
            }}
          </p>
          <p
            class="px-2 inline-flex text-xs leading-5 rounded-full text-white"
            :class="{
              'bg-green-700': transaction.state == 'success',
              'bg-red-700': transaction.state == 'error',
              'bg-blue-700': transaction.state == 'pending',
            }"
          >
            {{ transaction.state }}
          </p></a
        >
      </div>
      <router-link to="/transactions"
        ><p
          class="text-gray-500 text-xs text-right mx-2 mb-4 mt-5 cursor-pointer"
        >
          <i class="fas fa-plus text-xxs mr-1"></i>Voir plus
        </p></router-link
      >
    </div>
    <div v-else class="p-3">
      <p class="text-gray-300 text-xs mx-2 mb-4 mt-2 break-words">
        Transactions
      </p>
      <p class="text-gray-500 text-xxs break-words mx-2 mb-4 mt-2">
        No issuer connected
      </p>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const api = inject('api');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    const transactions = ref([]);
    const scanUrlPrefix = ref(process.env.VUE_APP_SCAN_URL_PREFIX);
    const issuerFromRegistration = ref(null);

    if (currentIssuerDid.value) {
      api.getIssuer(currentIssuerDid.value).then((result) => {
        if (result.data.found) {
          issuerFromRegistration.value = result.data.issuer;
          getLastTransactions();
          setInterval(async () => {
            getLastTransactions();
          }, 5000);
        }
      });
    }

    async function getLastTransactions() {
      if (currentIssuerDid.value && issuerFromRegistration.value)
        transactions.value = (
          await api.getIssuerTransactions(currentIssuerDid.value, null, 7)
        ).data;
    }

    return {
      currentIssuerDid,
      transactions,
      getLastTransactions,
      scanUrlPrefix,
    };
  },
};
</script>
