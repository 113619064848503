<template>
  <div class="my-10 mx-20">
    <p class="text-xl font-bold text-gray-600 mb-5">
      <i class="fas fa-grip-horizontal mr-2"></i>Dashboard
    </p>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-10" />
    <div v-if="issuers">
      <p class="font-bold text-gray-600 mb-5">
        <i class="fas fa-building mr-2"></i>Issuers
      </p>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="w-full table-auto divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Issuer DID
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      IPFS Link
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="issuer in issuers"
                  :key="issuer._id"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr>
                    <td class="px-6 py-4">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <img
                            class="h-10 w-10 rounded-full"
                            src="https://www.iconpacks.net/icons/1/free-building-icon-1062-thumb.png"
                            alt=""
                          />
                        </div>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ issuer.name }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div class="text-xs text-gray-900 break-all">
                        {{ issuer.did }}
                      </div>
                    </td>
                    <td class="px-6 py-4">
                      <div class="text-lg text-gray-500 break-all">
                        <a :href="issuer.url" target="_blank"
                          ><i class="fas fa-external-link-alt ml-1"></i
                        ></a>
                      </div>
                    </td>
                    <td class="px-6 py-4 text-xs text-gray-500">
                      {{
                        issuer.created.split('T')[0] +
                        ' ' +
                        issuer.created.split('T')[1].split('.')[0]
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');

    const issuers = ref([]);

    api.getIssuers().then((result) => {
      const registeredIssuers = result.data;
      registeredIssuers.forEach(async (issuer) => {
        const didDocument = await utils.mydid.getDID(
          issuer.did.replace('DID:SDI:', '')
        );
        const url = utils.ipfs.getUrlFromCID(
          utils.ipfs.hashToCID(didDocument[1])
        );

        issuers.value.push(Object.assign(issuer, { url }));
      });
    });

    return {
      issuers,
    };
  },
};
</script>
