import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import utils from './plugins/utils';
import VueCookies from 'vue-cookies';
import jwtDecode from 'jwt-decode';

import Dashboard from './views/Dashboard';
import Transactions from './views/Transactions';
import Signin from './views/Signin';
import CreateIssuer from './views/CreateIssuer';
import ChangeIssuerCategory from './views/IssuerFunctions/ChangeIssuerCategory';
import VCChangeController from './views/IssuerFunctions/ChangeController';
import VCSetAttribute from './views/IssuerFunctions/SetAttribute';

import AdminCreateIssuer from './views/AdminFunctions/CreateIssuer';
import AdminCreateVerifier from './views/AdminFunctions/CreateVerifier';
import AdminGetIssuerList from './views/AdminFunctions/GetIssuerList';
import AdminWithdrawFees from './views/AdminFunctions/WithdrawFees';
import AdminStatistics from './views/AdminFunctions/Statistics';
import AdminSeeAllUniverses from './views/AdminFunctions/SeeAllUniverses';
import AdminSeeP2PTemplates from './views/AdminFunctions/GetP2PTemplateList';

import VCCreateUser from './views/VCFunctions/CreateUser';
import VCManageVC from './views/VCFunctions/ManageVC';
import VCGetPublicRefList from './views/VCFunctions/GetPublicRefList';
import VCUpdateVCStatus from './views/VCFunctions/UpdateVCStatus';

import OBManageTemplates from './views/OBFunctions/ManageTemplates';
import OBIssueBadgeVC from './views/OBFunctions/IssueBadgeVC';

import UNManageUniverse from './views/UniverseFunctions/ManageUniverse';
import UNManageAppUniverseList from './views/UniverseFunctions/ManageAppUniverseList';

import Protected from './views/Protected';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Dashboard },
    { path: '/signin', component: Signin },
    { path: '/create-issuer', component: CreateIssuer },
    { path: '/transactions', component: Transactions },
    { path: '/admin/create-issuer', component: AdminCreateIssuer },
    { path: '/admin/create-verifier', component: AdminCreateVerifier },
    { path: '/admin/get-issuer-list', component: AdminGetIssuerList },
    { path: '/admin/withdraw-fees', component: AdminWithdrawFees },
    { path: '/admin/statistics', component: AdminStatistics },
    { path: '/admin/see-all-universes', component: AdminSeeAllUniverses },
    { path: '/admin/see-p2p-templates', component: AdminSeeP2PTemplates },
    { path: '/users/create-user', component: VCCreateUser },
    { path: '/users/set-attribute', component: VCSetAttribute },
    { path: '/users/change-controller', component: VCChangeController },
    {
      path: '/universe/manage-universe',
      component: UNManageUniverse,
    },
    {
      path: '/universe/manage-app-universe-list',
      component: UNManageAppUniverseList,
    },
    {
      path: '/vc/manage-vc',
      component: VCManageVC,
    },
    {
      path: '/vc/get-public-ref',
      component: VCGetPublicRefList,
    },
    {
      path: '/vc/update-vc-status',
      component: VCUpdateVCStatus,
    },
    { path: '/ob/manage-templates', component: OBManageTemplates },
    { path: '/ob/issue-badge-vc', component: OBIssueBadgeVC },
    { path: '/issuer/change-issuer-category', component: ChangeIssuerCategory },
    {
      path: '/protected',
      component: Protected,
    },
    { path: '/:notFound(.*)', redirect: '/' },
  ],
});

router.beforeEach(async (to, from, next) => {
  // password protection
  if (to.path != '/protected') {
    if (
      !VueCookies.get('pwd') ||
      VueCookies.get('pwd') != process.env.VUE_APP_PASSWORD
    ) {
      VueCookies.remove('pwd');
      store.commit('setVerticalBarsDisplay', false);
      return router.push('/protected');
    } else {
      store.commit('setVerticalBarsDisplay', true);
    }
  } else {
    if (
      !VueCookies.get('pwd') ||
      VueCookies.get('pwd') != process.env.VUE_APP_PASSWORD
    ) {
      VueCookies.remove('pwd');
      store.commit('setVerticalBarsDisplay', false);
    } else {
      store.commit('setVerticalBarsDisplay', true);
      return router.push('/');
    }
  }

  if (to.path == '/create-issuer') {
    store.commit('setVerticalBarsDisplay', false);
  }

  const token = VueCookies.get('token');
  const expirationTime = VueCookies.get('expirationTime');

  if (token && jwtDecode(token).exp < Date.now() / 1000) {
    VueCookies.remove('token');
    return (window.location.href = '/');
  }

  if (expirationTime && expirationTime < Date.now()) {
    VueCookies.remove('expirationTime');
    return (window.location.href = '/');
  }

  return next();
});

export default router;
