<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Change issuer category
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        issuers
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-5">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Get issuer category
      or change it.
    </p>
    <button
      class="text-sm bg-transparent text-primary border-primary border-2 font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
      @click="getIssuerCategory"
    >
      Get issuer category
    </button>
    <p
      v-if="issuerCategory"
      class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
    >
      <i class="fas fa-info-circle mr-2"></i>Current issuer category :
      {{ issuerCategory }}
    </p>
    <method-price-handler
      method="upgradeCategoryPrice"
      class="mt-10 mb-2"
    ></method-price-handler>
    <button
      class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
      :class="{
        'pointer-events-none opacity-50': buttonDisable,
      }"
      @click="
        () => {
          buttonDisable = true;
          transactionHandlerRef.createTransaction('upgradeIssuerCategory');
        }
      "
    >
      Update issuer category
    </button>
    <transaction-handler
      ref="transactionHandlerRef"
      @transactionCompleted="buttonDisable = false"
      @transactionFailed="buttonDisable = false"
    ></transaction-handler>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();
    const issuerCategory = ref(null);

    async function getIssuerCategory() {
      if (!window.ethereum)
        throw new Error('No crypto wallet found. Please install it.');
      issuerCategory.value = await utils.mydid.getIssuerCategory(
        currentIssuerDid.value.replace('DID:SDI:', '')
      );
    }

    return {
      issuerCategory,
      getIssuerCategory,
      buttonDisable,
      transactionHandlerRef,
    };
  },
};
</script>
