<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Add badge to user (V3)
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        open badge
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-2">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Add on-chain badge
      for a specific user (with role badge option).
    </p>
    <div class="grid grid-cols-2 gap-5 mb-5 items-center">
      <div>
        <p class="text-gray-600 mr-3 mb-1">Badge template :</p>
        <select
          required
          rows="1"
          class="w-full outline-none bg-gray-200 rounded-lg border-none p-3"
          v-model="templateName"
        >
          <option disabled selected value="">Please select an option</option>
          <option v-for="template in badgeTemplateList" :key="template">
            {{ utils.hexToAscii(template.name) }}
          </option>
        </select>
      </div>
      <div>
        <img
          v-if="templateImageUrl"
          :src="templateImageUrl"
          alt=""
          class="w-32"
        />
        <custom-loader
          v-if="templateLoading"
          class="mt-4"
          color="#111827"
          size="30px"
        >
        </custom-loader>
      </div>
    </div>
    <p class="text-gray-600 mr-3 mb-3">User DID :</p>
    <textarea
      type="text"
      placeholder="DID value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="userDid"
    />
    <p class="text-gray-600 mr-3 mb-1">Is role badge :</p>
    <select
      required
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="isRoleBadge"
    >
      <option disabled selected value="">Please select an option</option>
      <option :value="true">True</option>
      <option :value="false">False</option>
    </select>
    <div class="grid grid-cols-2 gap-5 mb-5">
      <div>
        <p class="text-gray-600 mr-3 mb-3">Custom name :</p>
        <textarea
          type="text"
          placeholder="Value"
          rows="1"
          class="w-full outline-none bg-gray-200 rounded-lg border-none p-30"
          v-model="customName"
        />
      </div>
      <div>
        <p class="text-gray-600 mr-3 mb-3">Custom description :</p>
        <textarea
          type="text"
          placeholder="Value"
          rows="1"
          class="w-full outline-none bg-gray-200 rounded-lg border-none p-30"
          v-model="customDescription"
        />
      </div>
    </div>
    <button
      class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-10"
      :class="{
        'pointer-events-none opacity-50':
          buttonDisable || badgeTemplateList.length == 0,
      }"
      @click="createBadge"
    >
      Create and sign open badge
    </button>
    <div
      v-if="waitingSignature"
      class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5 mb-10"
    >
      <div class="flex items-center">
        <custom-loader color="#111827" size="30px" class="mr-3">
        </custom-loader>
        <p class="text-gray-600 text-md font-bold">
          Waiting for WalletConnect signature
        </p>
      </div>
    </div>
    <div v-if="gifSrc" class="mb-10">
      <!-- <qrcode-vue
        :value="JSON.stringify(newBadge)"
        :size="500"
        level="L"
        :margin="5"
        class="mr-5 mb-5"
      /> -->
      <img :src="gifSrc" />
    </div>
    <method-price-handler
      method="issueBadgePrice"
      class="mb-2"
    ></method-price-handler>
    <button
      class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
      :class="{
        'pointer-events-none opacity-50':
          buttonDisable || badgeTemplateList.length == 0,
      }"
      @click="addBadge"
    >
      Add badge
    </button>
    <div
      v-if="ipfsLoading"
      class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
    >
      <div class="flex items-center">
        <custom-loader color="#111827" size="30px" class="mr-3">
        </custom-loader>
        <p class="text-gray-600 text-md font-bold">Pushing on IPFS</p>
      </div>
    </div>
    <transaction-handler
      ref="transactionHandlerRef"
      @transactionCompleted="buttonDisable = false"
      @transactionFailed="buttonDisable = false"
    ></transaction-handler>
    <!-- <div
      v-if="badgeIPFSHash"
      :key="badgeIPFSHash"
      class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5 mt-3"
    >
      <p class="text-gray-600 text-sm mr-3 mb-3">
        IPFS link for badge :
        <a
          :href="utils.ipfs.getUrlFromCID(utils.ipfs.hashToCID(badgeIPFSHash))"
          >{{
            utils.ipfs.getUrlFromCID(utils.ipfs.hashToCID(badgeIPFSHash))
          }}</a
        >
      </p>
      <p class="text-gray-600 text-sm mr-3 mb-3">
        <i class="fas fa-qrcode mr-2"></i>Here is the QR Code for created badge
        with hash : 0x{{ badgeIPFSHash }}
      </p>
      <qrcode-vue :value="badgeIPFSHash" :size="200" level="H" class="mr-5" />
    </div> -->
  </div>
</template>

<script>
import { ref, inject, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { createGif } from '../../plugins/qrGif';
export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const walletConnect = inject('walletConnect');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    const badgeTemplateList = ref([]);
    const templateName = ref('');
    const templateHash = ref('');
    const userDid = ref('');
    const customName = ref('');
    const customDescription = ref('');
    const isRoleBadge = ref(false);
    const badgeIPFSHash = ref('');
    const badgeHash = ref('');
    const currentTemplate = ref('');
    const templateImageUrl = ref('');
    const templateLoading = ref(false);

    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();
    const ipfsLoading = ref(false);
    const waitingSignature = ref(false);
    const newBadge = ref(null);
    const gifSrc = ref(null);

    async function createBadge() {
      newBadge.value = null;
      badgeHash.value = '';

      const issuerInfo = await getCurrentIssuerProfileAndUrl();

      const badgeWithoutProof = isRoleBadge.value
        ? utils.badge.computeRoleBadgeV3(
            uuidv4(),
            currentIssuerDid.value + '#SERV_1',
            issuerInfo.issuerProfile.name,
            userDid.value,
            currentTemplate.value,
            templateHash.value
          )
        : utils.badge.computeBadgeV3(
            uuidv4(),
            currentIssuerDid.value + '#SERV_1',
            issuerInfo.issuerProfile.name,
            userDid.value,
            currentTemplate.value,
            customName.value,
            customDescription.value,
            templateHash.value
          );

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      var from = currentIssuerDid.value.replace('DID:SDI:', '');

      if (!walletConnect.value)
        throw new Error('No wallet connected in WalletConnect');

      waitingSignature.value = true;

      let signedHash = '';
      try {
        const session = walletConnect.value.getSession();
        signedHash = await walletConnect.value.connector.request({
          topic: session.topic,
          chainId: `eip155:${Number(process.env.VUE_APP_CHAIN_ID)}`,
          request: {
            id: 1,
            jsonrpc: '2.0',
            method: 'eth_signTypedData',
            params: [from, JSON.stringify(typedData)],
          },
        });
        waitingSignature.value = false;
      } catch (e) {
        waitingSignature.value = false;
        console.log(e);
      }

      const badge = await utils.vc.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signedHash,
        null
      );

      console.log(JSON.stringify(badge, null, 2));

      // Verifying signature
      const address = await utils.sign.recoverVCTypedSignatureV4(badge);

      if (address == currentIssuerDid.value.replace('DID:SDI:', '')) {
        newBadge.value = badge;
        badgeHash.value = utils.createSHA256Hash(JSON.stringify(badge));
        createGif(JSON.stringify(badge)).then((res) => {
          gifSrc.value = res;
        });
      } else {
        throw new Error('Signature invalid for verifiable credential');
      }
    }

    async function addBadge() {
      buttonDisable.value = true;

      // ipfsLoading.value = true;
      // badgeIPFSHash.value = await utils.ipfs.uploadIpfsJsonData(
      //   JSON.stringify(newBadge.value)
      // );
      // ipfsLoading.value = false;
      // api.addIpfsData({
      //   context: 'badge-issue',
      //   hash: badgeIPFSHash.value,
      //   data: newBadge.value,
      // });

      transactionHandlerRef.value.createTransaction(
        'issueBadge',
        [userDid.value.replace('DID:SDI:', '')],
        [utils.createSHA256Hash(JSON.stringify(newBadge.value))]
      );
    }

    watch(templateName, async () => {
      const index = badgeTemplateList.value.findIndex(
        (el) => el.name == utils.asciiToHex(templateName.value)
      );
      templateHash.value = badgeTemplateList.value[index].badgeTemplateHash;
      const badgeTemplate = await utils.ipfs.getJsonDataFromCID(
        utils.ipfs.hashToCID(templateHash.value.replace('0x', ''))
      );
      templateImageUrl.value = badgeTemplate.image;
      currentTemplate.value = badgeTemplate;
    });

    async function getIssuerBadgeTemplateList() {
      templateLoading.value = true;
      try {
        badgeTemplateList.value = await utils.mydid.getIssuerBadgeTemplateList(
          currentIssuerDid.value.replace('DID:SDI:', '')
        );
        if (badgeTemplateList.value[0])
          templateName.value = utils.hexToAscii(
            badgeTemplateList.value[0].name
          );
        templateLoading.value = false;
      } catch (e) {
        templateLoading.value = false;
        console.log(e);
      }
    }

    async function getCurrentIssuerProfileAndUrl() {
      const didDocument = await utils.mydid.getDID(
        currentIssuerDid.value.replace('DID:SDI:', '')
      );
      const issuerUrl = utils.ipfs.getUrlFromCID(
        utils.ipfs.hashToCID(didDocument[1])
      );
      const issuerProfile = await utils.ipfs.getJsonDataFromUrl(issuerUrl);
      return { issuerUrl, issuerProfile };
    }

    onMounted(() => {
      setTimeout(getIssuerBadgeTemplateList, 500);
    });

    return {
      badgeTemplateList,
      templateName,
      userDid,
      customName,
      customDescription,
      isRoleBadge,
      badgeIPFSHash,
      templateImageUrl,
      templateLoading,
      createBadge,
      addBadge,
      utils,
      buttonDisable,
      transactionHandlerRef,
      ipfsLoading,
      waitingSignature,
      newBadge,
      gifSrc,
    };
  },
};
</script>
