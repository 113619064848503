<template>
  <admin-checker>
    <div class="my-10 mx-20">
      <div class="flex items-center mb-5">
        <p class="text-xl font-bold text-gray-600">
          <router-link to="/"
            ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
          >See validated universes
        </p>
        <p
          class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
        >
          universe
        </p>
      </div>
      <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
      <p class="text-sm text-gray-600 mb-10">
        <i class="fas fa-info-circle text-gray-700 mr-2"></i>See all universes
        from myDid app list.
      </p>
      <p class="font-bold text-gray-600 mb-5">
        <i class="fas fa-certificate mr-2"></i>Universe list
      </p>
      <div class="flex flex-col mb-10">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="w-full table-auto divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      Issuer DID
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      IPFS
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="universe in universeList"
                  :key="universe"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr>
                    <td class="px-6 py-4">
                      <p class="text-sm font-bold text-gray-700">
                        {{ universe.localeNames.en }}
                      </p>
                    </td>
                    <td class="px-6 py-4">
                      <p class="text-sm font-medium text-gray-700">
                        {{ 'DID:SDI:' + universe.issuerAddress }}
                      </p>
                    </td>
                    <td class="px-6 py-4">
                      <a :href="universe.ipfs" target="_blank"
                        ><p class="underline text-xs">Full profile</p></a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="universeListLoading" class="flex justify-center mt-2">
          <custom-loader class="pb-3" color="#111827" size="30px">
          </custom-loader>
        </div>
      </div></div
  ></admin-checker>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const utils = inject('utils');

    const universeAddressList = ref(null);
    const universeList = ref([]);
    const universeListLoading = ref(false);

    async function getUniverseList() {
      universeListLoading.value = true;

      utils.mydid
        .getAppUniverseList(process.env.VUE_APP_MYDID_ISSUER_ADDRESS)
        .then(async (result) => {
          universeAddressList.value = result;
          getUniversesProfiles();
          universeListLoading.value = false;
        });
    }

    async function getUniversesProfiles() {
      for (var i = 0; i < universeAddressList.value.length; i++) {
        const universeIpfsHash = await utils.mydid.getUniverse(
          universeAddressList.value[i].issuer
        );
        universeList.value.push(
          Object.assign(
            await utils.ipfs.getJsonDataFromCID(
              utils.ipfs.hashToCID(universeIpfsHash)
            ),
            {
              ipfs: utils.ipfs.getUrlFromCID(
                utils.ipfs.hashToCID(universeIpfsHash)
              ),
              issuerAddress: universeAddressList.value[i].issuer,
            }
          )
        );
      }
    }

    getUniverseList();

    return {
      universeList,
      universeListLoading,
    };
  },
};
</script>
