<template>
  <admin-checker>
    <div class="my-10 mx-20">
      <div class="flex items-center mb-5">
        <p class="text-xl font-bold text-gray-600">
          <router-link to="/"
            ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
          >Statistics
        </p>
        <p
          class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
        >
          admin
        </p>
      </div>
      <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
      <p class="text-sm text-gray-600 mb-10">
        <i class="fas fa-info-circle text-gray-700 mr-2"></i>Get statistics from
        mobile application.
      </p>
      <div
        class="flex justify-center items-center bg-gray-200 rounded-lg p-5 mb-5"
      >
        <p class="text-gray-600 mr-2">From :</p>
        <datepicker class="border-none rounded-md mr-3" v-model="fromDate" />
        <p class="text-gray-600 mr-2">To :</p>
        <datepicker class="border-none rounded-md mr-5" v-model="toDate" />
        <p @click="getStatistics" class="text-gray-600 cursor-pointer">
          Refresh
          <i class="fas fa-sync-alt ml-1"></i>
        </p>
      </div>

      <div class="flex justify-center items-center mb-5">
        <BarChart
          :chartData="{
            labels: [...new Set(events.map((el) => el.data.department))],
            datasets: [
              {
                data: [...new Set(events.map((el) => el.data.department))].map(
                  (el) =>
                    events.filter((event) => event.data.department == el).length
                ),
              },
            ],
          }"
          :options="{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'Open badge localization',
              },
              autocolors: {
                mode: 'data',
              },
              datalabels: {
                color: 'white',
                font: {
                  size: '25',
                },
              },
              legend: {
                display: false,
              },
            },
          }"
          class="w-1/2 mb-10"
        /><DoughnutChart
          :chartData="{
            labels: p2pTemplates.map(
              (el) =>
                `${utils.hexToAscii(
                  el.name.replace(/^(0x)0+((\w{4})+)$/, '$1$2')
                )}`
            ),
            datasets: [
              {
                data: p2pTemplates.map(
                  (el) =>
                    events.filter(
                      (event) => event.data.templateHash == el.badgeTemplateHash
                    ).length
                ),
              },
            ],
          }"
          :options="{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: 'Open badge repartition',
              },
              autocolors: {
                mode: 'data',
              },
              datalabels: {
                color: 'white',
                font: {
                  size: '25',
                },
              },
            },
          }"
          class="w-1/3 mb-10 p-5"
        />
      </div>
      <LineChart
        :chartData="{
          labels: Object.keys(chartObCreationThroughTimeData),
          datasets: [
            {
              data: Object.values(chartObCreationThroughTimeData),
              fill: true,
            },
          ],
        }"
        :options="{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Open badge creation through time',
            },
            datalabels: {
              display: false,
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MMM DD',
                },
              },
            },
            y: {
              beginAtZero: true,
            },
          },
        }"
        class="w-full h-52 mb-10"
      />
      <div class="flex flex-col mb-10">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="w-full table-auto divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      Index
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      Hash
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      IPFS link
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider"
                    >
                      NUMBER CREATED
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="template in p2pTemplates"
                  :key="template.index"
                  class="bg-white divide-y divide-gray-200"
                >
                  <tr>
                    <td class="px-6 py-4">
                      <p class="text-sm font-medium text-gray-700">
                        {{ utils.hexToAscii(template.name) }}
                      </p>
                    </td>
                    <td class="px-6 py-4">
                      <p>{{ template.index }}</p>
                    </td>
                    <td class="px-6 py-4">
                      <p class="text-xs text-gray-900 break-all">
                        {{ template.badgeTemplateHash }}
                      </p>
                    </td>
                    <td class="px-6 py-4">
                      <a
                        :href="
                          utils.ipfs.getUrlFromCID(
                            utils.ipfs.hashToCID(
                              template.badgeTemplateHash.replace('0x', '')
                            )
                          )
                        "
                        target="_blank"
                      >
                        <p class="text-xs text-blue-600 underline break-all">
                          {{
                            utils.ipfs.getUrlFromCID(
                              utils.ipfs.hashToCID(
                                template.badgeTemplateHash.replace('0x', '')
                              )
                            )
                          }}
                        </p></a
                      >
                    </td>
                    <td class="px-6 py-4">
                      <p class="text-2xl text-gray-900 font-bold break-all">
                        {{
                          events.filter(
                            (event) =>
                              event.data.templateHash ==
                              template.badgeTemplateHash
                          ).length
                        }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <p class="text-gray-600 mr-3 mb-3">Last events</p>
      <div class="grid grid-cols-3 gap-4">
        <div
          v-for="event in events.slice(0, 9)"
          :key="event"
          class="bg-white shadow-lg rounded-xl p-5 mb-3"
        >
          <p class="uppercase font-bold">{{ event.type }}</p>
          <p class="text-gray-600 text-xs">
            {{ event.created.split('.')[0].replace('T', ' ') }}
          </p>
          <hr class="my-2" />
          <div v-if="event.type == 'open_badge_p2p_badge_created'">
            <p class="text-xs mb-1">
              IPFS :
              <a
                :href="
                  utils.ipfs.getUrlFromCID(
                    utils.ipfs.hashToCID(
                      `${event.data.templateHash}`.replace('0x', '')
                    )
                  )
                "
                target="_blank"
              >
                <span class="text-xs text-blue-600 underline break-all">
                  {{
                    utils.ipfs.getUrlFromCID(
                      utils.ipfs.hashToCID(
                        `${event.data.templateHash}`.replace('0x', '')
                      )
                    )
                  }}
                </span></a
              >
            </p>
            <p class="text-xs">Department : {{ event.data.department }}</p>
          </div>
        </div>
      </div>
    </div></admin-checker
  >
</template>

<script>
import { ref, inject, watch } from 'vue';
import { DoughnutChart, BarChart, LineChart } from 'vue-chart-3';
export default {
  components: { DoughnutChart, BarChart, LineChart },
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const events = ref([]);
    const p2pTemplates = ref([]);
    const chartObCreationThroughTimeData = ref({});
    const fromDate = ref(new Date('2022-04-29'));
    const fromCleanDate = ref(new Date('2022-04-29'));
    const toDate = ref(
      new Date(new Date().toISOString().split('T')[0] + 'T21:59:59.000Z')
    );
    const toCleanDate = ref(
      new Date(new Date().toISOString().split('T')[0] + 'T23:59:59.000Z')
    );

    utils.mydid.getP2PBadgeTemplateList().then((templateList) => {
      p2pTemplates.value = templateList;
    });

    function getStatistics() {
      api
        .getStatistics(
          fromCleanDate.value.toISOString(),
          toCleanDate.value.toISOString()
        )
        .then((response) => {
          events.value = response.data;
          computeObCreationChart();
        });
    }

    watch(fromDate, (date) => {
      fromCleanDate.value = new Date(
        date.toLocaleDateString().split('/').reverse().join('-')
      );
    });

    watch(toDate, (date) => {
      toCleanDate.value = new Date(
        date.toLocaleDateString().split('/').reverse().join('-') +
          'T23:59:59.000Z'
      );
    });

    getStatistics();

    function computeObCreationChart() {
      chartObCreationThroughTimeData.value = {};
      const endDate = new Date(toCleanDate.value);
      const startDate = new Date(fromCleanDate.value);
      const dateRange = Math.ceil(
        Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)
      );
      for (let i = 0; i < dateRange; i++) {
        let nextDate = new Date(startDate.toISOString());
        nextDate.setDate(nextDate.getDate() + i);
        chartObCreationThroughTimeData.value[
          nextDate.toISOString().split('T')[0]
        ] = 0;
      }

      for (let ev of events.value) {
        chartObCreationThroughTimeData.value[ev.created.split('T')[0]] =
          chartObCreationThroughTimeData.value[ev.created.split('T')[0]] + 1;
      }
    }

    return {
      events,
      p2pTemplates,
      chartObCreationThroughTimeData,
      getStatistics,
      utils,
      fromDate,
      toDate,
    };
  },
};
</script>
