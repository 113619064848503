<template>
  <div class="bg-red-500 cursor-pointer" v-if="showBanner">
    <div
      v-if="currentIssuerDid && !isCurrentChaindIdValid"
      class="flex justify-center items-center"
    >
      <p class="text-white text-xs py-2">
        Please connect to
        <span class="font-black">{{ chainIdRequiredName }}</span>
        network with your wallet app
      </p>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    const walletConnect = inject('walletConnect');

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const isCurrentChaindIdValid = computed(
      () => store.getters.isCurrentChaindIdValid
    );

    const chainIdRequiredName = ref(
      {
        '0x38': 'BNB Smart Chain',
        '0x61': 'BSC Testnet',
      }[process.env.VUE_APP_CHAIN_ID]
    );

    watch(walletConnect, () => {
      retrieveChainId();
    });

    const route = useRoute();
    const showBanner = ref(true);
    watch(
      () => route.path,
      () => {
        showBanner.value =
          ['/signin', '/create-issuer', '/protected'].indexOf(route.path) == -1;
      }
    );

    function retrieveChainId() {
      if (!walletConnect.value) return;
      const session = walletConnect.value.getSession();
      if (session) {
        store.commit(
          'setCurrentChainId',
          session.requiredNamespaces.eip155.chains[0].split(':')[1]
        );
      }
    }

    watch(() => currentIssuerDid.value, retrieveChainId);

    return {
      currentIssuerDid,
      showBanner,
      isCurrentChaindIdValid,
      chainIdRequiredName,
    };
  },
};
</script>
