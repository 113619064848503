<template>
  <div v-if="show" class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5">
    <div v-if="confirmLoading">
      <div class="flex items-center">
        <custom-loader color="#111827" size="30px" class="mr-3">
        </custom-loader>
        <p class="text-gray-600 text-md font-bold">
          Waiting for WalletConnect confirmation
        </p>
      </div>
    </div>
    <div v-if="transactionHash">
      <p class="text-gray-600 text-md">
        <i class="fas fa-paper-plane mr-2"></i
        ><span class="font-bold">Transaction created with hash :</span>
        {{ transactionHash }}
        <a :href="scanUrlPrefix + transactionHash" target="_blank"
          ><i
            class="fas fa-external-link-alt text-gray-700 text-xxs ml-1 mb-0.5"
          ></i
        ></a>
      </p>
    </div>
    <div v-if="transactionLoading">
      <div class="flex items-center">
        <custom-loader color="#111827" size="30px" class="mr-3">
        </custom-loader>
        <p class="text-gray-600 text-md font-bold mr-2">
          Waiting for transaction completion
        </p>
        <span class="text-gray-600 text-xs">
          (can take several minutes depending on the operation)
        </span>
      </div>
    </div>
    <div v-if="transactionCompleted">
      <p class="text-gray-600 text-md font-bold">
        <i class="fas fa-check-circle mr-2"></i>Transaction completed
      </p>
    </div>
    <div v-if="errorMessage">
      <p class="text-gray-600 text-md font-bold mb-1">
        <i class="fas fa-exclamation-circle mr-2"></i>An error occured :
      </p>
      <p class="bg-red-200 h-16 overflow-y-scroll px-2 py-1">
        <span class="text-xxs leading-none my-4">{{ errorMessage }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { inject, ref, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup(props, context) {
    const api = inject('api');
    const utils = inject('utils');
    const walletConnect = inject('walletConnect');
    const store = useStore();

    const currentAddress = computed(() => store.getters.getCurrentAddress);

    const transactionHash = ref(null);
    const errorMessage = ref(null);
    const confirmLoading = ref(false);
    const transactionLoading = ref(false);
    const transactionCompleted = ref(false);
    const show = ref(false);
    const scanUrlPrefix = ref(process.env.VUE_APP_SCAN_URL_PREFIX);

    async function createTransaction(method, ...args) {
      transactionHash.value = false;
      errorMessage.value = false;
      transactionLoading.value = false;
      transactionCompleted.value = false;

      confirmLoading.value = true;
      show.value = true;

      try {
        const rawTransaction = await utils.mydid.createMydidTransaction(
          currentAddress.value,
          method,
          ...args
        );

        if (!walletConnect.value)
          throw new Error('No wallet connected in WalletConnect');
        const session = walletConnect.value.getSession();
        transactionHash.value = await walletConnect.value.connector.request({
          topic: session.topic,
          chainId: `eip155:${Number(process.env.VUE_APP_CHAIN_ID)}`,
          request: {
            id: 1,
            jsonrpc: '2.0',
            method: 'eth_sendTransaction',
            params: [rawTransaction],
          },
        });

        confirmLoading.value = false;

        api.addTransaction(
          transactionHash.value,
          method,
          'DID:SDI:' + currentAddress.value
        );

        transactionLoading.value = true;
        await utils.waitForTransactionConfirmed(transactionHash.value);
        transactionLoading.value = false;
        transactionCompleted.value = true;
        context.emit('transactionCompleted');
      } catch (e) {
        console.log(e);
        errorMessage.value = e;
        confirmLoading.value = false;
        transactionLoading.value = false;
        context.emit('transactionFailed');
        return;
      }
    }

    async function createSylTransaction(method, ...args) {
      transactionHash.value = false;
      errorMessage.value = false;
      transactionLoading.value = false;
      transactionCompleted.value = false;

      confirmLoading.value = true;
      show.value = true;

      try {
        const rawTransaction = await utils.syl.createSylTransaction(
          currentAddress.value,
          method,
          ...args
        );

        if (!walletConnect.value)
          throw new Error('No wallet connected in WalletConnect');
        const session = walletConnect.value.getSession();
        console.log(walletConnect.value);
        console.log(session);

        transactionHash.value = await walletConnect.value.connector.request({
          topic: session.topic,
          chainId: `eip155:${Number(process.env.VUE_APP_CHAIN_ID)}`,
          request: {
            id: 1,
            jsonrpc: '2.0',
            method: 'eth_sendTransaction',
            params: [rawTransaction],
          },
        });

        confirmLoading.value = false;

        api.addTransaction(
          transactionHash.value,
          method,
          'DID:SDI:' + currentAddress.value
        );

        transactionLoading.value = true;
        await utils.waitForTransactionConfirmed(transactionHash.value);
        transactionLoading.value = false;
        transactionCompleted.value = true;
        context.emit('transactionCompleted');
      } catch (e) {
        console.log(e);
        errorMessage.value = e;
        confirmLoading.value = false;
        transactionLoading.value = false;
        context.emit('transactionFailed');
        return;
      }
    }

    return {
      transactionHash,
      errorMessage,
      confirmLoading,
      transactionLoading,
      transactionCompleted,
      show,
      scanUrlPrefix,
      createTransaction,
      createSylTransaction,
    };
  },
};
</script>
