<template>
  <div class="flex flex-col">
    <div class="mt-1 py-2 px-4 mb-1">
      <router-link to="/"
        ><div class="flex">
          <img
            :src="require('/assets/images/small_mydid.png')"
            alt=""
            class="w-5 self-center mr-2"
          /><img
            :src="require('/assets/images/mydid.png')"
            alt=""
            class="w-20 self-center"
          /></div
      ></router-link>
    </div>
    <div class="border-t border-b border-white border-opacity-20"></div>
    <div v-if="currentIssuerDid" class="bg-primary-light p-3 -mb-1">
      <p class="text-white text-sm font-bold mx-2 mb-4 mt-2">
        Verifiable credential
      </p>
      <router-link to="/vc/manage-vc"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Manage verifiable credentials
        </p></router-link
      >
      <router-link to="/vc/get-public-ref"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>See public ref list
        </p></router-link
      >
      <router-link to="/vc/update-vc-status"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Update vc status
        </p></router-link
      >
    </div>
    <div
      v-if="currentIssuerDid"
      class="bg-primary-light border-t border-b border-white border-opacity-20 p-3"
    >
      <p class="text-white text-sm font-bold mx-2 mb-4 mt-2">Open badge</p>
      <router-link to="/ob/manage-templates"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Manage templates
        </p></router-link
      >
      <router-link to="/ob/issue-badge-vc"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Issue badge V3
        </p></router-link
      >
    </div>
    <div
      v-if="currentIssuerDid"
      class="bg-primary-light border-t border-b border-white border-opacity-20 p-3"
    >
      <p class="text-white text-sm font-bold mx-2 mb-4 mt-2">Universe</p>
      <router-link to="/universe/manage-universe"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Manage universe
        </p></router-link
      >
      <router-link to="/universe/manage-app-universe-list"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Manage app universe list
        </p></router-link
      >
    </div>
    <div
      v-if="currentIssuerDid"
      class="bg-primary-light border-t border-b border-white border-opacity-20 p-3"
    >
      <p class="text-white text-sm font-bold mx-2 mb-4 mt-2">Issuers</p>
      <router-link to="/issuer/change-issuer-category"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Change issuer category
        </p></router-link
      >
      <router-link to="/users/set-attribute"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Set attribute
        </p></router-link
      >
      <router-link to="/users/change-controller"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Change controller
        </p></router-link
      >
    </div>
    <div
      v-if="currentIssuerDid && isAdmin"
      class="bg-primary-light border-t border-b border-white border-opacity-20 p-3"
    >
      <p class="text-white text-sm font-bold mx-2 mb-4 mt-2">Admin</p>
      <router-link to="/users/create-user"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Create user
        </p></router-link
      >
      <router-link to="/admin/create-issuer"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Create issuer
        </p></router-link
      >
      <router-link to="/admin/create-verifier"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Create verifier
        </p></router-link
      >
      <router-link to="/admin/get-issuer-list"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Get issuer list
        </p></router-link
      >
      <router-link to="/admin/withdraw-fees"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>Withdraw fees
        </p></router-link
      >
      <router-link to="/admin/see-all-universes"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>See validated universes
        </p></router-link
      >
      <router-link to="/admin/see-p2p-templates"
        ><p class="text-gray-300 text-sm mx-2 my-2">
          <i class="fas fa-caret-right mr-3"></i>See P2P badge templates
        </p></router-link
      >
    </div>
    <div class="flex-grow"></div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();

    const isAdmin = ref(false);

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    if (currentIssuerDid.value) {
      utils.mydid
        .isAdmin(currentIssuerDid.value.replace('DID:SDI:', ''))
        .then((status) => (isAdmin.value = status));
    }

    return {
      isAdmin,
      currentIssuerDid,
    };
  },
};
</script>
