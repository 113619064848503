<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Create user
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        users
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-5">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Create DID document
      for a specific ETH address.
    </p>
    <p class="text-gray-600 mr-3 mb-3">User address :</p>
    <textarea
      type="text"
      placeholder="Address value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="userAddress"
    />
    <p class="text-gray-600 mr-3 mb-3">Authentication key :</p>
    <textarea
      type="text"
      placeholder="Key value"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="authenticationKey"
    />
    <p class="text-gray-600 mr-3 mb-3">Service :</p>
    <textarea
      type="text"
      placeholder="Service name"
      rows="1"
      class="w-full outline-none bg-gray-200 rounded-lg border-none p-3 mb-5"
      v-model="serviceName"
    />
    <button
      class="
        text-sm
        bg-primary-light
        text-white
        font-bold
        inline-block
        rounded-lg
        cursor-pointer
        py-3
        px-5
        mb-3
        mr-5  
      "
      :class="{
        'pointer-events-none opacity-50': buttonDisable
      }"
      @click="
        () => {
          buttonDisable = true;
          transactionHandlerRef.createTransaction(
            'createUserDID',
            userAddress,
            utils.asciiToHex(serviceName),
            authenticationKey
          );
        }
      "
    >
      Create user
    </button>
    <transaction-handler
      ref="transactionHandlerRef"
      @transactionCompleted="buttonDisable = false"
      @transactionFailed="buttonDisable = false"
    ></transaction-handler>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const utils = inject('utils');

    const userAddress = ref('');
    const authenticationKey = ref('');
    const serviceName = ref('myDid admin dashboard');
    const buttonDisable = ref(false);
    const transactionHandlerRef = ref();

    return {
      userAddress,
      authenticationKey,
      serviceName,
      utils,
      buttonDisable,
      transactionHandlerRef
    };
  }
};
</script>
