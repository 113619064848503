<template>
  <div class="my-10 mx-20">
    <div class="flex items-center mb-5">
      <p class="text-xl font-bold text-gray-600">
        <router-link to="/admin"
          ><i class="fas fa-arrow-left text-lg mr-3"></i></router-link
        >Manage universe
      </p>
      <p
        class="text-xxs font-medium text-gray-600 bg-gray-200 rounded-2xl py-1 px-2 ml-auto"
      >
        universe
      </p>
    </div>
    <hr class="border-t-2 border-gray-600 border-opacity-20 w-full mb-5" />
    <p class="text-sm text-gray-600 mb-5">
      <i class="fas fa-info-circle text-gray-700 mr-2"></i>Create or update
      universe for current issuer.
    </p>
    <div v-if="universeLoading" class="flex justify-center mb-10">
      <custom-loader color="#111827" size="30px" class="mr-3"> </custom-loader>
    </div>
    <div v-else>
      <div v-if="universe" class="flex flex-col mb-10">
        <div class="bg-white rounded-lg shadow-md p-5 mb-5">
          <p class="font-bold text-gray-600 pb-3">
            <i class="fas fa-info mr-2"></i>Universe information
          </p>
          <div class="flex items-center gap-5">
            <div>
              <img :src="universe.smallLogoPicture" class="w-32 h-32" />
            </div>
            <div>
              <qrcode-vue
                v-if="universeDeepLink"
                :value="universeDeepLink"
                :size="100"
                level="H"
                class="bg-white"
              />
            </div>
            <div class="flex-col space-y-2">
              <p class="text-gray-600 text-sm">
                Name : {{ universe.localeNames.en }}
              </p>
              <p class="text-gray-600 text-sm">
                Description : {{ universe.localeDescriptions.en }}
              </p>
              <p class="text-gray-600 text-sm">
                Email : {{ universe.contactEmail }}
              </p>
              <p class="text-gray-600 text-sm mr-3">
                IPFS :
                <a :href="universeIpfsUrl" class="underline" target="_blank">{{
                  universeIpfsUrl
                }}</a>
              </p>
              <p v-if="universeDeepLink" class="text-gray-600 mr-3">
                Universe installation link :
                <a :href="universeDeepLink" class="underline" target="_blank">{{
                  universeDeepLink
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-sm text-gray-700 italic mb-5">
          No universe found for this issuer
        </p>
      </div>
      <p class="text-gray-600 text-lg font-bold mb-5">
        <i class="fas fa-cog mr-3"></i
        >{{ universe ? 'Update universe' : 'Create universe' }}
      </p>
      <method-price-handler
        v-if="!universe"
        method="createIssuerUniversePrice"
        class="mb-2"
      ></method-price-handler>
      <div v-if="universe">
        <button
          class="text-sm bg-primary-light text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
          :class="{
            'pointer-events-none opacity-50': !universeData || buttonDisable,
          }"
          @click="updateUniverse"
        >
          Update universe
        </button>
      </div>
      <div v-else>
        <button
          class="text-sm bg-primary-light text-white font-bold rounded-lg cursor-pointer py-3 px-5 mb-3 mr-5"
          :class="{
            'pointer-events-none opacity-50': !universeData || buttonDisable,
          }"
          @click="createUniverse"
        >
          Create universe
        </button>
      </div>
      <div
        v-if="errorMessage"
        class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
      >
        <p class="text-gray-600 text-md font-bold mb-1">
          <i class="fas fa-exclamation-circle mr-2"></i>An error occured :
        </p>
        <p class="bg-red-200 h-16 overflow-y-scroll px-2 py-1">
          <span class="text-xxs leading-none my-4">{{ errorMessage }}</span>
        </p>
      </div>
      <div
        v-if="ipfsLoading"
        class="bg-white rounded-lg shadow-md flex-col space-y-4 p-5"
      >
        <div class="flex items-center">
          <custom-loader color="#111827" size="30px" class="mr-3">
          </custom-loader>
          <p class="text-gray-600 text-md font-bold">Pushing on IPFS</p>
        </div>
      </div>
      <transaction-handler
        @transactionCompleted="onTransactionCompleted"
        @transactionFailed="buttonDisable = false"
        ref="transactionHandlerRef"
      ></transaction-handler>
      <!-- <p
        v-if="universeData"
        class="bg-white rounded-lg shadow-lg overflow-y-scroll h-32 p-3 my-5"
      >
        <span class="text-xxs whitespace-pre leading-none my-4">{{
          JSON.stringify(universeData, null, 4)
        }}</span>
      </p> -->
      <div class="grid grid-cols-2 gap-5">
        <div>
          <p class="uppercase my-3">Information</p>
          <div class="grid grid-cols-2 gap-5">
            <div>
              <p class="font-bold mb-3">Universe name*</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-12 mb-1"
                  v-model="universeData.localeNames.en"
                /><country-flag
                  country="gb"
                  size="normal"
                  class="absolute left-3 top-1"
                />
              </div>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-12 mb-3"
                  v-model="universeData.localeNames.fr"
                /><country-flag
                  country="fr"
                  size="normal"
                  class="absolute left-3 top-1"
                />
              </div>
              <p class="font-bold mb-3">Splash Page Title</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-12 mb-1"
                  v-model="universeData.translations.en.splash_page_title"
                /><country-flag
                  country="gb"
                  size="normal"
                  class="absolute left-3 top-1"
                />
              </div>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-12 mb-3"
                  v-model="universeData.translations.fr.splash_page_title"
                /><country-flag
                  country="fr"
                  size="normal"
                  class="absolute left-3 top-1"
                />
              </div>
            </div>
            <div>
              <p class="font-bold mb-3">Universe description*</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-12 mb-1"
                  v-model="universeData.localeDescriptions.en"
                /><country-flag
                  country="gb"
                  size="normal"
                  class="absolute left-3 top-1"
                />
              </div>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-12 mb-5"
                  v-model="universeData.localeDescriptions.fr"
                /><country-flag
                  country="fr"
                  size="normal"
                  class="absolute left-3 top-1"
                />
              </div>
            </div>
          </div>
          <p class="uppercase mb-3">Pictures</p>
          <div class="grid grid-cols-2 gap-5 mb-5">
            <div>
              <p class="font-bold mb-2">Cover picture*</p>
              <img
                :src="universeData.coverPicture"
                class="h-16 border border-gray-300 bg-gray-100 cursor-pointer mb-2"
                @click="$refs.coverPictureInput.click()"
              />
              <input
                type="file"
                @change="
                  (event) => {
                    if (event.target.files[0]['type'] != 'image/png') {
                      createToast(`Please select an PNG file`, {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        toastBackgroundColor: '#111827',
                      });
                      return;
                    }
                    const reader = createFileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = async (e) => {
                      universeData.coverPicture = e.target.result;
                    };
                  }
                "
                ref="coverPictureInput"
                class="hidden"
              />
              <p class="font-bold mb-2">Opposite logo picture</p>
              <img
                :src="universeData.oppositeLogoPicture"
                class="h-16 border border-gray-300 bg-gray-100 cursor-pointer mb-2"
                @click="$refs.oppositeLogoPictureInput.click()"
              />
              <input
                type="file"
                @change="
                  (event) => {
                    if (event.target.files[0]['type'] != 'image/png') {
                      createToast(`Please select an PNG file`, {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        toastBackgroundColor: '#111827',
                      });
                      return;
                    }
                    const reader = createFileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = async (e) => {
                      universeData.oppositeLogoPicture = e.target.result;
                    };
                  }
                "
                ref="oppositeLogoPictureInput"
                class="hidden"
              />
              <p class="font-bold mb-2">Small logo gif</p>
              <img
                :src="universeData.smallLogoGif"
                class="h-16 border border-gray-300 bg-gray-100 cursor-pointer mb-2"
                @click="$refs.smallLogoGifInput.click()"
              />
              <input
                type="file"
                @change="
                  (event) => {
                    if (event.target.files[0]['type'] != 'image/gif') {
                      createToast(`Please select an GIF file`, {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        toastBackgroundColor: '#111827',
                      });
                      return;
                    }
                    const reader = createFileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = async (e) => {
                      universeData.smallLogoGif = e.target.result;
                    };
                  }
                "
                ref="smallLogoGifInput"
                class="hidden"
              />
              <p class="font-bold mb-2">Splash screen picture</p>
              <img
                v-if="universeData.splashScreenPicture"
                :src="universeData.splashScreenPicture"
                class="h-16 border border-gray-300 bg-gray-100 cursor-pointer mb-2"
                @click="$refs.splashScreenPictureInput.click()"
              />
              <div
                v-else
                class="h-16 w-16 border border-gray-300 bg-gray-100 flex justify-center items-center cursor-pointer mb-2"
                @click="$refs.splashScreenPictureInput.click()"
              >
                <p><i class="fas fa-file-upload text-2xl text-gray-500"></i></p>
              </div>
              <input
                type="file"
                @change="
                  (event) => {
                    if (event.target.files[0]['type'] != 'image/png') {
                      createToast(`Please select an PNG file`, {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        toastBackgroundColor: '#111827',
                      });
                      return;
                    }
                    const reader = createFileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = async (e) => {
                      universeData.splashScreenPicture = e.target.result;
                    };
                  }
                "
                ref="splashScreenPictureInput"
                class="hidden"
              />
            </div>
            <div>
              <p class="font-bold mb-2">Logo picture*</p>
              <img
                :src="universeData.logoPicture"
                class="h-16 border border-gray-300 bg-gray-100 cursor-pointer mb-2"
                @click="$refs.logoPictureInput.click()"
              />
              <input
                type="file"
                @change="
                  (event) => {
                    if (event.target.files[0]['type'] != 'image/png') {
                      createToast(`Please select an PNG file`, {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        toastBackgroundColor: '#111827',
                      });
                      return;
                    }
                    const reader = createFileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = async (e) => {
                      universeData.logoPicture = e.target.result;
                    };
                  }
                "
                ref="logoPictureInput"
                class="hidden"
              />
              <p class="font-bold mb-2">Small logo picture*</p>
              <img
                :src="universeData.smallLogoPicture"
                class="h-16 border border-gray-300 bg-gray-100 cursor-pointer mb-2"
                @click="$refs.smallLogoPictureInput.click()"
              />
              <input
                type="file"
                @change="
                  (event) => {
                    if (event.target.files[0]['type'] != 'image/png') {
                      createToast(`Please select an PNG file`, {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        toastBackgroundColor: '#111827',
                      });
                      return;
                    }
                    const reader = createFileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = async (e) => {
                      universeData.smallLogoPicture = e.target.result;
                    };
                  }
                "
                ref="smallLogoPictureInput"
                class="hidden"
              />
              <p class="font-bold mb-2">Home icon svg*</p>
              <img
                :src="universeData.homeIconSvg"
                class="h-16 border border-gray-300 bg-gray-100 cursor-pointer mb-2"
                @click="$refs.homeIconSvgInput.click()"
              />
              <input
                type="file"
                @change="
                  (event) => {
                    if (event.target.files[0]['type'] != 'image/svg') {
                      createToast(`Please select an PNG file`, {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        toastBackgroundColor: '#111827',
                      });
                      return;
                    }
                    const reader = createFileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = async (e) => {
                      universeData.homeIconSvg = e.target.result;
                    };
                  }
                "
                ref="homeIconSvgInput"
                class="hidden"
              />
              <p class="font-bold mb-3">Hide background decoration*</p>
              <select
                required
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-1"
                v-model="universeData.hideBackgroundDecoration"
              >
                <option disabled selected value="">
                  Please select an option
                </option>
                <option :value="false">False</option>
                <option :value="true">True</option>
                >
              </select>
            </div>
          </div>
          <p class="uppercase my-3">Socials</p>
          <div class="grid grid-cols-2 gap-5">
            <div>
              <p class="font-bold mb-3">Contact email*</p>
              <textarea
                type="text"
                placeholder="Value"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-1"
                v-model="universeData.contactEmail"
              />
              <p class="font-bold mb-3">Twitter user ID</p>
              <textarea
                type="text"
                placeholder="Value"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-3"
                v-model="universeData.twitterUserId"
              />
            </div>
            <div>
              <p class="font-bold mb-3">RSS feed URL</p>
              <textarea
                type="text"
                placeholder="Value"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-1"
                v-model="universeData.rssUrl"
              />
              <p class="font-bold mb-3">Badges endpoint</p>
              <textarea
                type="text"
                placeholder="Value"
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-5"
                v-model="universeData.badgesEndpoint"
              />
            </div>
          </div>
          <p class="uppercase my-3">Theme</p>
          <div class="grid grid-cols-2 gap-5">
            <div>
              <p class="font-bold mb-3">Primary color*</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-20 mb-1"
                  v-model="universePrimaryColor"
                />
                <div class="absolute left-3 top-2.5">
                  <color-picker
                    format="hex"
                    v-model:pureColor="universePrimaryColor"
                  />
                </div>
              </div>
              <p class="font-bold mb-3">Secondary color*</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-20 mb-1"
                  v-model="universeSecondaryColor"
                />
                <div class="absolute left-3 top-2.5">
                  <color-picker
                    format="hex"
                    v-model:pureColor="universeSecondaryColor"
                  />
                </div>
              </div>
              <p class="font-bold mb-3">First Twitter carousel color*</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-20 mb-1"
                  v-model="universeFirstTwitterCarouselColor"
                />
                <div class="absolute left-3 top-2.5">
                  <color-picker
                    format="hex"
                    v-model:pureColor="universeFirstTwitterCarouselColor"
                  />
                </div>
              </div>
              <p class="font-bold mb-3">Status bar white foreground*</p>
              <select
                required
                rows="1"
                class="w-full outline-none rounded-sm border border-gray-300 p-3 mb-1"
                v-model="universeData.themes[0].statusBarWhiteForeground"
              >
                <option disabled selected value="">
                  Please select an option
                </option>
                <option :value="false">False</option>
                <option :value="true">True</option>
                >
              </select>
            </div>
            <div>
              <p class="font-bold mb-3">Primary light color*</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-20 mb-1"
                  v-model="universePrimaryLightColor"
                />
                <div class="absolute left-3 top-2.5">
                  <color-picker
                    format="hex"
                    v-model:pureColor="universePrimaryLightColor"
                  />
                </div>
              </div>
              <p class="font-bold mb-3">Secondary light color*</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-20 mb-1"
                  v-model="universeSecondaryLightColor"
                />
                <div class="absolute left-3 top-2.5">
                  <color-picker
                    format="hex"
                    v-model:pureColor="universeSecondaryLightColor"
                  />
                </div>
              </div>
              <p class="font-bold mb-3">Second Twitter carousel color*</p>
              <div class="relative">
                <textarea
                  type="text"
                  placeholder="Value"
                  rows="1"
                  class="w-full outline-none rounded-sm border border-gray-300 p-3 pl-20 mb-1"
                  v-model="universeSecondTwitterCarouselColor"
                />
                <div class="absolute left-3 top-2.5">
                  <color-picker
                    format="hex"
                    v-model:pureColor="universeSecondTwitterCarouselColor"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 rounded-lg shadow-lg p-5">
          <div class="flex justify-center gap-4 mb-3">
            <div
              @click="
                {
                  universeDisplayLang = 'en';
                  pushUniverseDisplayLang();
                }
              "
              class="border border-opacity-40 rounded-lg cursor-pointer px-3 py-1"
              :class="{ 'bg-gray-200': universeDisplayLang == 'en' }"
            >
              <country-flag country="gb" size="normal" />
            </div>
            <div
              @click="
                {
                  universeDisplayLang = 'fr';
                  pushUniverseDisplayLang();
                }
              "
              class="border border-opacity-40 rounded-lg cursor-pointer px-3 py-1"
              :class="{ 'bg-gray-200': universeDisplayLang == 'fr' }"
            >
              <country-flag country="fr" size="normal" />
            </div>
          </div>
          <div
            ref="iframeWrapper"
            class="relative w-full"
            style="height: 1000px"
          >
            <iframe
              class="w-full h-full absolute top-0 left-0"
              id="inlineFrameExample"
              title="Inline Frame Example"
              :src="universeToolUrl"
              ref="iframe"
              scrolling="no"
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import Ajv from 'ajv';
import universeSchema from '/assets/universe-schema.json';
import defaultUniverseData from '/assets/default_universe.json';
import { createToast } from 'mosha-vue-toastify';

export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const store = useStore();

    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);

    const iframe = ref();
    const iframeWrapper = ref();
    const selectedFile = ref(null);
    const fileMessage = ref('Please select a file');
    const universeToolUrl = ref(process.env.VUE_APP_UNIVERSE_TOOL_URL);

    const universe = ref(null);
    const universeIpfsUrl = ref(null);
    const universeLoading = ref(true);
    const universeData = ref(defaultUniverseData);
    const universeIPFSHash = ref('');
    const universeDisplayLang = ref('en');
    const universeDeepLink = ref(null);

    const transactionHandlerRef = ref();
    const buttonDisable = ref(false);
    const errorMessage = ref(null);
    const ipfsLoading = ref(false);

    const universePrimaryColor = ref(
      '#' + universeData.value.themes[0].primaryColor.substring(2, 8)
    );
    const universePrimaryLightColor = ref(
      '#' + universeData.value.themes[0].primaryLightColor.substring(2, 8)
    );
    const universeSecondaryColor = ref(
      '#' + universeData.value.themes[0].secondaryColor.substring(2, 8)
    );
    const universeSecondaryLightColor = ref(
      '#' + universeData.value.themes[0].secondaryLightColor.substring(2, 8)
    );
    const universeFirstTwitterCarouselColor = ref(
      '#' +
        universeData.value.themes[0].firstTwitterCarouselColor.substring(2, 8)
    );
    const universeSecondTwitterCarouselColor = ref(
      '#' +
        universeData.value.themes[0].secondTwitterCarouselColor.substring(2, 8)
    );

    const ajv = new Ajv({ strict: false, allErrors: true });
    const universeSchemaValidate = ajv.compile(universeSchema);

    const eventHandler = (event) => {
      if (event.origin == process.env.VUE_APP_UNIVERSE_TOOL_URL_DOMAIN) {
        try {
          const eventData = JSON.parse(event.data);
          if (eventData.type == 'ready') {
            universeDisplayLang.value = eventData.data.language;
            pushUniverseData();
          }
        } catch (e) {
          console.log(`Can't parse event from iframe`);
        }
      }
    };
    window.addEventListener('message', eventHandler, false);
    onUnmounted(() => {
      window.removeEventListener('message', eventHandler);
    });

    function getUniverseDLink() {
      api
        .getUniverseDLink(
          currentIssuerDid.value.replace('DID:SDI:', ''),
          universeIpfsUrl.value.split('ipfs/')[1]
        )
        .then((res) => {
          universeDeepLink.value = res.data.shortLink;
        });
    }

    function pushUniverseData() {
      if (iframe.value && iframe.value.contentWindow) {
        const eventData = {
          type: 'universe_data',
          data: universeData.value,
        };
        iframe.value.contentWindow.postMessage(JSON.stringify(eventData), '*');
      }
    }

    function pushUniverseDisplayLang() {
      if (iframe.value && iframe.value.contentWindow) {
        const eventData = {
          type: 'language_update',
          data: universeDisplayLang.value,
        };
        iframe.value.contentWindow.postMessage(JSON.stringify(eventData), '*');
      }
    }

    watch(
      universeData,
      () => {
        pushUniverseData();
      },
      { deep: true }
    );

    async function getUniverse() {
      if (!currentIssuerDid.value) return;

      universeLoading.value = true;
      utils.mydid
        .getUniverse(currentIssuerDid.value.replace('DID:SDI:', ''))
        .then(async (res) => {
          if (
            res ==
            '0x0000000000000000000000000000000000000000000000000000000000000000'
          ) {
            universeLoading.value = false;
            return;
          }

          try {
            universe.value = await utils.ipfs.getJsonDataFromCID(
              utils.ipfs.hashToCID(res)
            );
            universeData.value = JSON.parse(JSON.stringify(universe.value));
            universeIpfsUrl.value = utils.ipfs.getUrlFromCID(
              utils.ipfs.hashToCID(res)
            );
            universePrimaryColor.value =
              '#' + universeData.value.themes[0].primaryColor.substring(2, 8);
            universePrimaryLightColor.value =
              '#' +
              universeData.value.themes[0].primaryLightColor.substring(2, 8);
            universeSecondaryColor.value =
              '#' + universeData.value.themes[0].secondaryColor.substring(2, 8);
            universeSecondaryLightColor.value =
              '#' +
              universeData.value.themes[0].secondaryLightColor.substring(2, 8);
            universeFirstTwitterCarouselColor.value =
              '#' +
              universeData.value.themes[0].firstTwitterCarouselColor.substring(
                2,
                8
              );
            universeSecondTwitterCarouselColor.value =
              '#' +
              universeData.value.themes[0].secondTwitterCarouselColor.substring(
                2,
                8
              );
            getUniverseDLink();
          } catch (err) {
            universe.value = null;
            console.log(err);
          }

          if (!universeSchemaValidate(universe.value)) {
            universe.value = null;
          }

          universeLoading.value = false;
        });
    }

    async function updateUniverse() {
      errorMessage.value = null;
      if (!universeSchemaValidate(universeData.value)) {
        errorMessage.value = 'Bad format for universe JSON.';
        return;
      }
      buttonDisable.value = true;
      ipfsLoading.value = true;
      universeIPFSHash.value =
        '0x' +
        (await utils.ipfs.uploadIpfsJsonData(
          JSON.stringify(universeData.value)
        ));

      ipfsLoading.value = false;

      api.addIpfsData({
        context: 'universe-update',
        hash: universeIPFSHash.value,
        data: universeData.value,
      });

      transactionHandlerRef.value.createTransaction(
        'updateUniverse',
        universeIPFSHash.value
      );
    }

    async function createUniverse() {
      errorMessage.value = null;
      if (!universeSchemaValidate(universeData.value)) {
        errorMessage.value = 'Bad format for universe JSON.';
        return;
      }
      buttonDisable.value = true;
      ipfsLoading.value = true;
      universeIPFSHash.value =
        '0x' +
        (await utils.ipfs.uploadIpfsJsonData(
          JSON.stringify(universeData.value)
        ));

      ipfsLoading.value = false;

      api.addIpfsData({
        context: 'universe-create',
        hash: universeIPFSHash.value,
        data: universeData.value,
      });

      transactionHandlerRef.value.createTransaction(
        'createUniverse',
        universeIPFSHash.value
      );
    }

    function onTransactionCompleted() {
      buttonDisable.value = false;
      getUniverse();
    }

    function createFileReader() {
      return new FileReader();
    }

    watch(universePrimaryColor, () => {
      if (universeData.value && universeData.value.themes)
        universeData.value.themes[0].primaryColor =
          'ff' + universePrimaryColor.value.replace('#', '');
    });
    watch(universePrimaryLightColor, () => {
      if (universeData.value && universeData.value.themes)
        universeData.value.themes[0].primaryLightColor =
          'ff' + universePrimaryLightColor.value.replace('#', '');
    });
    watch(universeSecondaryColor, () => {
      if (universeData.value && universeData.value.themes)
        universeData.value.themes[0].secondaryColor =
          'ff' + universeSecondaryColor.value.replace('#', '');
    });
    watch(universeSecondaryLightColor, () => {
      if (universeData.value && universeData.value.themes)
        universeData.value.themes[0].secondaryLightColor =
          'ff' + universeSecondaryLightColor.value.replace('#', '');
    });
    watch(universeFirstTwitterCarouselColor, () => {
      if (universeData.value && universeData.value.themes)
        universeData.value.themes[0].firstTwitterCarouselColor =
          'ff' + universeFirstTwitterCarouselColor.value.replace('#', '');
    });
    watch(universeSecondTwitterCarouselColor, () => {
      if (universeData.value && universeData.value.themes)
        universeData.value.themes[0].secondTwitterCarouselColor =
          'ff' + universeSecondTwitterCarouselColor.value.replace('#', '');
    });

    getUniverse();

    return {
      universe,
      universeLoading,
      universeData,
      universeData,
      universeIPFSHash,
      universeIpfsUrl,
      universeDisplayLang,
      universeDeepLink,
      createUniverse,
      updateUniverse,
      fileMessage,
      selectedFile,
      transactionHandlerRef,
      buttonDisable,
      onTransactionCompleted,
      errorMessage,
      ipfsLoading,
      iframe,
      iframeWrapper,
      pushUniverseDisplayLang,
      universeToolUrl,
      createToast,
      createFileReader,
      universePrimaryColor,
      universePrimaryLightColor,
      universeSecondaryColor,
      universeSecondaryLightColor,
      universeFirstTwitterCarouselColor,
      universeSecondTwitterCarouselColor,
      getUniverseDLink,
    };
  },
};
</script>
