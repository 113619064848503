<template>
  <div class="">
    <div
      class="flex justify-center items-center border-b pb-3"
      :key="currentStep"
    >
      <div
        v-for="(n, step) in totalSteps"
        :key="step"
        @click="changeStep(step)"
        class="mx-1"
      >
        <div class="flex items-center">
          <div
            class="cursor-pointer px-2 py-1"
            :class="{
              'opacity-50': step != currentStep,
              'font-bold': step == currentStep,
            }"
          >
            <p class="text-xs">
              {{ stepNames[step] ? stepNames[step] : `Step ${step + 1}` }}
            </p>
          </div>
          <div v-if="step < totalSteps - 1" class="text-xs text-gray-400 ml-2">
            <i class="fas fa-caret-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-stretch py-5">
      <div
        class="flex-none cursor-pointer pr-5"
        :class="{ invisible: currentStep == 0 }"
        @click="previousStep()"
      >
        <div class="flex items-center h-full">
          <p><i class="fas fa-chevron-left"></i></p>
        </div>
      </div>
      <div class="grow" :style="'min-height:' + maxHeight + 'px'">
        <div class="flex items-center justify-center h-full">
          <div class="grow" ref="formElements">
            <slot></slot>
          </div>
        </div>
      </div>
      <div
        class="flex-none cursor-pointer pl-5"
        :class="{ invisible: currentStep >= totalSteps - 1 }"
        @click="nextStep()"
      >
        <div class="flex items-center h-full">
          <p><i class="fas fa-chevron-right"></i></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
export default {
  props: {
    stepNames: Array,
  },
  setup(props) {
    const formElements = ref();
    const currentStep = ref(0);
    const totalSteps = ref(0);
    const maxHeight = ref(0);

    onMounted(() => {
      totalSteps.value = formElements.value.children.length;
      formElements.value.children.forEach((step) => {
        maxHeight.value =
          step.clientHeight > maxHeight.value
            ? step.clientHeight
            : maxHeight.value;
      });
      refreshSteps();
      // changeStep(3);
    });

    function refreshSteps() {
      var index = 0;
      for (var step of formElements.value.children) {
        if (index != currentStep.value) {
          step.style.display = 'none';
        } else {
          step.style.display = 'inline';
        }
        index++;
      }
    }

    function changeStep(newStep) {
      currentStep.value = newStep;
      refreshSteps();
    }

    function nextStep() {
      if (currentStep.value >= totalSteps.value - 1) return;
      currentStep.value = currentStep.value + 1;
      refreshSteps();
    }

    function previousStep() {
      if (currentStep.value == 0) return;
      currentStep.value = currentStep.value - 1;
      refreshSteps();
    }

    return {
      formElements,
      currentStep,
      totalSteps,
      maxHeight,
      changeStep,
      nextStep,
      previousStep,
    };
  },
};
</script>
