import Web3 from 'web3';
import bs58 from 'bs58';
import crypto from 'crypto';
import createKeccakHash from 'keccak';
import Axios from 'axios';
import FormData from 'form-data';
import { recoverTypedSignature } from '@metamask/eth-sig-util';
import { toChecksumAddress } from 'ethereum-checksum-address';

import sylContractABI from '/assets/sylContractABI.json';
import mydidContractABI from '/assets/mydidContractABI.json';

async function getJsonDataFromUrl(url) {
  if (
    !url.startsWith('https://myntfsid.mypinata.cloud/') &&
    !url.startsWith('https://resolver.mydid.eu/')
  )
    throw 'Bad URL input for method : getJsonDataFromUrl';
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(function (buffer) {
        const decoder = new TextDecoder('utf-8');
        const text = decoder.decode(buffer);
        resolve(JSON.parse(text));
      })
      .catch((err) => reject(err));
  });
}

export default {
  async install(app) {
    const web3 = new Web3(process.env.VUE_APP_WEB3_PROVIDER);

    const mydidContract = new web3.eth.Contract(
      mydidContractABI,
      process.env.VUE_APP_MYDID_CONTRACT_ADDR
    );
    const sylContract = new web3.eth.Contract(
      sylContractABI,
      process.env.VUE_APP_SYL_CONTRACT_ADDR
    );

    const eip712SchemaMap = {
      givenName:
        'https://myntfsid.mypinata.cloud/ipfs/QmXM1JReim4U736RSxHzn5rd2JJ3b8iikPkmHnCGE45KbH',
      familyName:
        'https://myntfsid.mypinata.cloud/ipfs/QmQqLWzLRwhgp8UwdaGSZfWb42my2gp4YoXHFHuGarYKFK',
      birthDate:
        'https://myntfsid.mypinata.cloud/ipfs/Qmb9YjjmeC2KHR5G1UUu3K6uTW2H3dtGd5fQELrYJjikbG',
      nationality:
        'https://myntfsid.mypinata.cloud/ipfs/QmYpYhYAbtWipDMaMYoCsSfydmkB2NRPwb5Lw46cFSTms1',
      gender:
        'https://myntfsid.mypinata.cloud/ipfs/Qmd4XZkXj9RsdZaEZavziAPM8quW72NrZNdZzkb3KyLayb',
      email:
        'https://myntfsid.mypinata.cloud/ipfs/QmfVApuBeHbvnqAk5c1FxEN1mZy15KmMbyvYyN1fR8hvXs',
      award:
        'https://myntfsid.mypinata.cloud/ipfs/QmQV6pQ4nzfA5o1jvLNwQpVgnTXF3KG9cNXmAuDDYgM5K4',
    };
    const obEip712Schema =
      'https://myntfsid.mypinata.cloud/ipfs/Qmdi7fC2EGp3kDWWX4dp6g3WQJ1V25QYo78nFcWbPobjk7';
    const obRoleEip712Schema =
      'https://myntfsid.mypinata.cloud/ipfs/QmNcDEeR42KY3nN44N5SdPUgC7q2vPAtgrRLhDrUSUeqDn';

    const utils = {
      mydid: {
        async createMydidTransaction(from, methodName, ...args) {
          const inputData = mydidContract.methods[methodName](
            ...args
          ).encodeABI();

          const gasPrice = parseInt(await web3.eth.getGasPrice());
          const estimateGas = await mydidContract.methods[methodName](
            ...args
          ).estimateGas({ from });

          const rawTransaction = {
            from,
            to: mydidContract.options.address,
            value: '0x0',
            gasPrice: '0x' + gasPrice.toString(16),
            gas: '0x' + Math.round(estimateGas * 1.01).toString(16),
            data: inputData,
            chainId: process.env.VUE_APP_CHAIN_ID,
          };

          return rawTransaction;
        },
        async getDID(addr) {
          return await mydidContract.methods.getDID(addr).call();
        },
        async getUniverse(addr) {
          return await mydidContract.methods.issuerUniverse(addr).call();
        },
        async getAppUniverseList(addr) {
          var appUniverseList = [];
          var index = 0;
          while (true) {
            try {
              const universe = await mydidContract.methods
                .appUniversesList(addr, index)
                .call();
              appUniverseList.push(Object.assign(universe, { index }));
              index++;
            } catch {
              return appUniverseList;
            }
          }
        },
        async getUniverseList() {
          var universeList = [];
          var index = 0;
          while (true) {
            try {
              const issuer = await mydidContract.methods
                .listUnivers(index)
                .call();
              universeList.push({ issuer, index });
              index++;
            } catch (err) {
              return universeList;
            }
          }
        },
        async isIssuer(addr) {
          return await mydidContract.methods
            .hasRole(
              createKeccakHash('keccak256').update('ISSUER_ROLE').digest(),
              addr
            )
            .call();
        },
        async isAdmin(addr) {
          const adminAddr = await mydidContract.methods.owner().call();
          return addr == adminAddr;
        },
        async getVCStatus(hash) {
          return await mydidContract.methods.vcs(hash).call();
        },
        async getIssuerCategory(addr) {
          return await mydidContract.methods.issuerCategory(addr).call();
        },
        async getIssuerBadgeTemplateList(addr) {
          const templateNumber = await mydidContract.methods
            .getLengthOfIssuerBadgeTemplate(addr)
            .call();
          let templates = [];
          for (var i = 0; i < templateNumber; i++) {
            const template = await mydidContract.methods
              .issuerBadgeTemplateLists(addr, i)
              .call();
            if (
              template.badgeTemplateHash !=
              '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            )
              templates.push(template);
          }
          return templates;
        },
        async getP2PBadgeTemplateList() {
          const templateNumber = await mydidContract.methods
            .getLengthOfP2PBadgeTemplate()
            .call();
          let templates = [];
          for (var i = 0; i < templateNumber; i++) {
            const template = await mydidContract.methods.p2pBT(i).call();
            if (
              template.badgeTemplateHash !=
              '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            )
              templates.push(template);
          }
          return templates;
        },
        async getPublicVCsByType(addr, type) {
          const vcNumber = await mydidContract.methods
            .getLengthOfPublicVcsByType(addr, type)
            .call();
          let vcs = [];
          for (var i = 0; i < vcNumber; i++) {
            const vc = await mydidContract.methods
              .publicVcsByType(addr, type, i)
              .call();
            vcs.push(vc);
          }
          return vcs;
        },
      },
      syl: {
        async createSylTransaction(from, methodName, ...args) {
          const inputData = sylContract.methods[methodName](
            ...args
          ).encodeABI();

          const gasPrice = parseInt(await web3.eth.getGasPrice());
          const estimateGas = await sylContract.methods[methodName](
            ...args
          ).estimateGas({ from });

          const rawTransaction = {
            from,
            to: sylContract.options.address,
            value: '0x0',
            gasPrice: '0x' + gasPrice.toString(16),
            gas: '0x' + Math.round(estimateGas * 1.1).toString(16),
            data: inputData,
            chainId: process.env.VUE_APP_CHAIN_ID,
          };

          return rawTransaction;
        },
        async getSylBalance(addr) {
          return (
            parseInt(await sylContract.methods.balanceOf(addr).call()) /
            Math.pow(10, 6)
          );
        },
        async verifySylAllowanceForMydid(addr, priceMethod) {
          // retrieve issuer SYL balance
          const sylBalance =
            parseInt(await sylContract.methods.balanceOf(addr).call()) /
            Math.pow(10, 6);

          // retrieve method SYL price
          const sylPrice =
            parseInt(await mydidContract.methods[priceMethod]().call()) /
            Math.pow(10, 6);

          // check if SYL allowance is sufficient
          const sylAllowanceMydid =
            parseInt(
              await sylContract.methods
                .allowance(addr, process.env.VUE_APP_MYDID_CONTRACT_ADDR)
                .call()
            ) / Math.pow(10, 6);
          if (sylAllowanceMydid < sylPrice) {
            return {
              state: false,
              price: sylPrice,
              code: 1,
              error: `SYL allowance insufficient`,
            };
          }

          // check if issuer SYL balance is sufficient
          if (sylBalance < sylPrice) {
            return {
              state: false,
              price: sylPrice,
              code: 2,
              error: `SYL balance insufficient`,
            };
          }

          return {
            state: true,
            price: sylPrice,
            code: 0,
          };
        },
      },
      ipfs: {
        async uploadIpfsFile(file) {
          var data = new FormData();
          data.append('file', file);

          var config = {
            method: 'post',
            url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${process.env.VUE_APP_PINATA_AUTH}`,
            },
            data,
          };
          const res = await Axios(config);
          const hash = bs58
            .decode(res.data.IpfsHash.substring(0, 46))
            .toString('hex')
            .substring(4);
          return hash;
        },
        async uploadIpfsJsonData(data) {
          var config = {
            method: 'post',
            url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.VUE_APP_PINATA_AUTH}`,
            },
            data,
          };
          const res = await Axios(config);
          const hash = bs58
            .decode(res.data.IpfsHash.substring(0, 46))
            .toString('hex')
            .substring(4);
          return hash;
        },
        async getJsonDataFromCID(cid) {
          return new Promise((resolve, reject) => {
            fetch('https://myntfsid.mypinata.cloud/ipfs/' + cid)
              .then(function (response) {
                return response.arrayBuffer();
              })
              .then(function (buffer) {
                const decoder = new TextDecoder('utf-8');
                const text = decoder.decode(buffer);
                resolve(JSON.parse(text));
              })
              .catch((err) => reject(err));
          });
        },
        async getJsonDataFromUrl(url) {
          if (
            !url.startsWith('https://myntfsid.mypinata.cloud/') &&
            !url.startsWith('https://resolver.mydid.eu/')
          )
            throw 'Bad URL input for method : getJsonDataFromUrl';
          return new Promise((resolve, reject) => {
            fetch(url)
              .then(function (response) {
                return response.arrayBuffer();
              })
              .then(function (buffer) {
                const decoder = new TextDecoder('utf-8');
                const text = decoder.decode(buffer);
                resolve(JSON.parse(text));
              })
              .catch((err) => reject(err));
          });
        },
        hashToCID(hash) {
          const cleanHash = (hash + '').replace('0x', '');
          const bytes = Buffer.from('1220' + cleanHash, 'hex');
          const cid = bs58.encode(bytes);
          return cid;
        },
        getUrlFromCID(cid) {
          return 'https://myntfsid.mypinata.cloud/ipfs/' + cid;
        },
      },
      sign: {
        getParamsTypedDataV4(
          message,
          primaryType,
          types,
          domainChainId,
          domainName,
          domainVerifyingContract,
          domainVersion
        ) {
          let typedData = {
            domain: {
              chainId: domainChainId,
              name: domainName,
              verifyingContract: domainVerifyingContract,
              version: domainVersion,
            },
            message,
            primaryType,
            types,
          };

          return typedData;
        },
        recoverTypedSignatureV4(
          message,
          primaryType,
          types,
          signature,
          domainChainId,
          domainName,
          domainVerifyingContract,
          domainVersion
        ) {
          const typedData = {
            domain: {
              chainId: domainChainId,
              name: domainName,
              verifyingContract: domainVerifyingContract,
              version: domainVersion,
            },
            message,
            primaryType,
            types,
          };
          return toChecksumAddress(
            recoverTypedSignature({
              data: typedData,
              signature,
              version: 'V4',
            })
          );
        },
        async getVCTypedDataV4(verifiableCredential) {
          const { id, type, image, ...keyValue } =
            verifiableCredential.credentialSubject;

          let eip712SchemaUrl = null;
          let primaryType = null;

          if (
            verifiableCredential.type.indexOf('EndorsementCredential') != -1
          ) {
            eip712SchemaUrl = obRoleEip712Schema;
            primaryType = 'EndorsementCredential';
          } else if (
            verifiableCredential.type.indexOf('OpenBadgeCredential') != -1
          ) {
            eip712SchemaUrl = obEip712Schema;
            primaryType = 'OpenBadgeCredential';
          } else {
            eip712SchemaUrl = eip712SchemaMap[Object.keys(keyValue)[0]];
            primaryType = 'VerifiableCredential';
          }

          const eip712Schema = await getJsonDataFromUrl(eip712SchemaUrl);

          const typedData = {
            domain: {
              name: 'myDid',
              chainId: parseInt(process.env.VUE_APP_CHAIN_ID),
              version: '2',
            },
            message: verifiableCredential,
            primaryType,
            types: eip712Schema,
          };

          return typedData;
        },
        async recoverVCTypedSignatureV4(verifiableCredential) {
          const { proof, ...vcWithoutProof } = verifiableCredential;
          const eip712Schema = await getJsonDataFromUrl(
            proof.eip712.messageDataEip712Schema
          );

          const typedData = {
            domain: proof.eip712.domain,
            message: vcWithoutProof,
            primaryType: proof.eip712.primaryType,
            types: eip712Schema,
          };

          return toChecksumAddress(
            recoverTypedSignature({
              data: typedData,
              signature: proof.proofValue,
              version: 'V4',
            })
          );
        },
      },
      badge: {
        computeBadgeV3(
          id,
          issuerProfileURL,
          issuerName,
          userDid,
          badgeTemplate,
          customName,
          customDescription,
          templateHash
        ) {
          const badge = {
            '@context': [
              'https://www.w3.org/2018/credentials/v1',
              'https://purl.imsglobal.org/spec/ob/v3p0/context.json',
              'https://purl.imsglobal.org/spec/ob/v3p0/extensions.json',
            ],
            id,
            type: ['VerifiableCredential', 'OpenBadgeCredential'],
            issuer: {
              id: issuerProfileURL,
              type: 'Profile',
              name: issuerName,
            },
            name: customName,
            description: customDescription,
            image: badgeTemplate.image,
            templateHash,
            issuanceDate: new Date().toISOString().slice(0, 19) + 'Z',
            credentialSubject: {
              id: userDid,
              type: 'AchievementSubject',
              achievement: {
                id: badgeTemplate.criteria.id,
                type: 'Achievement',
                criteria: {
                  id: badgeTemplate.criteria.id,
                  narrative: badgeTemplate.criteria.narrative,
                },
                description: badgeTemplate.description,
                name: badgeTemplate.name,
              },
            },
            credentialSchema: [
              {
                id: 'https://myntfsid.mypinata.cloud/ipfs/QmXRCpd6hqRY8q8EcPAqjM9jDcmbsyWCr2zAo1DVEfi5uJ',
                type: '1EdTechJsonSchemaValidator2019',
              },
            ],
            mVersion: '3.0',
          };

          return badge;
        },
        computeRoleBadgeV3(
          id,
          issuerProfileURL,
          issuerName,
          userDid,
          badgeTemplate,
          templateHash
        ) {
          const badge = {
            '@context': [
              'https://www.w3.org/2018/credentials/v1',
              'https://purl.imsglobal.org/spec/ob/v3p0/context.json',
              'https://w3id.org/security/suites/ed25519-2020/v1',
            ],
            id,
            type: ['VerifiableCredential', 'EndorsementCredential'],
            issuer: {
              id: issuerProfileURL,
              type: 'Profile',
              name: issuerName,
            },
            image: badgeTemplate.image,
            templateHash,
            issuanceDate: new Date().toISOString().slice(0, 19) + 'Z',
            expirationDate:
              new Date(new Date().setFullYear(new Date().getFullYear() + 10))
                .toISOString()
                .slice(0, 19) + 'Z',
            credentialSubject: {
              id: userDid,
              type: 'EndorsementSubject',
              endorsementComment: `${badgeTemplate.name}::${badgeTemplate.description}`,
            },
            credentialSchema: [
              {
                id: 'https://myntfsid.mypinata.cloud/ipfs/QmXRCpd6hqRY8q8EcPAqjM9jDcmbsyWCr2zAo1DVEfi5uJ',
                type: '1EdTechJsonSchemaValidator2019',
              },
            ],
            mVersion: '3.0',
          };

          return badge;
        },
        computeBadgeTemplate(
          language,
          name,
          id,
          imageUrl,
          description,
          criteriaId,
          criteriaNarrative,
          issuerAddress
        ) {
          const badgeTemplate = {
            '@context': 'https://w3id.org/openbadges/v2',
            '@language': language,
            type: 'BadgeClass',
            id,
            name,
            image: imageUrl,
            description,
            criteria: {
              id: criteriaId,
              narrative: criteriaNarrative,
            },
            issuerDID: `DID:SDI:${issuerAddress}#SERV_1`,
            mVersion: '3.0',
          };

          return badgeTemplate;
        },
      },
      vc: {
        createVerifiableCredential(issuerDid, userDid, key, value, image) {
          const verifiableCredential = {
            '@context': [
              'https://www.w3.org/2018/credentials/v1',
              'https://w3c-ccg.github.io/ethereum-eip712-signature-2021-spec/contexts/v1',
              'https://schema.org',
            ],
            type: ['VerifiableCredential'],
            issuer: issuerDid,
            issuanceDate: new Date().toISOString(),
            credentialSubject: {
              id: userDid,
              image,
              type: 'Person',
              [key]: value,
            },
            credentialSchema: {
              id: 'https://myntfsid.mypinata.cloud/ipfs/QmQVWmUBCLyuky9KeBAks2Ca8VT8CrHMnNDTSkrZAzSNji',
              type: 'MyDIDSchemaValidator2022',
            },
          };

          return verifiableCredential;
        },
        async addProofToVerifiableCredential(
          vcWithoutProof,
          issuerDid,
          signature
        ) {
          const { id, type, image, ...keyValue } =
            vcWithoutProof.credentialSubject;

          let eip712SchemaUrl = null;
          let primaryType = null;

          if (vcWithoutProof.type.indexOf('EndorsementCredential') != -1) {
            eip712SchemaUrl = obRoleEip712Schema;
            primaryType = 'EndorsementCredential';
          } else if (vcWithoutProof.type.indexOf('OpenBadgeCredential') != -1) {
            eip712SchemaUrl = obEip712Schema;
            primaryType = 'OpenBadgeCredential';
          } else {
            eip712SchemaUrl = eip712SchemaMap[Object.keys(keyValue)[0]];
            primaryType = 'VerifiableCredential';
          }

          const proof = {
            type: 'EthereumEip712Signature2021',
            created: new Date().toISOString().slice(0, 19) + 'Z',
            proofPurpose: 'assertionMethod',
            verificationMethod: issuerDid + '#ASSR_1',
            proofValue: signature,
            eip712: {
              messageDataEip712Schema: eip712SchemaUrl,
              domain: {
                name: 'myDid',
                chainId: parseInt(process.env.VUE_APP_CHAIN_ID),
                version: '2',
              },
              primaryType,
            },
          };
          return { ...vcWithoutProof, proof };
        },
      },
      async waitForTransactionConfirmed(hash) {
        return new Promise(function (resolve, reject) {
          var i = 0;
          var interval = setInterval(async () => {
            web3.eth
              .getTransactionReceipt(hash)
              .then(async (transactionReceipt) => {
                if (transactionReceipt && transactionReceipt.status) {
                  console.log(`Transaction ${hash} succeeded`);
                  clearInterval(interval);
                  resolve();
                } else if (transactionReceipt && !transactionReceipt.status) {
                  console.log(`Transaction ${hash} failed`);
                  clearInterval(interval);
                  reject('Transaction failed');
                } else {
                  console.log(`Transaction ${hash} still pending`);
                  if (i >= 30) {
                    clearInterval(interval);
                    reject('Transaction timeout (try to refresh this page).');
                  }
                }
              });
            i++;
          }, 1000);
        });
      },
      async getCurrentProviderBalance(addr) {
        return parseInt(await web3.eth.getBalance(addr)) / Math.pow(10, 18);
      },
      asciiToHex(asciiString) {
        return web3.utils.asciiToHex(asciiString);
      },
      hexToAscii(hexString) {
        return web3.utils.hexToAscii(hexString);
      },
      createSHA256Hash(message) {
        const hash = crypto.createHash('sha256').update(message).digest();
        return '0x' + Buffer.from(hash).toString('hex');
      },
      createRandomHexString(length) {
        return crypto.randomBytes(length).toString('hex');
      },
      isValidDid(did) {
        const didRegex = /^(DID|did):(SDI|sdi):0x[a-fA-F0-9]{40}$/;
        return did.match(didRegex);
      },
    };
    app.provide('utils', utils);
  },
};
