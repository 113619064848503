<template>
  <div :key="refresh">
    <p class="text-sm text-red-600 mb-1">
      Price for this method :
      <span
        v-if="
          allowanceVerification &&
          allowanceVerification.price &&
          allowanceVerification.price > 0
        "
        class="font-bold"
        >{{ `${allowanceVerification.price} SYL` }}</span
      ><span v-else class="font-bold">- SYL</span>
    </p>
    <div v-if="allowanceVerification && allowanceVerification.code == 2">
      <p class="text-red-600 text-xs">
        <i class="fas fa-exclamation-triangle mr-2"></i
        >{{ `Insufficient SYL balance for this method`
        }}<span @click="triggerVerification()"
          ><i class="fas fa-redo cursor-pointer ml-2"></i
        ></span>
      </p>
    </div>
    <div v-else-if="allowanceVerification && allowanceVerification.code == 1">
      <p class="text-red-600 text-xs">
        <i class="fas fa-exclamation-triangle mr-2"></i
        >{{
          `Please allow myDid to access your SYL in order to use this method`
        }}
      </p>
      <!-- <p @click="triggerVerification(true)" class="text-red-600 text-xs underline">
        Trigger allowance in Metamask
      </p> -->
    </div>
  </div>
</template>

<script>
import { inject, ref, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  props: {
    method: String,
    noAutoAllowanceTrigger: { type: Boolean, default: false },
  },
  setup(props) {
    const api = inject('api');
    const utils = inject('utils');
    const store = useStore();

    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const allowanceVerification = ref(null);
    const refresh = ref(0);

    async function verifyAllowance(method, force) {
      if (!method) return;

      allowanceVerification.value = await utils.syl.verifySylAllowanceForMydid(
        currentAddress.value,
        method
      );
      if (
        !allowanceVerification.value.state &&
        (!props.noAutoAllowanceTrigger || force)
      ) {
        if (allowanceVerification.value.code == 2) {
          return;
        }
        const rawTransaction = await utils.syl.createSylTransaction(
          currentAddress.value,
          'approve',
          process.env.VUE_APP_MYDID_CONTRACT_ADDR,
          '0xffffffffffffffffffffffffffffffff'
        );

        const transactionHash = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [rawTransaction],
        });

        await api.addTransaction(
          transactionHash,
          'approve',
          'DID:SDI:' + currentAddress.value
        );
      }
    }
    if (currentAddress.value) {
      verifyAllowance(props.method);
    }

    async function triggerVerification(force) {
      allowanceVerification.value = null;
      await verifyAllowance(props.method, force);
      refresh.value = refresh.value + 1;
    }

    return {
      allowanceVerification,
      refresh,
      triggerVerification,
    };
  },
};
</script>
